import React, { useEffect, useState } from 'react';
import { SidebarPortal } from '@plone/volto/components';
import { v4 as uuid } from 'uuid';
import { Container, Grid } from 'semantic-ui-react';
import EditColumnBlock from './Block/EditBlock';
import Sidebar from './Sidebar';
import cx from 'classnames';

const Edit = (props) => {
  const { data, block, onChangeBlock, selected } = props;
  const [focusOn, setFocusOn] = useState('title');

  useEffect(() => {
    if (!data?.columns || data?.columns?.length === 0) {
      onChangeBlock(block, {
        ...data,
        columns: [{ '@id': uuid() }, { '@id': uuid() }],
      });
    }
  }, [block]);

  if (__SERVER__) {
    return <div />;
  }

  return (
    <>
      <section
        className={cx('text-with-icons-block full-width', {
          [data.bg_color]: data.bg_color,
        })}
      >
        <Container>
          <Grid className="columns-wrapper">
            {data?.columns?.map((column, i) => (
              <EditColumnBlock
                column={column}
                data={data}
                index={i}
                focusOn={focusOn}
                setFocusOn={setFocusOn}
                onChange={(index, field, value) => {
                  let newColumns = [...data.columns];
                  newColumns[index][field] = value;
                  onChangeBlock(block, {
                    ...data,
                    columns: [...newColumns],
                  });
                }}
                selected={selected}
                key={i}
              />
            ))}
          </Grid>
        </Container>
      </section>
      <SidebarPortal selected={selected}>
        <Sidebar {...props} />
      </SidebarPortal>
    </>
  );
};

export default Edit;
