import React from 'react';
import PropTypes from 'prop-types';

import { UniversalLink } from '@plone/volto/components';
import { PreviewImage } from '@package/components';

const ArtistItem = ({ item, isEditMode, infoInTitle = false }) => {
  return (
    <div className="listing-item artist-item">
      <UniversalLink
        item={!isEditMode ? item : null}
        href={isEditMode ? '#' : null}
      >
        <PreviewImage item={item} />
      </UniversalLink>

      <div className="item-title">
        <UniversalLink
          item={!isEditMode ? item : null}
          href={isEditMode ? '#' : null}
        >
          {item.title ? item.title : item.id}
        </UniversalLink>
      </div>
      {item.skills?.length > 0 && (
        <p>
          {item.skills.map((s, i) => (
            <>
              {s}
              {i < item.skills.length - 1 ? ', ' : ''}
            </>
          ))}
        </p>
      )}
    </div>
  );
};

ArtistItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ArtistItem;
