import { defineMessages } from 'react-intl';
import { addSchemaField } from '@package/config/Blocks/ListingOptions/utils';

const messages = defineMessages({
  title: {
    id: 'Titolo',
    defaultMessage: 'Titolo',
  },
});

/** DEFAULT **/
const addDefaultOptions = (schema, formData, intl, position = 0) => {
  let pos = position;
  addSchemaField(
    schema,
    'title',
    intl.formatMessage(messages.title),
    null,
    null,
    pos,
  );
  pos++;

  return pos;
};

export default addDefaultOptions;
