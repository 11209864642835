import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { TextWidget } from '@plone/volto/components';

import clearSVG from '@plone/volto/icons/clear.svg';

const messages = defineMessages({
  appStoreLink: {
    id: 'appStoreLink',
    defaultMessage: 'APPStore Link',
  },
  playStoreLink: {
    id: 'playStoreLink',
    defaultMessage: 'PlayStore Link',
  },
  AltText: {
    id: 'Alt text',
    defaultMessage: 'Alt text',
  },
  AltTextHint: {
    id: 'Alt text hint',
    defaultMessage: 'Leave empty if the image is purely decorative.',
  },
  AltTextHintLinkText: {
    id: 'Alt text hint link text',
    defaultMessage: 'Describe the purpose of the image.',
  },
  openLinkInNewTab: {
    id: 'Open in a new tab',
    defaultMessage: 'Open in a new tab',
  },
});

const HeroSidebar = ({
  data,
  block,
  onChangeBlock,
  openObjectBrowser,
  required = false,
  intl,
}) => {
  const [activeAccIndex, setActiveAccIndex] = useState(0);

  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage id="Hero" defaultMessage="Hero" />
        </h2>
      </header>

      <Segment className="form">
        <TextWidget
          id="appStoreLink"
          title={intl.formatMessage(messages.appStoreLink)}
          required={false}
          value={data.appStoreLink ?? ''}
          icon={data.appStoreLink ? clearSVG : null}
          iconAction={() =>
            onChangeBlock(block, {
              ...data,
              appStoreLink: null,
            })
          }
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              appStoreLink: value,
            });
          }}
        />

        <TextWidget
          id="playStoreLink"
          title={intl.formatMessage(messages.playStoreLink)}
          required={false}
          value={data.playStoreLink ?? ''}
          icon={data.playStoreLink ? clearSVG : null}
          iconAction={() =>
            onChangeBlock(block, {
              ...data,
              playStoreLink: null,
            })
          }
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              playStoreLink: value,
            });
          }}
        />
      </Segment>
      <Segment className="form">
        <TextWidget
          id="alt"
          title={intl.formatMessage(messages.AltText)}
          description={
            <>
              <a
                href="https://www.w3.org/WAI/tutorials/images/decision-tree/"
                title={intl.formatMessage(messages.openLinkInNewTab)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {intl.formatMessage(messages.AltTextHintLinkText)}
              </a>{' '}
              {intl.formatMessage(messages.AltTextHint)}
            </>
          }
          required={false}
          value={data.alt || ''}
          icon={data.alt ? clearSVG : null}
          iconAction={() =>
            onChangeBlock(block, {
              ...data,
              alt: '',
            })
          }
          onChange={(_name, value) => {
            onChangeBlock(block, {
              ...data,
              alt: value,
            });
          }}
        />
      </Segment>
    </Segment.Group>
  );
};

HeroSidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  openObjectBrowser: PropTypes.func.isRequired,
  resetSubmitUrl: PropTypes.func.isRequired,
};

export default injectIntl(HeroSidebar);
