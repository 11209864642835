/**
 * Login container.
 * @module components/theme/ViewXml/ViewXml
 */

import React, { useEffect } from 'react';

import { defineMessages, useIntl } from 'react-intl';
import { Grid } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet, BodyClass } from '@plone/volto/helpers';
import { getYCApi } from '@package/actions';
import { useDispatch, useSelector } from 'react-redux';
import { UniversalLink } from '@plone/volto/components';

const messages = defineMessages({
  scheda_convenzione: {
    id: 'scheda_convenzione',
    defaultMessage: 'Scheda Convenzione',
  },
  from: {
    id: 'from',
    defaultMessage: 'dal',
  },
  to: {
    id: 'to',
    defaultMessage: 'al',
  },
  categoria: { id: 'yc_categoria', defaultMessage: 'Categoria:' },
  tipologia: { id: 'yc_tipologia', defaultMessage: 'Tipologia:' },
  contact_infos: {
    id: 'yc_contact_infos',
    defaultMessage: 'Per info contattare:',
  },
});
/**
 * ViewXml class.
 * @class ViewXml
 * @extends Component
 */

const YCConvenzioneView = (props) => {
  const intl = useIntl();

  const dispatch = useDispatch();
  const itemID = props?.match?.params?.[2];

  useEffect(() => {
    if (itemID) {
      dispatch(getYCApi(itemID, 'convenzione', { id: itemID }));
    }
  }, [dispatch, itemID]);

  const content = useSelector(
    (state) => state.YCApi.subrequests?.[itemID + 'convenzione']?.result,
  );

  return (
    <div id="page-ycview" className="ui container">
      <Helmet title={content?.CN01_NOME} />
      {/* Body class if displayName in component is set */}
      <BodyClass className={`view-ycconvenzione`} />
      {/*-----titolo-----*/}
      {content?.CN01_NOME && (
        <h1 className="documentFirstHeading">{content.CN01_NOME}</h1>
      )}

      <Grid className="data">
        <Grid.Row stretched>
          <Grid.Column
            mobile={12}
            tablet={12}
            computer={12}
            largeScreen={8}
            widescreen={8}
          >
            {/*-----descrizione-----*/}
            {content?.CN01_DESCRIZIONE && (
              <div className="description">
                <p
                  dangerouslySetInnerHTML={{
                    __html: content.CN01_DESCRIZIONE.replaceAll(
                      '\r\n',
                      '<br/>',
                    ).replace('\n', '<br/>'),
                  }}
                ></p>
              </div>
            )}
          </Grid.Column>

          <Grid.Column
            mobile={12}
            tablet={12}
            computer={12}
            largeScreen={4}
            widescreen={4}
            className="column-infos"
          >
            {/*-----info-----*/}
            <div className="infos-header">
              {intl.formatMessage(messages.scheda_convenzione)}
            </div>

            <div className="infos-body">
              {content?.CN01_CATEGORIA && (
                <div className="info">
                  <span className="label">
                    {intl.formatMessage(messages.categoria)}
                  </span>
                  <span>{content.CN01_CATEGORIA}</span>
                </div>
              )}

              <div className="info">
                <span className="label">
                  <FontAwesomeIcon icon={['fas', 'map-marker-alt']} />
                </span>
                <span>
                  {content?.CN01_INDIRIZZO}, {content?.CN01_CIVICO}
                  <br />
                  {content?.CN01_CAP ? content?.CN01_CAP + ' ' : ''}{' '}
                  {content?.CN01_LOCALITA ? content?.CN01_LOCALITA + ' ' : ''}{' '}
                  {content?.CN01_COMUNE ? content?.CN01_COMUNE + ' ' : ''}{' '}
                  {content?.CN01_PROVINCIA
                    ? '(' + content?.CN01_PROVINCIA + ') '
                    : ''}
                </span>
              </div>
              {(content?.CN01_TELEFONO ||
                content?.CN01_MAIL ||
                content?.CN01_WEB) && (
                <div className="info contact-infos">
                  {content?.CN01_WEB && (
                    <span>
                      <UniversalLink href={content?.CN01_WEB ?? ''}>
                        {content?.CN01_WEB}
                      </UniversalLink>
                    </span>
                  )}

                  {content?.CN01_TELEFONO && (
                    <span>
                      <a href={'tel:' + content?.CN01_TELEFONO}>
                        {content?.CN01_TELEFONO}
                      </a>
                    </span>
                  )}

                  {content?.CN01_MAIL && (
                    <span>
                      <a href={'mailto:' + content?.CN01_MAIL}>
                        {content.CN01_MAIL}
                      </a>
                    </span>
                  )}
                </div>
              )}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default YCConvenzioneView;
