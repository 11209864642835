import React from 'react';
import { useSelector } from 'react-redux';
import { SearchWidget } from '@plone/volto/components';
import { SocialLinksSubsite } from '@package/components/layout';

const RightHeaderSubsite = ({ pathname }) => {
  const subsite = useSelector((state) => state.subsite?.data);
  return subsite?.['@id'] ? (
    <div className="right-header">
      <SocialLinksSubsite />
      <div className="search">
        <SearchWidget pathname={pathname} />
      </div>
    </div>
  ) : null;
};

export default RightHeaderSubsite;
