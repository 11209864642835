import React from 'react';
import { TextEditorWidget } from '@package/components';
import { useIntl, defineMessages } from 'react-intl';
import { Container } from 'semantic-ui-react';
import Sidebar from './Sidebar';
import { UniversalLink, SidebarPortal } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Image } from '@plone/volto/components';
import { ReadMore } from '@package/components';
import selectIconPNG from '@package/components/layout/images/select.png';

import cx from 'classnames';

const messages = defineMessages({
  titlePlaceholder: {
    id: 'Title placeholder',
    defaultMessage: 'Inserisci il titolo della sezione',
  },
  preTitlePlaceholder: {
    id: 'Pre Title placeholder',
    defaultMessage: "Inserisci il nome dell'elemento collegato",
  },
  IconAlt: {
    id: 'Show icon Alt',
    defaultMessage: 'Icona',
  },
});

const Edit = (props) => {
  const { data, block, onChangeBlock, selected } = props;
  const intl = useIntl();

  return (
    <section
      id={
        !data.id_element__decalogo
          ? ''
          : data.id_element__decalogo.toString().toLowerCase().trim()
      }
      className={cx('decalogo-wrapper full-width', {
        [data.bg_color]: data.bg_color,
        ['alignText-' + data.alignText]: data.alignText,
        ['bg-' + data.decalogo_img]: data.decalogo_img,
      })}
    >
      <Container>
        <div className="list-element">
          <section
            className={cx('block-title', {
              'less-padding': data.addButtons,
            })}
          >
            <div>
              {data.addButtons
                ?.filter((b) => b.href?.[0]?.['@id'])
                .map((e, index) => (
                  <ReadMore
                    key={index}
                    linkMore={{
                      href: e.href?.[0]?.['@id'],
                      title: e.href_title,
                    }}
                    isEditMode={true}
                    color={data.bg_color !== 'bg-secondary' ? 'black' : 'grey'}
                    size={data.button_sizes ?? 'tiny'}
                  />
                ))}
            </div>

            <TextEditorWidget
              data={data}
              fieldName="title"
              onChangeBlock={(v) => {
                onChangeBlock(block, {
                  ...data,
                  title: v.title,
                });
              }}
              placeholder={intl.formatMessage(messages.titlePlaceholder)}
              showToolbar={true}
            />
          </section>
          {data.item
            ?.filter((b) => b.href?.[0]?.['@id'])
            .map((item, index) => (
              <div
                key={index}
                className={cx('box', {
                  ['separator-' +
                  (data.showLineSeparatorDecalogo
                    ? 'true'
                    : 'false')]: data.showLineSeparatorDecalogo,
                })}
              >
                <h3>{item.listItem}</h3>
                <div className="icon-title-wrapper">
                  <Image
                    className={cx({
                      [item.icon && 'show']: item.icon,
                    })}
                    src={selectIconPNG}
                    alt={intl.formatMessage(messages.IconAlt)}
                  />
                  <UniversalLink
                    className="link"
                    href={flattenToAppURL(item.href?.[0]?.['@id'])}
                  >
                    {item.href_title || item.href?.[0]?.['@id']}
                  </UniversalLink>
                </div>
                <p>{item.description1}</p>
                <p>{item.description2}</p>
                <p>{item.description3}</p>
              </div>
            ))}
        </div>
        <SidebarPortal selected={selected}>
          <Sidebar {...props} />
        </SidebarPortal>
      </Container>
    </section>
  );
};

export default Edit;
