import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment/min/moment-with-locales';

import cx from 'classnames';
import { defineMessages, injectIntl } from 'react-intl';
import { rrulestr } from 'rrule';
import { rrulei18n } from '@plone/volto/components/manage/Widgets/RecurrenceWidget/Utils';
import { datesForDisplay } from '@package/helpers';

const messages = defineMessages({
  eventFrom: {
    id: 'eventFrom',
    defaultMessage: 'Dal',
  },
  eventFromV: {
    id: 'eventFromV',
    defaultMessage: "Dall'",
  },
  eventTo: {
    id: 'eventTo',
    defaultMessage: 'al',
  },
  eventToV: {
    id: 'eventToV',
    defaultMessage: "all'",
  },
  eventFromTime: {
    id: 'eventFromTime',
    defaultMessage: 'dalle',
  },
  eventToTime: {
    id: 'eventToTime',
    defaultMessage: 'alle',
  },
});

const When = ({
  start,
  end,
  show_times = true,
  _whole_day,
  open_end,
  intl,
  recurrence,
}) => {
  moment.locale(intl.locale);

  const datesInfo = datesForDisplay(start, end, null, null, moment);
  if (!datesInfo) {
    // eslint-disable-next-line no-console
    console.warn('EventWhen: Received invalid start or end date.');
    return;
  }
  const {
    sameYear,
    sameMonth,
    sameDay,
    sameTime,
    wholeDay,
    mStart,
    mEnd,
  } = datesInfo;

  const whole_day = _whole_day || wholeDay;

  if (recurrence) {
    const rruleSet = rrulestr(recurrence, {
      compatible: true, //If set to True, the parser will operate in RFC-compatible mode. Right now it means that unfold will be turned on, and if a DTSTART is found, it will be considered the first recurrence instance, as documented in the RFC.
      forceset: true,
    });
    const RRULE_LANGUAGE = rrulei18n(intl);
    const rruleText = rruleSet.rrules()[0]?.toText(
      (t) => {
        return RRULE_LANGUAGE.strings[t];
      },
      RRULE_LANGUAGE,
      RRULE_LANGUAGE.dateFormatter,
    );

    return (
      <>
        {[1, 8, 11].indexOf(mStart.date()) >= 0
          ? intl.formatMessage(messages.eventFromV)
          : intl.formatMessage(messages.eventFrom)}
        {mStart.format('DD.MM.YYYY')}
        {rruleText && <div>{rruleText}</div>}
        {!whole_day || (!open_end && !sameTime && <br />)}
        {!whole_day && (
          <>
            {!rruleText && <>&nbsp;</>}
            {intl.formatMessage(messages.eventFromTime)}
            &nbsp;
            <span className="start-time">{mStart.format('H:mm')}</span>
          </>
        )}
        {!open_end && !sameTime && (
          <>
            &nbsp;
            {intl.formatMessage(messages.eventToTime)}
            &nbsp;
            <span className="end-time">{mEnd.format('H:mm')}</span>
          </>
        )}
      </>
    );
  }

  return (
    <p
      className={cx('event-when', {
        'same-year': sameYear,
        'same-month': sameMonth,
        'same-day': sameDay,
        'same-time': sameTime,
        'whole-day': whole_day,
        'open-end': open_end,
      })}
    >
      {!sameDay ? (
        <>
          {[1, 8, 11].indexOf(mStart.date()) >= 0
            ? intl.formatMessage(messages.eventFromV)
            : intl.formatMessage(messages.eventFrom)}
          &nbsp;
          <span className="start">
            <span className="start-date">
              {mStart.format(
                sameMonth
                  ? intl.locale === 'en'
                    ? 'MMMM D'
                    : 'D'
                  : 'DD.MM.YYYY',
              )}
            </span>

            {!whole_day && !sameMonth && show_times && (
              <>
                {/* Plone has an optional word based on locale here */}
                <span> </span>
                <span className="start-time">{mStart.format('LT')}</span>
              </>
            )}
          </span>
          {!open_end && (
            <>
              &nbsp;
              {[1, 8, 11].indexOf(mEnd.date()) >= 0
                ? intl.formatMessage(messages.eventToV)
                : intl.formatMessage(messages.eventTo)}
              &nbsp;
              <span className="end">
                <span className="end-date">{mEnd.format('DD.MM.YYYY')}</span>
                {!whole_day && show_times && (
                  <>
                    {/* Plone has an optional word based on locale here */}
                    <span> </span>
                    <span className="end-time">{mEnd.format('LT')}</span>
                  </>
                )}
              </span>
            </>
          )}
        </>
      ) : (
        <>
          {whole_day && (
            <span className="start-date">{mStart.format('DD.MM.YYYY')}</span>
          )}
          {open_end && !whole_day && (
            <>
              <span className="start-date">{mStart.format('DD.MM.YYYY')}</span>

              {show_times && (
                <>
                  &nbsp;
                  {intl.formatMessage(messages.eventFromTime)}
                  &nbsp;
                  <span className="start-time">{mStart.format('LT')}</span>
                </>
              )}
            </>
          )}
          {!(whole_day || open_end) && (
            <>
              <span className="start-date">{mStart.format('DD.MM.YYYY')}</span>

              {show_times && (
                <>
                  &nbsp;
                  {intl.formatMessage(messages.eventFromTime)}
                  &nbsp;
                  <span className="start-time">{mStart.format('LT')}</span>
                  &nbsp;
                  {intl.formatMessage(messages.eventToTime)}
                  &nbsp;
                  <span className="end-time">{mEnd.format('LT')}</span>
                </>
              )}
            </>
          )}
        </>
      )}
    </p>
  );
};

When.propTypes = {
  start: PropTypes.string.isRequired,
  end: PropTypes.string,
  whole_day: PropTypes.bool,
  open_end: PropTypes.bool,
};

const EventWhen = injectIntl(When);

export { EventWhen, datesForDisplay };
