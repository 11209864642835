/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { OSMMap } from 'volto-venue';

const messages = defineMessages({
  no_results: {
    id: 'no_results',
    defaultMessage: 'Nessun risultato trovato',
  },
});

const YCMap = ({ markers, center, zoom }) => {
  const intl = useIntl();

  return (
    <>
      {/* Mappa */}
      {__CLIENT__ && (
        <>
          {markers?.length > 0 ? (
            <OSMMap
              center={center ? [center.latitude, center.longitude] : null}
              markers={markers}
              showTooltip={true}
              zoom={zoom}
              showPopup={true}
              cluster={true}
            />
          ) : (
            <div className="no-results">
              {intl.formatMessage(messages.no_results)}
            </div>
          )}
        </>
      )}
    </>
  );
};

const areEqual = (prevProps, nextProps) => {
  let e = prevProps.zoom === nextProps.zoom;
  if (e) {
    e = prevProps.center === nextProps.center;
  }
  if (e) {
    e =
      JSON.stringify(prevProps.markers?.map((m) => m.title) ?? []) ===
      JSON.stringify(nextProps.markers?.map((m) => m.title) ?? []);
  }

  return e;
};

export default React.memo(YCMap, areEqual);
