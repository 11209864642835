/**
 * Login container.
 * @module components/theme/ViewXml/ViewXml
 */

import React, { useEffect } from 'react';

import { defineMessages, useIntl } from 'react-intl';
import { Grid } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from '@plone/volto/helpers';
import { getYCApi } from '@package/actions';
import { useDispatch, useSelector } from 'react-redux';
import { BodyClass, parseDateTime } from '@plone/volto/helpers';

const messages = defineMessages({
  scheda_progetto: {
    id: 'scheda_progetto',
    defaultMessage: 'Scheda progetto',
  },
  from: {
    id: 'from',
    defaultMessage: 'dal',
  },
  to: {
    id: 'to',
    defaultMessage: 'al',
  },
  proponente: { id: 'yc_proponente', defaultMessage: 'Proposta da:' },
  tipologia: { id: 'yc_tipologia', defaultMessage: 'Tipologia:' },
  contact_infos: {
    id: 'yc_contact_infos',
    defaultMessage: 'Per info contattare:',
  },
});
/**
 * ViewXml class.
 * @class ViewXml
 * @extends Component
 */

const YCProgettoView = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const itemID = props?.match?.params?.[2];

  useEffect(() => {
    if (itemID) {
      dispatch(getYCApi(itemID, 'progetto', { id: itemID }));
    }
  }, [dispatch, itemID]);

  const content = useSelector(
    (state) => state.YCApi.subrequests?.[itemID + 'progetto']?.result,
  );

  return (
    <div id="page-ycview" className="ui container">
      <Helmet title={content?.PG01_TITOLO} />
      {/* Body class if displayName in component is set */}
      <BodyClass className={`view-ycprogetto`} />
      {/*-----titolo-----*/}
      {content?.PG01_TITOLO && (
        <h1 className="documentFirstHeading">{content.PG01_TITOLO}</h1>
      )}

      {/*-----date-----*/}
      {(content?.PG01_DATA_INIZIO || content?.PG01_DATA_FINE) && (
        <div className="dates">
          {content?.PG01_DATA_INIZIO && (
            <>
              {intl.formatMessage(messages.from)}{' '}
              {parseDateTime(
                intl.locale,
                content.PG01_DATA_INIZIO,
                'DD/MM/YYYY',
              )}{' '}
            </>
          )}

          {content?.PG01_DATA_FINE && (
            <>
              {intl.formatMessage(messages.to)}{' '}
              {parseDateTime(intl.locale, content.PG01_DATA_FINE, 'DD/MM/YYYY')}
            </>
          )}
        </div>
      )}

      <Grid className="data">
        <Grid.Row stretched>
          <Grid.Column
            mobile={12}
            tablet={12}
            computer={12}
            largeScreen={8}
            widescreen={8}
          >
            {/*-----descrizione-----*/}
            {content?.PG01_DESCRIZIONE && (
              <div className="description">
                <p
                  dangerouslySetInnerHTML={{
                    __html: content.PG01_DESCRIZIONE.replaceAll(
                      '\r\n',
                      '<br/>',
                    ).replace('\n', '<br/>'),
                  }}
                ></p>
              </div>
            )}
          </Grid.Column>

          <Grid.Column
            mobile={12}
            tablet={12}
            computer={12}
            largeScreen={4}
            widescreen={4}
            className="column-infos"
          >
            <div className="infos-header">
              {intl.formatMessage(messages.scheda_progetto)}
            </div>

            <div className="infos-body">
              {/*-----info-----*/}

              {content?.PG01_PROPONENTE && (
                <div className="info">
                  <span className="label">
                    {intl.formatMessage(messages.proponente)}
                  </span>
                  <span>{content.PG01_PROPONENTE}</span>
                </div>
              )}
              {content?.PG01_TIPOLOGIA && (
                <div className="info">
                  <span className="label">
                    {intl.formatMessage(messages.tipologia)}
                  </span>
                  <span>{content.PG01_TIPOLOGIA}</span>
                </div>
              )}
              <div className="info">
                <span className="label">
                  <FontAwesomeIcon icon={['fas', 'map-marker-alt']} />
                </span>
                <span>
                  {content?.PG01_INDIRIZZO}, {content?.PG01_CIVICO}
                  <br />
                  {content?.PG01_CAP ? content?.PG01_CAP + ' ' : ''}{' '}
                  {content?.PG01_LOCALITA ? content?.PG01_LOCALITA + ' ' : ''}{' '}
                  {content?.PG01_COMUNE ? content?.PG01_COMUNE + ' ' : ''}{' '}
                  {content?.PG01_PROVINCIA
                    ? '(' + content?.PG01_PROVINCIA + ') '
                    : ''}
                </span>
              </div>
              {(content?.PG01_CONTATTO_PERSONA ||
                content?.PG01_CONTATTO_TELEFONO ||
                content?.PG01_CONTATTO_MAIL) && (
                <div className="info contact-infos">
                  <span className="label">
                    {intl.formatMessage(messages.contact_infos)}
                  </span>
                  {content?.PG01_CONTATTO_PERSONA && (
                    <span>{content?.PG01_CONTATTO_PERSONA}</span>
                  )}
                  {content?.PG01_CONTATTO_TELEFONO && (
                    <span>
                      <a href={'tel:' + content?.PG01_CONTATTO_TELEFONO}>
                        {content?.PG01_CONTATTO_TELEFONO}
                      </a>
                    </span>
                  )}
                  {content?.PG01_CONTATTO_MAIL && (
                    <span>
                      <a href={'mailto:' + content?.PG01_CONTATTO_MAIL}>
                        {content.PG01_CONTATTO_MAIL}
                      </a>
                    </span>
                  )}
                </div>
              )}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default YCProgettoView;
