import React from 'react';
import Sidebar from './Sidebar';
import { SidebarPortal } from '@plone/volto/components';
import cx from 'classnames';
import { Container, Input, Grid } from 'semantic-ui-react';
import { TextEditorWidget } from '@package/components';
import { useIntl, defineMessages } from 'react-intl';

const messages = defineMessages({
  titlePlaceholder: {
    id: 'Title placeholder',
    defaultMessage: 'Titolo...',
  },
  textPlaceholder: {
    id: 'Text placeholder',
    defaultMessage: 'Inserisci il testo...',
  },
});

const Edit = (props) => {
  const { data, block, onChangeBlock, selected } = props;
  const intl = useIntl();

  return (
    <>
      <section
        className={cx('column-text-block full-width', {
          [data.bg_color]: data.bg_color,
          ['alignText-' + data.alignText]: data.alignText,
          'column-fullWidth': data.column_width,
        })}
      >
        <Container>
          <div className="title">
            <Input
              fluid
              className={cx({
                'input-main-title': data.title_size === 'h1',
                'input-title': data.title_size !== 'h1',
              })}
              transparent
              placeholder={intl.formatMessage(messages.titlePlaceholder)}
              value={data.title || ''}
              onClick={(e) => {
                //focusField('title');
                e.stopPropagation();
              }}
              onChange={(v) =>
                onChangeBlock(block, {
                  ...data,
                  title: v.target.value,
                })
              }
            />
          </div>
          <Grid columns={data.column_num}>
            <Grid.Column>
              <p className="text-content">
                <TextEditorWidget
                  data={data}
                  fieldName="text1"
                  onChangeBlock={(v) => {
                    onChangeBlock(block, {
                      ...data,
                      text1: v.text1,
                    });
                  }}
                  placeholder={intl.formatMessage(messages.textPlaceholder)}
                  showToolbar={true}
                />
                {data.title_element !== '' ?? <div>Teste</div>}
              </p>
            </Grid.Column>
            <Grid.Column>
              <p className="text-content">
                <TextEditorWidget
                  data={data}
                  fieldName="text2"
                  onChangeBlock={(v) => {
                    onChangeBlock(block, {
                      ...data,
                      text2: v.text2,
                    });
                  }}
                  placeholder={intl.formatMessage(messages.textPlaceholder)}
                  showToolbar={true}
                />
                {data.title_element !== '' ?? <div>Teste</div>}
              </p>
            </Grid.Column>
          </Grid>
        </Container>
      </section>
      <SidebarPortal selected={selected}>
        <Sidebar {...props} />
      </SidebarPortal>
    </>
  );
};

export default Edit;
