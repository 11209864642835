/**
 * View.
 * @module components/manage/Blocks/IconsBlocks/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import ViewBlock from './Block/ViewBlock';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Grid } from 'semantic-ui-react';

const View = ({ data }) => {
  return (
    <div className="block iconsBlock">
      <div className="title-wrapper">
        {data.main_title && <h2 className="title-block">{data.main_title}</h2>}
      </div>
      <Grid padded className="iconsBlock-wrapper">
        {data.url && (
          <div
            className="background"
            style={{
              backgroundImage: `url(${flattenToAppURL(
                data.url,
              )}/@@images/image)`,
            }}
          ></div>
        )}
        {data?.columns?.length > 0 && (
          <Grid.Row
            className="columns-wrapper"
            columns={data?.columns?.length > 2 ? 3 : 2}
          >
            {data?.columns?.map((column, i) => (
              <ViewBlock data={column} index={i} key={column['@id']} />
            ))}
          </Grid.Row>
        )}
      </Grid>
    </div>
  );
};

View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
