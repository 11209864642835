/**
 * Login container.
 * @module components/theme/ViewXml/ViewXml
 */

import React, { useEffect } from 'react';

import { defineMessages, useIntl } from 'react-intl';
import { Grid } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet, BodyClass } from '@plone/volto/helpers';
import { getYCApi } from '@package/actions';
import { useDispatch, useSelector } from 'react-redux';
import { UniversalLink } from '@plone/volto/components';

const messages = defineMessages({
  scheda_spazio: {
    id: 'scheda_spazio',
    defaultMessage: 'Scheda Spazio o Servizio',
  },
  from: {
    id: 'from',
    defaultMessage: 'dal',
  },
  to: {
    id: 'to',
    defaultMessage: 'al',
  },
  tipologia: { id: 'yc_tipologia', defaultMessage: 'Tipologia:' },
  target: { id: 'yc_target', defaultMessage: 'Target:' },
  dotazioni: { id: 'yc_dotazioni', defaultMessage: 'Dotazioni:' },
  servizi: { id: 'yc_servizi', defaultMessage: 'Servizi:' },
  contact_infos: {
    id: 'yc_contact_infos',
    defaultMessage: 'Per info contattare:',
  },
});
/**
 * ViewXml class.
 * @class ViewXml
 * @extends Component
 */

const YCSpazioServizioView = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const itemID = props?.match?.params?.[2];

  useEffect(() => {
    if (itemID) {
      dispatch(getYCApi(itemID, 'spazio', { id: itemID }));
    }
  }, [dispatch, itemID]);

  const content = useSelector(
    (state) => state.YCApi.subrequests?.[itemID + 'spazio']?.result,
  );

  return (
    <div id="page-ycview" className="ui container">
      <Helmet title={content?.OR01_NOME} />
      {/* Body class if displayName in component is set */}
      <BodyClass className={`view-ycspazio`} />
      {/*-----titolo-----*/}
      {content?.OR01_NOME && (
        <h1 className="documentFirstHeading">{content.OR01_NOME}</h1>
      )}

      <Grid className="data">
        <Grid.Row stretched>
          <Grid.Column
            mobile={12}
            tablet={12}
            computer={12}
            largeScreen={8}
            widescreen={8}
          >
            <div>
              {/*-----descrizione-----*/}
              {content?.OR01_ATTIVITA && (
                <div className="description">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: content.OR01_ATTIVITA.replaceAll(
                        '\r\n',
                        '<br/>',
                      ).replace('\n', '<br/>'),
                    }}
                  ></p>
                </div>
              )}

              {/*-----dotazioni-----*/}
              {content?.OR01_DOTAZIONI && (
                <div className="description">
                  <h5>{intl.formatMessage(messages.dotazioni)}</h5>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: content.OR01_DOTAZIONI.replaceAll(
                        '\r\n',
                        '<br/>',
                      ).replace('\n', '<br/>'),
                    }}
                  ></p>
                </div>
              )}

              {/*-----servizi-----*/}
              {content?.OR01_SERVIZI && (
                <div className="description">
                  <h5>{intl.formatMessage(messages.servizi)}</h5>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: content.OR01_SERVIZI.replaceAll(
                        '\r\n',
                        '<br/>',
                      ).replace('\n', '<br/>'),
                    }}
                  ></p>
                </div>
              )}
            </div>
          </Grid.Column>

          <Grid.Column
            mobile={12}
            tablet={12}
            computer={12}
            largeScreen={4}
            widescreen={4}
            className="column-infos"
          >
            {/*-----info-----*/}
            <div className="infos-header">
              {intl.formatMessage(messages.scheda_spazio)}
            </div>

            <div className="infos-body">
              {content?.OR01_TIPOLOGIA && (
                <div className="info">
                  <span className="label">
                    {intl.formatMessage(messages.tipologia)}
                  </span>
                  <span>{content.OR01_TIPOLOGIA}</span>
                </div>
              )}
              {content?.OR01_TARGET && (
                <div className="info">
                  <span className="label">
                    {intl.formatMessage(messages.target)}
                  </span>
                  <span>{content.OR01_TARGET}</span>
                </div>
              )}

              <div className="info">
                <span className="label">
                  <FontAwesomeIcon icon={['fas', 'map-marker-alt']} />
                </span>
                <span>
                  {content?.OR01_INDIRIZZO}
                  {content?.OR01_CIVICO ? ', ' + content?.OR01_CIVICO : ''}
                  <br />
                  {content?.OR01_CAP ? content?.OR01_CAP + ' ' : ''}{' '}
                  {content?.OR01_LOCALITA ? content?.OR01_LOCALITA + ' ' : ''}{' '}
                  {content?.OR01_COMUNE ? content?.OR01_COMUNE + ' ' : ''}{' '}
                  {content?.OR01_PROVINCIA
                    ? '(' + content?.OR01_PROVINCIA + ') '
                    : ''}
                </span>
              </div>

              {(content?.OR01_FACEBOOK ||
                content?.OR01_INSTAGRAM ||
                content?.OR01_LINKEDIN ||
                content?.OR01_TWITTER ||
                content?.OR01_YOUTUBE) && (
                <div className="info socials">
                  {content?.OR01_FACEBOOK && (
                    <UniversalLink href={content.OR01_FACEBOOK}>
                      <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                    </UniversalLink>
                  )}

                  {content?.OR01_INSTAGRAM && (
                    <UniversalLink href={content.OR01_INSTAGRAM}>
                      <FontAwesomeIcon icon={['fab', 'instagram']} />
                    </UniversalLink>
                  )}
                  {content?.OR01_LINKEDIN && (
                    <UniversalLink href={content.OR01_LINKEDIN}>
                      <FontAwesomeIcon icon={['fab', 'linkedin']} />
                    </UniversalLink>
                  )}
                  {content?.OR01_TWITTER && (
                    <UniversalLink href={content.OR01_TWITTER}>
                      <FontAwesomeIcon icon={['fab', 'twitter']} />
                    </UniversalLink>
                  )}
                  {content?.OR01_YOUTUBE && (
                    <UniversalLink href={content.OR01_YOUTUBE}>
                      <FontAwesomeIcon icon={['fab', 'youtube']} />
                    </UniversalLink>
                  )}
                </div>
              )}
              {(content?.OR01_CONTATTO_TELEFONO ||
                content?.OR01_CONTATTO_MAIL) && (
                <div className="info contact-infos">
                  <span className="label">
                    {intl.formatMessage(messages.contact_infos)}
                  </span>

                  {content?.OR01_CONTATTO_TELEFONO && (
                    <span>
                      <a href={'tel:' + content?.OR01_CONTATTO_TELEFONO}>
                        {content?.OR01_CONTATTO_TELEFONO}
                      </a>
                    </span>
                  )}

                  {content?.OR01_CONTATTO_MAIL && (
                    <span>
                      <a href={'mailto:' + content?.OR01_CONTATTO_MAIL ?? ''}>
                        {content?.OR01_CONTATTO_MAIL}
                      </a>
                    </span>
                  )}
                </div>
              )}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default YCSpazioServizioView;
