import { defineMessages } from 'react-intl';
const messages = defineMessages({
  calendarLabel: {
    id: 'a11y_date_calendarLabel',
    defaultMessage: 'Calendario',
  },
  roleDescription: {
    id: 'a11y_date_roleDescription',
    defaultMessage: 'Selezionatore di date',
  },
  closeDatePicker: {
    id: 'a11y_date_closeDatePicker',
    defaultMessage: 'Chiudi',
  },
  focusStartDate: {
    id: 'a11y_date_focusStartDate',
    defaultMessage: 'Scegli la data di inizio.',
  },
  clearDates: {
    id: 'a11y_date_clearDates',
    defaultMessage: 'Cancella le date',
  },
  jumpToPrevMonth: {
    id: 'a11y_date_jumpToPrevMonth',
    defaultMessage: 'Torna indietro per passare al mese precedente.',
  },
  jumpToNextMonth: {
    id: 'a11y_date_jumpToNextMonth',
    defaultMessage: 'Vai avanti per passare al mese successivo.',
  },
  keyboardShortcuts: {
    id: 'a11y_date_keyboardShortcuts',
    defaultMessage: 'Tasti rapidi',
  },
  showKeyboardShortcutsPanel: {
    id: 'a11y_date_showKeyboardShortcutsPanel',
    defaultMessage: 'Apri il pannello dei tasti rapidi da tastiera.',
  },
  hideKeyboardShortcutsPanel: {
    id: 'a11y_date_hideKeyboardShortcutsPanel',
    defaultMessage: 'Chiudi il pannello dei tasti rapidi',
  },
  openThisPanel: {
    id: 'a11y_date_openThisPanel',
    defaultMessage: 'Apri questo pannello',
  },
  enterKey: {
    id: 'a11y_date_enterKey',
    defaultMessage: 'Tasto enter',
  },
  leftArrowRightArrow: {
    id: 'a11y_date_leftArrowRightArrow',
    defaultMessage: 'tasti per le frecce destra e sinistra',
  },
  upArrowDownArrow: {
    id: 'a11y_date_upArrowDownArrow',
    defaultMessage: 'tasti per le frecce su e giù',
  },
  pageUpPageDown: {
    id: 'a11y_date_pageUpPageDown',
    defaultMessage: 'Tasti pagina su e pagina giù',
  },
  homeEnd: {
    id: 'a11y_date_homeEnd',
    defaultMessage: 'tasti home e end',
  },
  escape: {
    id: 'a11y_date_escape',
    defaultMessage: 'Tasto esc',
  },
  questionMark: {
    id: 'a11y_date_questionMark',
    defaultMessage: 'Punto interrogativo',
  },
  selectFocusedDate: {
    id: 'a11y_date_selectFocusedDate',
    defaultMessage: 'Seleziona la data.',
  },
  moveFocusByOneDay: {
    id: 'a11y_date_moveFocusByOneDay',
    defaultMessage:
      'Spostati indietro (sinistra) o avanti (destra) di un giorno.',
  },
  moveFocusByOneWeek: {
    id: 'a11y_date_moveFocusByOneWeek',
    defaultMessage: 'Spostati indietro (su) o avanti (giù) di una settimana.',
  },
  moveFocusByOneMonth: {
    id: 'a11y_date_moveFocusByOneMonth',
    defaultMessage: 'Cambia mese.',
  },
  moveFocustoStartAndEndOfWeek: {
    id: 'a11y_date_moveFocustoStartAndEndOfWeek',
    defaultMessage: "Vai all'ultimo o al primo giorno della settimana.",
  },
  returnFocusToInput: {
    id: 'a11y_date_returnFocusToInput',
    defaultMessage: 'Torna al campo data.',
  },
  keyboardForwardNavigationInstructions: {
    id: 'a11y_date_keyboardForwardNavigationInstructions',
    defaultMessage:
      'Vai avanti per interagire con il calendario e selezionare una data. Premi il tasto del punto interrogativo per aprire il pannello dei tasti rapidi da tastiera per modificare le date.',
  },
  keyboardBackwardNavigationInstructions: {
    id: 'a11y_date_keyboardBackwardNavigationInstructions',
    defaultMessage:
      'Torna indietro per interagire con il calendario e selezionare una data. Premi il tasto del punto interrogativo per aprire il pannello dei tasti rapidi da tastiera per modificare le date.',
  },
  chooseAvailableStartDate: {
    id: 'a11y_date_chooseAvailableStartDate',
    defaultMessage: 'Scegli {date} come la data di inizio. É disponibile.',
  },
  chooseAvailableEndDate: {
    id: 'a11y_date_chooseAvailableEndDate',
    defaultMessage: 'Scegli {date} come la data di fine. É disponibile.',
  },
  dateIsUnavailable: {
    id: 'a11y_date_dateIsUnavailable',
    defaultMessage: 'Non disponibile. {date}',
  },
  dateIsSelected: {
    id: 'a11y_date_dateIsSelected',
    defaultMessage: 'Selezionato. {date}',
  },
  dateIsSelectedAsStartDate: {
    id: 'a11y_date_dateIsSelectedAsStartDate',
    defaultMessage: "Selezionata come data d'inizio {date}",
  },
  dateIsSelectedAsEndDate: {
    id: 'a11y_date_dateIsSelectedAsEndDate',
    defaultMessage: 'Selezionata come data di fine {date}',
  },
});

export const DateRangePickerPhrases = (intl) => {
  return {
    calendarLabel: intl.formatMessage(messages.calendarLabel),
    roleDescription: intl.formatMessage(messages.roleDescription),
    closeDatePicker: intl.formatMessage(messages.closeDatePicker),
    clearDates: intl.formatMessage(messages.clearDates),
    focusStartDate: intl.formatMessage(messages.focusStartDate),
    jumpToPrevMonth: intl.formatMessage(messages.jumpToPrevMonth),
    jumpToNextMonth: intl.formatMessage(messages.jumpToNextMonth),
    keyboardShortcuts: intl.formatMessage(messages.keyboardShortcuts),
    showKeyboardShortcutsPanel: intl.formatMessage(
      messages.showKeyboardShortcutsPanel,
    ),
    hideKeyboardShortcutsPanel: intl.formatMessage(
      messages.hideKeyboardShortcutsPanel,
    ),
    openThisPanel: intl.formatMessage(messages.openThisPanel),
    enterKey: intl.formatMessage(messages.enterKey),
    leftArrowRightArrow: intl.formatMessage(messages.leftArrowRightArrow),
    upArrowDownArrow: intl.formatMessage(messages.upArrowDownArrow),
    pageUpPageDown: intl.formatMessage(messages.pageUpPageDown),
    homeEnd: intl.formatMessage(messages.homeEnd),
    escape: intl.formatMessage(messages.escape),
    questionMark: intl.formatMessage(messages.questionMark),
    selectFocusedDate: intl.formatMessage(messages.selectFocusedDate),
    moveFocusByOneDay: intl.formatMessage(messages.moveFocusByOneDay),
    moveFocusByOneWeek: intl.formatMessage(messages.moveFocusByOneWeek),
    moveFocusByOneMonth: intl.formatMessage(messages.moveFocusByOneMonth),
    moveFocustoStartAndEndOfWeek: intl.formatMessage(
      messages.moveFocustoStartAndEndOfWeek,
    ),
    returnFocusToInput: intl.formatMessage(messages.returnFocusToInput),
    keyboardForwardNavigationInstructions: intl.formatMessage(
      messages.keyboardForwardNavigationInstructions,
    ),
    keyboardBackwardNavigationInstructions: intl.formatMessage(
      messages.keyboardBackwardNavigationInstructions,
    ),
    chooseAvailableStartDate: ({ date }) =>
      intl.formatMessage(messages.chooseAvailableStartDate, { date: date }),
    chooseAvailableEndDate: ({ date }) =>
      intl.formatMessage(messages.chooseAvailableEndDate, { date: date }),
    dateIsUnavailable: ({ date }) =>
      intl.formatMessage(messages.dateIsUnavailable, { date: date }),
    dateIsSelected: ({ date }) =>
      intl.formatMessage(messages.dateIsSelected, { date: date }),
    dateIsSelectedAsStartDate: ({ date }) =>
      intl.formatMessage(messages.dateIsSelectedAsStartDate, { date: date }),
    dateIsSelectedAsEndDate: ({ date }) =>
      intl.formatMessage(messages.dateIsSelectedAsEndDate, { date: date }),
  };
};
