import React from 'react';

import { useSelector } from 'react-redux';

import { Icon } from 'semantic-ui-react';
import cx from 'classnames';

const SocialLinksSubsite = ({ wrapperCssClass, itemCssClass }) => {
  const subsite = useSelector((state) => state.subsite?.data);

  const socialSettings =
    subsite?.subsite_social_links?.length > 0
      ? JSON.parse(subsite.subsite_social_links)
      : null;

  return socialSettings?.length > 0 ? (
    <div className={cx('social-header', wrapperCssClass)}>
      {socialSettings.map((social) => {
        const { title, icon, url } = social;

        return (
          <a
            href={url}
            rel="noopener noreferrer"
            className={cx(itemCssClass)}
            target="_blank"
            title={title}
            key={url}
          >
            <Icon name={icon} />
          </a>
        );
      })}
    </div>
  ) : null;
};

export default SocialLinksSubsite;
