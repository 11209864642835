import React from 'react';

import { defineMessages, useIntl } from 'react-intl';
import moment from 'moment';

import { Message, Grid, List } from 'semantic-ui-react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { flattenToAppURL, parseDateTime } from '@plone/volto/helpers';
import { Image } from '@plone/volto/components';

import { Subjects, Sharing } from '@package/components/View';
import { datesForDisplay } from '@package/helpers';

const messages = defineMessages({
  ImageBlockInputPlaceholder: {
    id: "Upload a lead image in the 'Lead Image' content field.",
    defaultMessage: "Upload a lead image in the 'Lead Image' content field.",
  },
  dal: {
    id: 'dal_data',
    defaultMessage: 'Dal',
  },
  al: {
    id: 'al_data',
    defaultMessage: 'al',
  },
  dalle_ore: {
    id: 'dalle_ore',
    defaultMessage: 'Dalle',
  },
  alle_ore: {
    id: 'alle_ore',
    defaultMessage: 'alle',
  },
  il_giorno: {
    id: 'il_giorno',
    defaultMessage: 'Il',
  },
  excluded_dates: { id: 'tranne_il', defaultMessage: 'tranne il' },
  visitWebsite: { id: 'visitWebsite', defaultMessage: 'Visita il sito' },
});

const Body = ({ data, properties, isEditMode }) => {
  const intl = useIntl();
  moment.locale(intl.locale);
  const placeholder =
    data.placeholder || intl.formatMessage(messages.ImageBlockInputPlaceholder);

  const image = properties.image
    ? properties.image.data
      ? `data:${properties.image['content-type']};base64,${properties.image.data}`
      : flattenToAppURL(properties.image.scales.large.download)
    : null;

  const datesInfo = datesForDisplay(
    properties.start,
    properties.end,
    properties.recurrence,
    intl,
    moment,
  );

  return (
    <>
      <Subjects subjects={properties.subjects} />

      {isEditMode && !properties.image && (
        <Message>
          <center>
            <div className="message-text">{placeholder}</div>
          </center>
        </Message>
      )}

      <Grid padded>
        <Grid.Row stretched>
          {image && (
            <Grid.Column
              mobile={12}
              tablet={12}
              computer={12}
              largeScreen={9}
              widescreen={9}
              className="image-column"
            >
              <div className="image-wrapper">
                <Image src={image} alt={properties.image_caption || ''} />
              </div>
            </Grid.Column>
          )}
          <Grid.Column
            mobile={12}
            tablet={12}
            computer={12}
            largeScreen={image ? 3 : 4}
            widescreen={image ? 3 : 4}
            className="event-infos"
          >
            {/* Date */}
            <div className="event-info">
              <div className="label">
                <FontAwesomeIcon icon={['fas', 'calendar-alt']} />
              </div>
              <div>
                {!datesInfo.sameDay ? (
                  <>
                    {intl.formatMessage(messages.dal)} {datesInfo.startDate}
                    {!properties.open_end && (
                      <>
                        &nbsp;{intl.formatMessage(messages.al)}&nbsp;
                        {datesInfo.endDate}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {intl.formatMessage(messages.il_giorno)}{' '}
                    {datesInfo.startDate}
                  </>
                )}

                {datesInfo.eventRecurrenceText && (
                  <div className="recurrence">
                    {datesInfo.eventRecurrenceText}
                    {datesInfo.rruleSet?.exdates().length > 0 && (
                      <div>
                        {intl.formatMessage(messages.excluded_dates)}{' '}
                        {datesInfo.rruleSet.exdates().map((exDate, index) => {
                          return (
                            <>
                              {parseDateTime(
                                intl.locale,
                                exDate.toISOString(),
                                'DD.MM.YYYY',
                              )}
                              {index < datesInfo.rruleSet.exdates().length - 1
                                ? ', '
                                : ''}
                            </>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            {/* Orari */}
            {!properties.whole_day && (
              <div className="event-info">
                <div className="label">
                  <FontAwesomeIcon icon={['fas', 'clock']} />
                </div>
                <div>
                  <>
                    {intl.formatMessage(messages.dalle_ore)}{' '}
                    {datesInfo.startTime}
                    {!properties.open_end && (
                      <>
                        &nbsp; {intl.formatMessage(messages.alle_ore)}{' '}
                        {datesInfo.endTime}
                      </>
                    )}
                  </>
                </div>
              </div>
            )}

            {/* Dove */}
            {properties.location && (
              <div className="event-info">
                <div className="label">
                  <FontAwesomeIcon icon={['fas', 'map-marker-alt']} />
                </div>
                <div>{properties.location}</div>
              </div>
            )}

            {/* URL evento */}
            {properties.event_url && (
              <div className="event-info">
                <div className="label">
                  <FontAwesomeIcon icon={['fas', 'link']} />
                </div>
                <div>
                  <a
                    href={properties.event_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {intl.formatMessage(messages.visitWebsite)}
                  </a>
                </div>
              </div>
            )}

            {/* Contatti */}
            {(properties.contact_name ||
              properties.contact_phone ||
              properties.contact_email) && (
              <div className="event-info">
                <div className="label">
                  <FontAwesomeIcon icon={['fas', 'phone']} />
                </div>
                <div>
                  {properties.contact_name && (
                    <div>{properties.contact_name}</div>
                  )}
                  {properties.contact_email && (
                    <div>
                      <a href={`mailto:${properties.contact_email}`}>
                        {properties.contact_email}
                      </a>
                    </div>
                  )}
                  {properties.contact_phone && (
                    <div>
                      <a href={`tel:${properties.contact_phone}`}>
                        {properties.contact_phone}
                      </a>
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* Partecipanti */}
            {properties.attendees.length > 0 && (
              <div className="event-info">
                <div className="label">
                  <FontAwesomeIcon icon={['fas', 'users']} />
                </div>
                <div>
                  <List items={properties.attendees} />
                </div>
              </div>
            )}

            {/* Share */}
            <div className="event-info">
              <div className="label">
                <FontAwesomeIcon icon={['fas', 'share-alt']} />
              </div>
              <div>
                <Sharing url={properties['@id']} title={properties.title} />
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default Body;
