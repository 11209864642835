import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { SelectWidget, TextWidget } from '@plone/volto/components';
import QuerystringWidget from '@plone/volto/components/manage/Widgets/QuerystringWidget';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  Source: {
    id: 'Source',
    defaultMessage: 'Source',
  },
  itemBatchSize: {
    id: 'Item batch size',
    defaultMessage: 'Item batch size',
  },
});

const Sidebar = (props) => {
  const intl = useIntl();
  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage
            id="SearchEventsBlock"
            defaultMessage="Ricerca eventi"
          />
        </h2>
      </header>
      <Segment className="form sidebar-listing-data">
        <SelectWidget
          id="whichEvents"
          title="Quali eventi"
          required={false}
          description="Se impostato, il blocco preimposterà le date per mostrare gli eventi che corrispondono al valore selezionato"
          value={props.data.whichEvents}
          onChange={(id, value) => {
            props.onChangeBlock(props.block, {
              ...props.data,
              [id]: value,
            });
          }}
          choices={[
            ['today', 'Del giorno'],
            ['weekend', 'Del weekend'],
            ['month', 'Del mese'],
          ]}
        />

        <QuerystringWidget
          id="source"
          title={intl.formatMessage(messages.Source)}
          required={false}
          value={props.data.query || []}
          onChange={(name, value) => {
            props.onChangeBlock(props.block, {
              ...props.data,
              query: value,
            });
          }}
        />
      </Segment>
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
};

export default injectIntl(Sidebar);
