import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';
import { defineMessages, useIntl } from 'react-intl';
import { ReadMore } from '@package/components';
import { ConditionalLink, Component } from '@plone/volto/components';

const messages = defineMessages({
  open_tappa: {
    id: 'open_tappa',
    defaultMessage: 'Scopri di più',
  },
  label_show_more: {
    id: 'label_show_more',
    defaultMessage: 'Vai al dettaglio della tappa',
  },
});

const TimelineListing = (props) => {
  const intl = useIntl();
  const { items, isEditMode } = props;
  moment.locale(intl.locale);

  const linkMore =
    props.linkHref?.length > 0
      ? { href: props.linkHref[0]['@id'], title: props.linkTitle }
      : null;

  const checkFutureItem = (itemDate) => {
    const today = moment();
    if (itemDate) {
      return today.diff(itemDate) < 0;
    } else {
      return false;
    }
  };

  const getItemYear = (item) => {
    return item?.opening_date ? moment(item.opening_date).year() : null;
  };

  return (
    <div className="timeline-listing">
      {items.length > 0 && (
        <ul className="container-list">
          {items.map((item, index) => {
            return (
              <>
                {/* ITEM YEAR */}
                {getItemYear(item) !== null &&
                  (getItemYear(item) !== getItemYear(items[index - 1]) ||
                    !items[index - 1]) && (
                    <li className="item year right">{getItemYear(item)}</li>
                  )}
                {/* ITEM TAPPA */}
                <li
                  key={index}
                  className={cx('item', {
                    expanded: checkFutureItem(item?.opening_date),
                    left: index % 2 !== 0,
                    right: index % 2 === 0,
                  })}
                >
                  <div>
                    <div className="content">
                      {!checkFutureItem(item?.opening_date) ? (
                        <ConditionalLink
                          condition={!isEditMode}
                          item={item}
                          className="title-link"
                        >
                          {item?.opening_date && (
                            <>
                              <h4 className="date">
                                {moment(item.opening_date).format('DD.MM.YYYY')}
                              </h4>
                              <span> | </span>
                            </>
                          )}
                          <h4 className="title">{item.title}</h4>
                        </ConditionalLink>
                      ) : (
                        <>
                          {item?.opening_date && (
                            <h4 className="date">
                              {moment(item.opening_date).format('DD.MM.YYYY')}
                            </h4>
                          )}
                          <h4 className="title">{item.title}</h4>
                        </>
                      )}
                      {checkFutureItem(item?.opening_date) && (
                        <>
                          {item?.description && (
                            <div className="description">
                              <p>{item.description}</p>
                            </div>
                          )}
                          <Component
                            componentName="PreviewImage"
                            className="preview-image"
                            item={item}
                            alt={item.title}
                          />
                          <h4 className="show-more">
                            <ConditionalLink
                              condition={!isEditMode}
                              item={item}
                              aria-label={
                                moment(item.opening_date).format('DD.MM.YYYY') +
                                ' ' +
                                intl.formatMessage(messages.label_show_more) +
                                ': ' +
                                item.title
                              }
                            >
                              {intl.formatMessage(messages.open_tappa)}
                            </ConditionalLink>
                          </h4>
                        </>
                      )}
                    </div>
                  </div>
                </li>
              </>
            );
          })}
        </ul>
      )}

      <ReadMore linkMore={linkMore} isEditMode={props.isEditMode} />
    </div>
  );
};

TimelineListing.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any),
};

export default TimelineListing;
