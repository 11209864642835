import React from 'react';
import { List } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { UniversalLink } from '@plone/volto/components';
import { displayBanner } from 'volto-gdpr-privacy';

const messages = defineMessages({
  siteInfos: {
    id: 'Site infos',
    defaultMessage: 'Site infos',
  },
  cookieSettings: {
    id: 'Cookie settings',
    defaultMessage: 'Impostazioni cookie',
  },
});
const FooterSmall = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const links = {
    it: [
      {
        href: '/it/accessibilita',
        label: 'Dichiarazione di accessibilità',
      },
      {
        href: '/it/sitemap',
        label: 'Mappa del sito',
      },
      {
        href: '/it/privacy',
        label: 'Privacy',
      },
      {
        href: '/it/note-legali',
        label: 'Note legali',
      },
    ],
    en: [
      {
        href: '/en/accessibility',
        label: 'Accessibility statement',
      },
      {
        href: '/en/sitemap',
        label: 'Sitemap',
      },
      {
        href: '/en/privacy',
        label: 'Privacy',
      },
      {
        href: '/en/legal-notes',
        label: 'Legal notes',
      },
    ],
  };

  return (
    <div
      className="footer-small"
      aria-label={intl.formatMessage(messages.siteInfos)}
      role="contentinfo"
    >
      <List horizontal inverted>
        {/* wrap in div for a11y reasons: listitem role cannot be on the <a> element directly */}
        {links[intl.locale].map((item) => (
          <div role="listitem" className="item" key={item.href}>
            <UniversalLink className="item" href={item.href}>
              {item.label}
            </UniversalLink>
          </div>
        ))}
        <div role="listitem" className="item">
          <button
            className="footer-gdpr-privacy-show-banner"
            onClick={(e) => {
              e.preventDefault();
              dispatch(displayBanner(true, true));
            }}
            title={intl.formatMessage(messages.cookieSettings)}
          >
            {intl.formatMessage(messages.cookieSettings)}
          </button>
        </div>
      </List>
    </div>
  );
};

export default FooterSmall;
