import React from 'react';
import PropTypes from 'prop-types';
import redraft from 'redraft';
import { useIntl, defineMessages } from 'react-intl';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReadMore } from '@package/components';
import config from '@plone/volto/registry';

const messages = defineMessages({
  vedi: {
    id: 'Vedi',
    defaultMessage: 'Vedi',
  },
});

/**
 * ViewBlock class.
 * @class ViewBlock
 * @extends Component
 */
const ViewBlock = ({ data, isOpen, toggle, id, index }) => {
  const intl = useIntl();
  return (
    <div className="accordion-item subblock-view">
      {data.title && (
        <h3 className={cx('accordion-header', { open: isOpen })}>
          <button
            onClick={toggle()}
            aria-expanded={isOpen}
            aria-controls={`content-${id}-${index}`}
            id={`${id}-${index}`}
          >
            <FontAwesomeIcon icon={['fas', isOpen ? 'minus' : 'plus']} />
            {redraft(
              data.title,
              config.settings.richtextViewSettings.ToHTMLRenderers,
              config.settings.richtextViewSettings.ToHTMLOptions,
            )}
          </button>
        </h3>
      )}

      {data.text && (
        <div
          className={cx('accordion-content', { open: isOpen })}
          id={`content-${id}-${index}`}
          role="region"
          aria-labelledby={`${id}-${index}`}
          aria-expanded={isOpen}
        >
          <div className="accordion-inner">
            {redraft(
              data.text,
              config.settings.richtextViewSettings.ToHTMLRenderers,
              config.settings.richtextViewSettings.ToHTMLOptions,
            )}
          </div>
          {data.href && (
            <ReadMore
              linkMore={{
                title: data.linkMoreTitle || intl.formatMessage(messages.vedi),
                href: data.href,
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ViewBlock.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ViewBlock;
