import React from 'react';

import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Segment, Form, Divider, Header } from 'semantic-ui-react';
import withObjectBrowser from '@plone/volto/components/manage/Sidebar/ObjectBrowser';
import { SelectWidget, TextWidget, Icon } from '@plone/volto/components';
import linkSVG from '@plone/volto/icons/link.svg';

import { LinkToWidget } from '@package/components/';

export const MAP_TYPE_YC = 'yc';
export const MAP_TYPE_SPAZI_E_SERVIZI = 'spazi_e_servizi';
export const MAP_TYPE_CREATIVITA = 'creativita';
export const MAP_TYPE_WEB_RADIO = 'web_radio';
export const MAP_TYPE_STREETART_SPAZI_CREATIVI = 'streetart_spazicreativi';
export const MAP_TYPE_TAPPE_YOUZ = 'tappe_youz';

const messages = defineMessages({
  LinkTitle: {
    id: 'Link more title',
    defaultMessage: 'Titolo link',
  },
  title: {
    id: 'Titolo',
    defaultMessage: 'Titolo',
  },
  title_help: {
    id: 'Titolo del blocco',
    defaultMessage: 'Titolo del blocco',
  },
  linkMore: {
    id: 'Link more',
    defaultMessage: 'Link ad altro',
  },
});

const Sidebar = ({ data, onChangeBlock, block, intl, openObjectBrowser }) => {
  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage
            id="YoungerCard Map"
            defaultMessage="Mappa YoungER Card"
          />
        </h2>
      </header>

      <Segment className="form sidebar-button">
        <Form>
          <SelectWidget
            id="mapType"
            title="Tipo di mappa"
            required={true}
            description="Scegliere il tipo di mappa da mostrare. YoungERCard: mostra convenzini, progetti, punti di distribuzione. Spazie e servizi: mostra spazi e servizi. Creatività: mostra gli spazi e i servizi di tipo 'sale prova' e 'centri culturali'. Web Radio: mostra gli spazie e servizi di tipo 'Webradio giovani'"
            value={data.mapType ?? 'yc'}
            onChange={(id, value) => {
              onChangeBlock(block, {
                ...data,
                [id]: value,
              });
            }}
            choices={[
              [MAP_TYPE_YC, 'YoungER Card'],
              [MAP_TYPE_SPAZI_E_SERVIZI, 'Spazi e servizi'],
              [MAP_TYPE_CREATIVITA, 'Creatività'],
              [MAP_TYPE_WEB_RADIO, 'Web radio'],
              [
                MAP_TYPE_STREETART_SPAZI_CREATIVI,
                'Street Art e Spazi creativi',
              ],
              [MAP_TYPE_TAPPE_YOUZ, 'Tappe Youz'],
            ]}
          />

          <TextWidget
            id="title"
            title={intl.formatMessage(messages.title)}
            description={intl.formatMessage(messages.title_help)}
            required={false}
            value={data.title}
            onChange={(name, value) => {
              onChangeBlock(block, {
                ...data,
                [name]: value,
              });
            }}
          />

          <Divider horizontal>
            <Header as="h6">
              <Icon name={linkSVG} size="24px" />
              {intl.formatMessage(messages.linkMore)}
            </Header>
          </Divider>
          <TextWidget
            id="linkTitle"
            title={intl.formatMessage(messages.LinkTitle)}
            required={false}
            value={data.linkMore?.title}
            onChange={(name, value) => {
              onChangeBlock(block, {
                ...data,
                linkMore: {
                  ...data.linkMore,
                  title: value,
                },
              });
            }}
          />

          <LinkToWidget
            data={data.linkMore ?? {}}
            openObjectBrowser={openObjectBrowser}
            onChange={(name, value) => {
              onChangeBlock(block, {
                ...data,
                linkMore: { ...data.linkMore, [name]: value },
              });
            }}
          />
        </Form>
      </Segment>
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  block: PropTypes.string.isRequired,
};

export default compose(withObjectBrowser, injectIntl)(Sidebar);
