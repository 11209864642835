import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import {
  FormattedMessage,
  injectIntl,
  defineMessages,
  useIntl,
} from 'react-intl';
import { SelectWidget, CheckboxWidget } from '@plone/volto/components';
import { AlignWidget, ColorListWidget } from '@package/components';

const messages = defineMessages({
  numero_colonne: {
    id: 'Quantità Colonne',
    defaultMessage: 'Numero di colonne',
  },
  column_width_label: {
    id: 'Dimensione colonne',
    defaultMessage: 'Colonne più ampie',
  },
  colore_sfondo: {
    id: 'Colore di sfondo del blocco',
    defaultMessage: 'Colore di sfondo',
  },
  title_size: {
    id: 'Dimensione del titolo',
    defaultMessage: 'Dimensione del titolo',
  },
  textAlign: {
    id: 'Allineamento testo',
    defaultMessage: 'Allineamento del testo',
  },
});

const Sidebar = (props) => {
  const { data, onChangeBlock, block } = props;
  useEffect(() => {
    if (!data.bg_color) {
      onChangeBlock(block, {
        ...data,
        bg_color: 'bg-secondary',
      });
    }
    if (!data.column_num) {
      onChangeBlock(block, {
        ...data,
        column_num: '1',
      });
    }
  }, [data]);

  const intl = useIntl();

  return (
    <Segment.Group raised>
      <header className="header pulled main-title">
        <h2>
          <FormattedMessage
            id="column-text"
            defaultMessage="Testo incolonnato"
          />
          :
        </h2>
      </header>
      <header className="header pulled">
        <h2>
          <FormattedMessage id="titleColumn" defaultMessage="Colonne" />:
        </h2>
      </header>
      <Segment className="form">
        <SelectWidget
          id="column_num"
          title={intl.formatMessage(messages.numero_colonne)}
          choices={[
            ['1', '1'],
            ['2', '2'],
          ]}
          value={data.column_num ?? '1'}
          noValueOption={false}
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              [name]: value,
            });
          }}
        />
        <CheckboxWidget
          id="column_width"
          title={intl.formatMessage(messages.column_width_label)}
          value={data.column_width ?? false}
          onChange={(name, value) => {
            onChangeBlock(block, { ...data, [name]: value });
          }}
        />
        <header className="header pulled">
          <h2>
            <FormattedMessage
              id="titleGeneral"
              defaultMessage="Impostazioni generali"
            />
            :
          </h2>
        </header>
        {/*Select BG color full-width*/}
        <ColorListWidget
          id="bg_color"
          title={intl.formatMessage(messages.colore_sfondo)}
          colors={[
            { name: 'bg-primary', label: 'Colore primario' },
            { name: 'bg-secondary', label: 'Colore secondario' },
            { name: 'bg-tertiary', label: 'Colore terziario' },
          ]}
          value={data.bg_color ?? 'bg-secondary'}
          onChange={(name, value) =>
            onChangeBlock(block, { ...data, [name]: value })
          }
        />
        <SelectWidget
          id="title_size"
          title={intl.formatMessage(messages.title_size)}
          choices={[
            ['h1', 'H1'],
            ['h2', 'H2'],
          ]}
          value={data.title_size ?? 'h2'}
          noValueOption={false}
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              [name]: value,
            });
          }}
        />
        <AlignWidget
          value={data.alignText ?? 'left'}
          id="alignText"
          onChange={(name, value) =>
            onChangeBlock(block, { ...data, [name]: value })
          }
          alignments={['left', 'center']}
          title={intl.formatMessage(messages.textAlign)}
        />
      </Segment>
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  openObjectBrowser: PropTypes.func.isRequired,
};

export default injectIntl(Sidebar);
