import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * View Video Gallery body class
 * @class Body
 * @extends Component
 */
const Body = ({
  data,
  children,
  items,
  className,
  isEditMode,
  subIndexSelected,
}) => {
  const slidesToShow = 3;
  const settings = {
    dots: true,
    infinite: !isEditMode && items?.length > slidesToShow,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    focusOnSelect: true,

    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slider = useRef(null);

  useEffect(() => {
    slider.current.slickGoTo(subIndexSelected);
  }, [subIndexSelected]);

  return (
    <div className={`videogallery-body ${className ?? ''}`}>
      {data?.title && <h2>{data.title}</h2>}
      {(data?.channel_link || data?.channel_link_title) && (
        <div className="channel">
          <FontAwesomeIcon icon={['fab', 'youtube']} />
          {data.channel_link ? (
            <a
              href={data.channel_link}
              rel="noopener noreferrer"
              target="_blank"
            >
              {data.channel_link_title || data.channel_link}
            </a>
          ) : (
            <span>{data.channel_link_title}</span>
          )}
        </div>
      )}

      <div className="slider-container">
        <Slider {...settings} ref={slider}>
          {children}
        </Slider>
      </div>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Body.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Body;
