import React from 'react';
import redraft from 'redraft';
import config from '@plone/volto/registry';
import cx from 'classnames';
import { Container, Grid } from 'semantic-ui-react';

const View = ({ data }) => {
  return (
    <section
      className={cx('column-text-block full-width', {
        [data.bg_color]: data.bg_color,
        ['alignText-' + data.alignText]: data.alignText,
        'column-fullWidth': data.column_width,
      })}
    >
      <Container>
        {data.title &&
          (data.title_size === 'h1' ? (
            <h1 className="title">{data.title}</h1>
          ) : (
            <h2 className="title">{data.title}</h2>
          ))}
        <Grid columns={data.column_num}>
          <Grid.Column>
            {data.text1 && (
              <div className="text-content">
                {redraft(
                  data.text1,
                  config.settings.richtextViewSettings.ToHTMLRenderers,
                  config.settings.richtextViewSettings.ToHTMLOptions,
                )}
              </div>
            )}
          </Grid.Column>
          <Grid.Column>
            {data.text2 && (
              <div className="text-content">
                {redraft(
                  data.text2,
                  config.settings.richtextViewSettings.ToHTMLRenderers,
                  config.settings.richtextViewSettings.ToHTMLOptions,
                )}
              </div>
            )}
          </Grid.Column>
        </Grid>
      </Container>
    </section>
  );
};

export default View;
