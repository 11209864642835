import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { flattenToAppURL, flattenHTMLToAppURL } from '@plone/volto/helpers';
import { Container } from 'semantic-ui-react';
import { isSubsiteRoot } from 'volto-subsites';

const HomeHeaderSubsite = () => {
  const subsite = useSelector((state) => state.subsite?.data);
  const location = useLocation();
  let style = {};

  style['backgroundImage'] = subsite?.image?.download
    ? `linear-gradient(rgba(25,25,25,0.8), rgba(25,25,25,0.8)), url(${flattenToAppURL(
        subsite.image.download,
      )})`
    : 'linear-gradient(rgba(25,25,25,0.8), rgba(25,25,25,0.8))';

  const richTextHasContent = (text) => {
    const textToDisplay = text?.replace(/(<([^>]+)>)/g, '') ?? '';
    return textToDisplay.length > 0 ? true : false;
  };

  return subsite &&
    isSubsiteRoot(location.pathname, subsite) &&
    (Object.keys(style).length > 0 ||
      richTextHasContent(subsite?.subsite_header?.data)) ? (
    <div className="home-header-subsite" style={style}>
      <div className="text">
        <Container>
          {subsite.subsite_header?.data && (
            <div
              dangerouslySetInnerHTML={{
                __html: flattenHTMLToAppURL(subsite.subsite_header.data),
              }}
            />
          )}
        </Container>
      </div>
    </div>
  ) : null;
};

export default HomeHeaderSubsite;
