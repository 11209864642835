import React from 'react';
import { TextEditorWidget } from '@package/components';
import { useIntl, defineMessages } from 'react-intl';
import { Container, Input } from 'semantic-ui-react';

const messages = defineMessages({
  numberPlaceholder: {
    id: 'Number placeholder',
    defaultMessage: 'Numero...',
  },
  textPlaceholder: {
    id: 'Text placeholder',
    defaultMessage: 'Inserisci il testo...',
  },
});

const Edit = (props) => {
  const { data, block, onChangeBlock } = props;
  const intl = useIntl();

  return (
    <div className="tableOfNumbers-wrapper full-width">
      <Container className="flex-container">
        <div className="cell">
          <div className="number">
            <Input
              fluid
              className="input-number"
              transparent
              placeholder={intl.formatMessage(messages.numberPlaceholder)}
              value={data.number1 || ''}
              onClick={(e) => {
                //focusField('title');
                e.stopPropagation();
              }}
              onChange={(v) =>
                onChangeBlock(block, {
                  ...data,
                  number1: v.target.value,
                })
              }
            />
          </div>
          <div className="description">
            <TextEditorWidget
              data={data}
              fieldName="description1"
              onChangeBlock={(v) => {
                onChangeBlock(block, {
                  ...data,
                  description1: v.description1,
                });
              }}
              placeholder={intl.formatMessage(messages.textPlaceholder)}
              showToolbar={false}
            />
          </div>
        </div>
        <div className="cell">
          <div className="number">
            <Input
              fluid
              className="input-number"
              transparent
              placeholder={intl.formatMessage(messages.numberPlaceholder)}
              value={data.number2 || ''}
              onClick={(e) => {
                //focusField('title');
                e.stopPropagation();
              }}
              onChange={(v) =>
                onChangeBlock(block, {
                  ...data,
                  number2: v.target.value,
                })
              }
            />
          </div>
          <div className="description">
            <TextEditorWidget
              data={data}
              fieldName="description2"
              onChangeBlock={(v) => {
                onChangeBlock(block, {
                  ...data,
                  description2: v.description2,
                });
              }}
              placeholder={intl.formatMessage(messages.textPlaceholder)}
              showToolbar={false}
            />
          </div>
        </div>
        <div className="cell">
          <div className="number">
            <Input
              fluid
              className="input-number"
              transparent
              placeholder={intl.formatMessage(messages.numberPlaceholder)}
              value={data.number3 || ''}
              onClick={(e) => {
                //focusField('title');
                e.stopPropagation();
              }}
              onChange={(v) =>
                onChangeBlock(block, {
                  ...data,
                  number3: v.target.value,
                })
              }
            />
          </div>
          <div className="description">
            <TextEditorWidget
              data={data}
              fieldName="description3"
              onChangeBlock={(v) => {
                onChangeBlock(block, {
                  ...data,
                  description3: v.description3,
                });
              }}
              placeholder={intl.formatMessage(messages.textPlaceholder)}
              showToolbar={false}
            />
          </div>
        </div>
        <div className="cell">
          <div className="number">
            <Input
              fluid
              className="input-number"
              transparent
              placeholder={intl.formatMessage(messages.numberPlaceholder)}
              value={data.number4 || ''}
              onClick={(e) => {
                //focusField('title');
                e.stopPropagation();
              }}
              onChange={(v) =>
                onChangeBlock(block, {
                  ...data,
                  number4: v.target.value,
                })
              }
            />
          </div>
          <div className="description">
            <TextEditorWidget
              data={data}
              fieldName="description4"
              onChangeBlock={(v) => {
                onChangeBlock(block, {
                  ...data,
                  description4: v.description4,
                });
              }}
              placeholder={intl.formatMessage(messages.textPlaceholder)}
              showToolbar={false}
            />
          </div>
        </div>
        <div className="cell">
          <div className="number">
            <Input
              fluid
              className="input-number"
              transparent
              placeholder={intl.formatMessage(messages.numberPlaceholder)}
              value={data.number5 || ''}
              onClick={(e) => {
                //focusField('title');
                e.stopPropagation();
              }}
              onChange={(v) =>
                onChangeBlock(block, {
                  ...data,
                  number5: v.target.value,
                })
              }
            />
          </div>
          <div className="description">
            <TextEditorWidget
              data={data}
              fieldName="description5"
              onChangeBlock={(v) => {
                onChangeBlock(block, {
                  ...data,
                  description5: v.description5,
                });
              }}
              placeholder={intl.formatMessage(messages.textPlaceholder)}
              showToolbar={false}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Edit;
