import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { UniversalLink } from '@plone/volto/components';

import { ReadMore, ExternalSource, PreviewImage } from '@package/components';

const SquaresTemplate = ({ items, linkHref, linkTitle, isEditMode, title }) => {
  const linkMore =
    linkHref?.length > 0
      ? { href: linkHref[0]['@id'], title: linkTitle }
      : null;

  return items.length > 0 ? (
    <div className="card-circle-image-template">
      {title && <h2 className="text-center">{title}</h2>}
      <Grid stretched stackable>
        {items.map((item) => {
          return (
            <Grid.Column key={item['@id']} mobile={6}>
              <div className="listing-item">
                <div className="text-wrapper">
                  <UniversalLink
                    item={!isEditMode ? item : null}
                    href={isEditMode ? '#' : null}
                  >
                    <div className="card-title">
                      {item.title ? item.title : item.id}
                    </div>
                  </UniversalLink>
                  {item.description && <p>{item.description}</p>}
                  <ExternalSource item={item} />
                </div>
                <div className="image-wrapper">
                  <UniversalLink
                    tabIndex="-1"
                    item={!isEditMode ? item : null}
                    href={isEditMode ? '#' : null}
                  >
                    <PreviewImage item={item} />
                  </UniversalLink>
                </div>
              </div>
            </Grid.Column>
          );
        })}
      </Grid>

      <ReadMore linkMore={linkMore} isEditMode={isEditMode} />
    </div>
  ) : null;
};
SquaresTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};
export default SquaresTemplate;
