/**
 * Edit Links block, single block
 * @module components/manage/Blocks/LinksBlock/Block/EditBlock
 */

import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { Input, Grid } from 'semantic-ui-react';
import { TextEditorWidget } from '@package/components';
import { Icon, UniversalLink } from '@plone/volto/components';
import arrowSVG from '@package/components/layout/icons/circle-right.svg';
import cx from 'classnames';

const messages = defineMessages({
  infoLink: {
    id: 'More information link with title',
    defaultMessage: 'Maggiori dettagli su {title}.',
  },
  infoLinkBlank: {
    id: 'More information link without title',
    defaultMessage: 'Apri link {path}',
  },
  textSection: {
    id: 'Text section',
    defaultMessage: 'Sezione...',
  },
  textTitle: {
    id: 'Text for title',
    defaultMessage: 'Titolo...',
  },
  textSubtitle: {
    id: 'Text for subtitle',
    defaultMessage: 'Sottotitolo...',
  },
  titlePlaceholder: {
    id: 'Text for subtitle',
    defaultMessage: 'Sottotitolo...',
  },
});

const EditBlock = ({
  data,
  index,
  focusOn,
  nColumns,
  setFocusOn,
  onChange,
  selected,
}) => {
  const intl = useIntl();

  return __SERVER__ ? (
    <div />
  ) : (
    <Grid.Column
      computer={
        nColumns > 3
          ? 3
          : nColumns === 1
          ? 12
          : nColumns === 2
          ? 6
          : nColumns === 3 && 4
      }
      tablet={6}
      mobile={12}
    >
      <div
        className={cx(
          'single-card',
          data?.bg_color_card ? data.bg_color_card : 'sectionGreen',
        )}
      >
        <h2 className="section">
          <Input
            fluid
            transparent
            placeholder={intl.formatMessage(messages.textSection)}
            value={data.section || ''}
            name={'section' + index}
            onChange={(v) => {
              onChange(index, 'section', v.target.value);
            }}
          />
        </h2>

        <h3 className="title">
          <Input
            fluid
            transparent
            placeholder={intl.formatMessage(messages.textTitle)}
            value={data.title || ''}
            name={'title' + index}
            onChange={(v) => {
              onChange(index, 'title', v.target.value);
            }}
          />
        </h3>
        <TextEditorWidget
          data={data}
          fieldName={'subtitle'}
          onChangeBlock={(v) => {
            onChange(index, 'subtitle', v.subtitle);
          }}
          placeholder={intl.formatMessage(messages.textSubtitle)}
          setFocus={(f) => setFocusOn(f)}
          showToolbar={true}
        />
        {data?.href?.length > 0 && (
          <div className="icon-link">
            <UniversalLink
              item={data.href[0]}
              title={
                data.title
                  ? intl.formatMessage(messages.infoLink, {
                      title: data.title,
                    })
                  : intl.formatMessage(messages.infoLinkBlank, {
                      path: data.href,
                    })
              }
            >
              <Icon name={arrowSVG} />
            </UniversalLink>
          </div>
        )}
      </div>
    </Grid.Column>
  );
};

export default React.memo(EditBlock);
