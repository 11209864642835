/**
 * Footer component.
 * @module components/theme/Footer/Footer
 *
 * Customization:
 * - Id footer added to footer section
 */

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid } from 'semantic-ui-react';

import { flattenHTMLToAppURL } from '@plone/volto/helpers';
import LogoRegione from '@package/components/layout/images/logo-regione.svg';
import LogoGiovazoom from '@package/components/layout/images/logo-white.svg';
import { FooterSubsite } from '@package/components/layout';
import { SocialLinks } from 'volto-social-settings';

import FooterSmall from '@package/components/layout/Footer/FooterSmall';

import { getEditableFooterColumns, getItemsByPath } from 'volto-editablefooter';

const Footer = () => {
  const N_COLUMNS = 3;
  const location = useLocation();
  const dispatch = useDispatch();

  const footerConfiguration = useSelector(
    (state) => state.editableFooterColumns?.result,
  );

  useEffect(() => {
    dispatch(getEditableFooterColumns());
  }, [dispatch, location]);

  //filter rootpaths
  const footerColumns = getItemsByPath(
    footerConfiguration,
    location?.pathname?.length ? location.pathname : '/',
  );

  const firstColumn = footerColumns[0];
  const otherColumns = footerColumns.slice(1, footerColumns.length);

  const ncolumns =
    otherColumns.length < N_COLUMNS ? otherColumns.length : N_COLUMNS;

  return (
    <>
      <FooterSubsite />
      <div id="footer" className="footer" role="contentinfo">
        <Container>
          <div className="footer-main">
            <Grid stackable>
              {firstColumn && (
                <Grid.Column width={4} className="er-column">
                  <div className="footer-header-logo">
                    <img src={LogoRegione} alt="Regione Emilia-Romagna" />
                  </div>

                  {firstColumn?.showSocial && <SocialLinks />}
                  {/* {firstColumn?.newsletterSubscribe && <NewsletterSubscribe />} */}
                  {firstColumn?.text.data && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: flattenHTMLToAppURL(firstColumn.text.data),
                      }}
                    />
                  )}
                </Grid.Column>
              )}
              {otherColumns && (
                <Grid.Column width={8} className="gz-footer">
                  <div className="footer-header-logo">
                    <img src={LogoGiovazoom} alt="Giovazoom" />
                  </div>
                  <Grid stackable verticalAlign="top">
                    {otherColumns.map((column) => (
                      <Grid.Column width={12 / ncolumns} key={column.id}>
                        {column?.text?.data && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: flattenHTMLToAppURL(column.text.data),
                            }}
                          />
                        )}
                        {/* {column.newsletterSubscribe && <NewsletterSubscribe />} */}
                        {column.showSocial && <SocialLinks />}
                      </Grid.Column>
                    ))}
                  </Grid>
                </Grid.Column>
              )}
            </Grid>

            <FooterSmall />
          </div>
        </Container>
      </div>
    </>
  );
};

export default Footer;
