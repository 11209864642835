/**
 * EditBlock.
 * @module components/manage/Blocks/IconsBlock/Block/EditBlock
 */

import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { TextEditorWidget } from '@package/components';
import ViewImage from './ViewImage';
import { Grid } from 'semantic-ui-react';

const messages = defineMessages({
  titlePlaceholder: {
    id: 'Title placeholder',
    defaultMessage: 'Titolo...',
  },
});

const EditBlock = ({
  data,
  block,
  onChangeBlock,
  setFocusOn,
  index,
  onChange,
}) => {
  const intl = useIntl();

  if (__SERVER__) {
    return <div />;
  }

  return (
    <Grid.Column className={(data.block_style, 'single-card')}>
      <ViewImage icon={data.icon} uploadImage={data.iconUpload} size="120px" />

      <TextEditorWidget
        data={data}
        fieldName={'title'}
        block={block}
        setFocus={(f) => setFocusOn(f)}
        onChangeBlock={(v) => {
          onChange(index, 'title', v.title);
        }}
        placeholder={intl.formatMessage(messages.titlePlaceholder)}
      />
    </Grid.Column>
  );
};

export default React.memo(EditBlock);
