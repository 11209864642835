import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import {
  EditTitleBlock,
  EditDescriptionBlock,
  UniversalLink,
  Image,
} from '@plone/volto/components';
import imageBlockSVG from '@plone/volto/components/manage/Blocks/Image/block-image.svg';
import { useIntl, defineMessages } from 'react-intl';

const messages = defineMessages({
  artist_twitter: {
    id: 'artist_twitter',
    defaultMessage: "Click here to go to the artist's Twitter page",
  },
  artist_facebook: {
    id: 'artist_facebook',
    defaultMessage: "Click here to go to the artist's Facebook page",
  },
  artist_instagram: {
    id: 'artist_instagram',
    defaultMessage: "Click here to go to the artist's instagram page",
  },
  artist_youtube: {
    id: 'artist_youtube',
    defaultMessage: "Click here to go to the artist's youtube chanel",
  },
  artist_linkedin: {
    id: 'artist_linkedin',
    defaultMessage: "Click here to go to the artist's linkedin profile",
  },
});

const Body = (props) => {
  const { properties, isEditMode } = props;
  const intl = useIntl();

  const image = properties.image
    ? properties.image.data
      ? `data:${properties.image['content-type']};${properties.image['encoding']},${properties.image.data}`
      : flattenToAppURL(properties.image.scales.large.download)
    : null;

  const hasSocials =
    properties.twitter ||
    properties.facebook ||
    properties.instagram ||
    properties.youtube ||
    properties.linkedin;
  return (
    <Grid padded>
      <Grid.Row>
        {(image || isEditMode) && (
          <Grid.Column
            mobile={12}
            tablet={4}
            computer={4}
            largeScreen={4}
            widescreen={4}
            className="image-column"
          >
            <div className="image-wrapper">
              {image ? (
                <Image src={image} alt={properties.image_caption || ''} />
              ) : (
                <img src={imageBlockSVG} alt="" />
              )}
            </div>
          </Grid.Column>
        )}
        <Grid.Column
          mobile={12}
          tablet={isEditMode ? 8 : image ? 8 : 12}
          computer={isEditMode ? 8 : image ? 8 : 12}
          largeScreen={isEditMode ? 8 : image ? 8 : 12}
          widescreen={isEditMode ? 8 : image ? 8 : 12}
          className="artist-infos"
        >
          {isEditMode ? (
            <div className="block title">
              <EditTitleBlock {...props} />
            </div>
          ) : (
            <h1 className="documentFirstHeader">{properties.title}</h1>
          )}

          {properties.skills?.length > 0 && (
            <div className="skills">
              {properties.skills.map((s, i) => (
                <>
                  {s}
                  {i < properties.skills.length - 1 ? ', ' : ''}
                </>
              ))}
            </div>
          )}

          {(hasSocials || properties.website) && (
            <div className="contacts">
              {hasSocials && (
                <div className="socials">
                  {properties.twitter && (
                    <UniversalLink
                      title={intl.formatMessage(messages.artist_twitter)}
                      href={properties.twitter}
                    >
                      <FontAwesomeIcon icon={['fab', 'twitter']} />
                    </UniversalLink>
                  )}
                  {properties.facebook && (
                    <UniversalLink
                      title={intl.formatMessage(messages.artist_facebook)}
                      href={properties.facebook}
                    >
                      <FontAwesomeIcon icon={['fab', 'facebook']} />
                    </UniversalLink>
                  )}
                  {properties.instagram && (
                    <UniversalLink
                      title={intl.formatMessage(messages.artist_instagram)}
                      href={properties.instagram}
                    >
                      <FontAwesomeIcon icon={['fab', 'instagram']} />
                    </UniversalLink>
                  )}
                  {properties.youtube && (
                    <UniversalLink
                      title={intl.formatMessage(messages.artist_youtube)}
                      href={properties.youtube}
                    >
                      <FontAwesomeIcon icon={['fab', 'youtube']} />
                    </UniversalLink>
                  )}
                  {properties.linkedin && (
                    <UniversalLink
                      title={intl.formatMessage(messages.artist_linkedin)}
                      href={properties.linkedin}
                    >
                      <FontAwesomeIcon icon={['fab', 'linkedin']} />
                    </UniversalLink>
                  )}
                </div>
              )}

              {properties.website && (
                <div className="website">
                  <a
                    href={properties.website}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {properties.website}
                  </a>
                </div>
              )}
            </div>
          )}

          {isEditMode ? (
            <EditDescriptionBlock {...props} />
          ) : (
            <div className="documentDescription">{properties.description}</div>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default Body;
