/**
 * Root reducer.
 * @module reducers/root
 */

import { YCApiReducer } from './YCApiReducer';
import defaultReducers from '@plone/volto/reducers';

import config from '@plone/volto/registry';

/**
 * Root reducer.
 * @function
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
const reducers = {
  ...defaultReducers,
  ...config.addonReducers,
  YCApi: YCApiReducer,
  // Add your reducers here
};

export default reducers;
