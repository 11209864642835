/**
 * Add your config changes here.
 * @module config
 * @example
 * export default function applyConfig(config) {
 *   config.settings = {
 *     ...config.settings,
 *     port: 4300,
 *     listBlockTypes: {
 *       ...config.settings.listBlockTypes,
 *       'my-list-item',
 *    }
 * }
 */

import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import * as IconsRegular from '@fortawesome/free-regular-svg-icons';
import * as IconsBrand from '@fortawesome/free-brands-svg-icons';

import { Helmet } from '@plone/volto/helpers';

import SiteBlocks from '@package/config/Blocks/blocks.js';
import SiteViews from '@package/config/views.js';
import SiteWidgets from '@package/config/widgets.js';

import applyRichTextConfig from '@package/config/RichTextEditor/config';
import faSitemapSVG from '@package/icons/sitemap.svg';

import { SubsiteLoader } from 'volto-subsites';

import Matomo from '@package/components/Matomo';

// All your imports required for the config here BEFORE this line
import '@plone/volto/config';

//FontAwesome icons
const iconList = Object.keys(Icons.fas).map((icon) => Icons[icon]);
const iconListRegular = Object.keys(IconsRegular.far).map(
  (icon) => IconsRegular[icon],
);
const iconListBrand = Object.keys(IconsBrand.fab).map(
  (icon) => IconsBrand[icon],
);
library.add(...iconList, ...iconListRegular, ...iconListBrand);

export default function applyConfig(config) {
  config = applyRichTextConfig(config);
  // Add here your project's configuration here binby modifying `config` accordingly
  config.settings = {
    ...config.settings,
    isMultilingual: true,
    supportedLanguages: ['it', 'en'],
    defaultLanguage: 'it',
    contentIcons: {
      ...config.settings.contentIcons,
      Subsite: faSitemapSVG,
    },
    appExtras: [
      ...config.settings.appExtras,
      {
        match: '',
        component: Helmet,
        props: {
          titleTemplate: `%s — Giovazoom - Portale Giovani Emilia-Romagna`,
        },
      },
      {
        match: '*/search',
        component: Helmet,
        props: {
          title: `Cerca nel sito`,
        },
      },
      { match: '', component: SubsiteLoader, props: {} },
      { match: '', component: Matomo, props: {} },
    ],
    siteProperties: {
      enableVoltoFormBlockCaptcha: true,
    },
  };

  config.settings['volto-gdpr-privacy'].defaultPanelConfig = {
    ...config.settings['volto-gdpr-privacy'].defaultPanelConfig,
    last_updated: '2022-01-11T10:36:28+00:00',
    text: {
      it: {
        description:
          "Questo sito utilizza i cookie tecnici di navigazione e di sessione per garantire un miglior servizio di navigazione del sito, e cookie analitici per raccogliere informazioni sull'uso del sito da parte degli utenti.  Utilizza anche cookie di profilazione dell'utente per fini statistici. Per i cookie di profilazione puoi decidere se abilitarli o meno cliccando sul pulsante 'Impostazioni'. Per saperne di più, su come disabilitare i cookie oppure abilitarne solo alcuni, consulta la nostra <a href='/it/privacy' target='_blank'>Cookie Policy</a>.",
      },
      en: {
        description:
          "This site uses technical navigation and session cookies to ensure a better site navigation service, and analytical cookies to collect information on the use of the site by users. It also uses user profiling cookies for statistical and remarketing purposes. For profiling cookies you can decide whether to enable them or not by clicking on the 'Change settings' button. To find out more, on how to disable cookies or enable only some of them, consult our <a href='/en/privacy' target='_blank'>Cookie Policy</a>.",
      },
    },
  };

  SiteBlocks(config); //configurazione dei blocchi di Giovazoom
  SiteViews(config); //configurazione delle viste di Giovazoom
  SiteWidgets(config); //configurazione degli widgets di Giovazoom

  return config;
}
