import React from 'react';
import PropTypes from 'prop-types';
import Body from '@package/components/Blocks/EventsSearch/Body';

const View = ({ data, id, properties, block }) => {
  return (
    <div className="event-search">
      <Body data={data} properties={properties} block={block} />
    </div>
  );
};
/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  id: PropTypes.string.isRequired,
};

export default View;
