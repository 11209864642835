import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Container } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UniversalLink } from '@plone/volto/components';

import { ReadMore, ExternalSource } from '@package/components';

import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';

const LinkTemplate = ({
  items,
  linkTitle,
  linkHref,
  isEditMode,
  title,
  agendaLink,
}) => {
  const linkMore =
    linkHref?.length > 0
      ? { href: linkHref[0]['@id'], title: linkTitle }
      : null;

  return items.length > 0 ? (
    <div className="link-template">
      <div className="link-template-content full-width">
        <Container>
          {title && <h2 className="text-center">{title}</h2>}

          {items?.length > 0 && (
            <Grid stackable>
              {items.map((item) => {
                const fileUrl =
                  item['@type'] === 'File'
                    ? flattenToAppURL(item?.file?.download)
                    : null;
                return (
                  <Grid.Column
                    computer={4}
                    largeScreen={4}
                    widescreen={4}
                    tablet={12}
                    key={item['@id']}
                  >
                    <div className="link-item">
                      <div className="item-icon">
                        <div className="icon-wrapper">
                          <FontAwesomeIcon
                            icon={[
                              'fas',
                              item['@type'] == 'File'
                                ? 'cloud-download-alt'
                                : 'link',
                            ]}
                          />
                        </div>
                      </div>
                      <div className="item-content">
                        <div className="item-title">
                          <UniversalLink
                            item={!isEditMode && !fileUrl ? item : null}
                            href={isEditMode ? '#' : fileUrl}
                          >
                            {item.title ? item.title : item.id}
                          </UniversalLink>
                        </div>
                        {item.description && <p>{item.description}</p>}
                        <ExternalSource item={item} />
                      </div>
                    </div>
                  </Grid.Column>
                );
              })}
            </Grid>
          )}
          <ReadMore linkMore={linkMore} isEditMode={isEditMode} color="white" />
        </Container>
      </div>
    </div>
  ) : null;
};
LinkTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};
export default LinkTemplate;
