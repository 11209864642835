/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import redraft from 'redraft';
import { Grid } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import { Image } from '@plone/volto/components';
import { ReadMore } from '@package/components';
import cx from 'classnames';

const ViewBlock = ({ data, column }) => {
  const icon = column?.url;

  const linkMore =
    column?.href?.length > 0
      ? { href: column.href[0]['@id'], title: column.href_title }
      : null;

  return column?.title || column?.text || icon ? (
    <Grid.Column
      computer={data.column_number}
      tablet={data.column_number}
      mobile={12}
      className="column-block"
    >
      {column?.title && <h2 className="column-title title">{column.title}</h2>}
      {icon && (
        <div className="column-image">
          <Image
            className={cx({
              ['image-' + data.sizeImage]: data.sizeImage,
            })}
            src={flattenToAppURL(icon) + '/@@images/image/teaser'}
            alt=""
            role="presentation"
            aria-hidden="true"
          />
        </div>
      )}
      {column?.text && (
        <div className="column-text">
          {redraft(
            column.text,
            config.settings.richtextViewSettings.ToHTMLRenderers,
            config.settings.richtextViewSettings.ToHTMLOptions,
          )}
        </div>
      )}

      {linkMore && (
        <ReadMore
          linkMore={linkMore}
          color={data.bg_color !== 'bg-secondary' ? 'black' : 'grey'}
        />
      )}
    </Grid.Column>
  ) : (
    <></>
  );
};

export default React.memo(ViewBlock);
