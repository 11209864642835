import React from 'react';
import PropTypes from 'prop-types';
import Body from './Body';
/**
 * View block class.
 * @class View
 * @extends Component
 */
const View = (props) => (
  <div className="block youngercard-numbers">
    <Body {...props} />
  </div>
);

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  properties: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
