import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReadMore } from '@package/components';
import { Component, Icon } from '@plone/volto/components';
import { Container, Grid, Modal } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import iconLeft from '@plone/volto/icons/circle-left.svg';
import iconRight from '@plone/volto/icons/circle-right.svg';
import iconClose from '@plone/volto/icons/circle-dismiss.svg';

const GridGalleryListing = (props) => {
  const { items } = props;
  const [previewImage, setPreviewImage] = useState(null);
  const [itemSelected, setItemSelected] = useState(null);
  const [itemIndex, setItemIndex] = useState(null);
  const [focusClose, setFocusClose] = useState(false);
  const closeButtonRef = useRef(null);

  const linkMore =
    props.linkHref?.length > 0
      ? { href: props.linkHref[0]['@id'], title: props.linkTitle }
      : null;

  useEffect(() => {
    if (closeButtonRef?.current !== null && focusClose) {
      closeButtonRef.current.focus();
      setFocusClose(false);
    }
  }, [closeButtonRef, focusClose]);

  const carouselModal = (direction) => {
    items.forEach((item, index) => {
      if (direction === 'right') {
        if (itemIndex <= items.length && index === itemIndex + 1) {
          setItemSelected(item);
          setPreviewImage(item.getURL ?? item.url);
          setItemIndex(index);
        }
      } else if (direction === 'left') {
        if (itemIndex >= 0 && index === itemIndex - 1) {
          setItemSelected(item);
          setPreviewImage(item.getURL ?? item.url);
          setItemIndex(index);
        }
      }
    });
  };

  return (
    <Container className="grid-gallery-block">
      <Grid stackable className="grid-gallery-wrapper">
        <Grid.Row columns={3}>
          {items.map((item, index) => {
            return (
              <Grid.Column
                computer={4}
                tablet={6}
                mobile={12}
                key={index}
                className="image-wrapper"
              >
                <Component
                  componentName="PreviewImage"
                  item={item}
                  alt={item.description ? item.description : item.title}
                  onClick={() => {
                    setPreviewImage(item.getURL ?? item.url);
                    setItemSelected(item);
                    setItemIndex(index);
                  }}
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                      //Enter
                      setPreviewImage(item.getURL ?? item.url);
                      setItemSelected(item);
                      setFocusClose(true);
                    }
                  }}
                  role="button"
                  tabIndex={0}
                />
              </Grid.Column>
            );
          })}
        </Grid.Row>
      </Grid>
      <Modal
        className="modal-gallery"
        open={previewImage != null}
        onClose={() => {
          setPreviewImage(null);
        }}
        onKeyUp={(e) => {
          if (e.keyCode === 37) {
            //arrow-left
            carouselModal('left');
          }
          if (e.keyCode === 39) {
            //arrow-right
            carouselModal('right');
          }
        }}
        closeIcon={
          <button className="close" closeonscape="true" ref={closeButtonRef}>
            <Icon
              name={iconClose}
              className="icon"
              closeonscape="true"
              type="button"
              tabIndex="0"
            />
          </button>
        }
      >
        <Modal.Content image>
          <Icon
            name={iconLeft}
            className="modal-icon left"
            alt="Immagine anteriore"
            type="button"
            tabIndex="0"
            onClick={() => carouselModal('left')}
          />
          <Icon
            name={iconRight}
            className="modal-icon right"
            alt="Prossima immagine"
            type="button"
            tabIndex="0"
            onClick={() => carouselModal('right')}
          />
          <img
            className="modal-image"
            size="medium"
            src={
              flattenToAppURL(itemSelected?.getURL ?? itemSelected?.url) +
              '/@@images/image/huge'
            }
            alt="Immagine"
          />
        </Modal.Content>
      </Modal>
      <ReadMore linkMore={linkMore} isEditMode={props.isEditMode} />
    </Container>
  );
};

GridGalleryListing.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  isEditMode: PropTypes.bool,
  linkTitle: PropTypes.string,
  linkHref: PropTypes.any,
};
export default GridGalleryListing;
