/**
 * Logo component.
 * @module components/theme/Logo/Logo
 */

import React from 'react';

import { Image } from '@plone/volto/components';
import { useSelector } from 'react-redux';
import config from '@plone/volto/registry';
import { flattenToAppURL } from '@plone/volto/helpers';
import { UniversalLink } from '@plone/volto/components';
import LogoGZ from '@package/components/layout/images/logo.png';
import LogoMobile from '@package/components/layout/images/logo-black.svg';
import LogoWhite from '@package/components/layout/images/logo-white.svg';

/**
 * Logo component class.
 * @function Logo
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component.
 */
const Logo = ({ mobile = false, menu = false }) => {
  const { settings } = config;
  const lang = useSelector((state) => state.intl.locale);
  const subsite = useSelector((state) => state.subsite?.data);

  let LogoImage = mobile ? (menu ? LogoWhite : LogoMobile) : LogoGZ;
  let link = settings.isMultilingual ? `/${lang}` : '/';
  let title = 'Giovazoom';

  if (subsite?.['@id']?.length > 0) {
    if (subsite.logo) {
      LogoImage = flattenToAppURL(subsite.logo?.download);
    }
    link = flattenToAppURL(subsite?.['@id']);
    title = subsite.title;
  }

  return (
    <div className="logo">
      <UniversalLink href={link} title={title}>
        <Image src={LogoImage} alt={title} title={title} />
      </UniversalLink>
    </div>
  );
};

export default Logo;
