import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { defineMessages, useIntl } from 'react-intl';
import { AlignWidget, ColorListWidget } from '@package/components';
import {
  SelectWidget,
  ObjectBrowserWidget,
  TextWidget,
} from '@plone/volto/components';

const messages = defineMessages({
  allineamento_intestazione: {
    id: 'Allineamento Intestazione',
    defaultMessage: 'Allineamento Intestazione',
  },
  spaziatura: {
    id: 'Altezza elemento',
    defaultMessage: 'Spaziatura',
  },
  title_size: {
    id: 'Dimensione del titolo',
    defaultMessage: 'Dimensione del titolo',
  },
  link: {
    id: 'LinkAltro',
    defaultMessage: 'Link ad altro',
  },
  titolo_link: {
    id: 'TitoloLink',
    defaultMessage: 'Titolo per il link ad altro',
  },
});

const Sidebar = (props) => {
  const { data, onChangeBlock, block } = props;

  useEffect(() => {
    if (!data.bg_color) {
      onChangeBlock(block, {
        ...data,
        bg_color: 'bg-primary',
      });
    }
    if (!data.alignText) {
      onChangeBlock(block, {
        ...data,
        alignText: 'center',
      });
    }
    if (!data.padding_blockTitle) {
      onChangeBlock(block, {
        ...data,
        padding_blockTitle: 'medium',
      });
    }
  }, [data]);

  const intl = useIntl();

  return (
    <Segment.Group raised>
      <header className="header pulled main-title">
        <h2>
          <FormattedMessage
            id="text-background"
            defaultMessage="Testo con sfondo"
          />
          :
        </h2>
      </header>
      <Segment className="form">
        {
          //Select BG color full-width
        }
        <ColorListWidget
          id="bg_color"
          title="Colore di sfondo"
          colors={[
            { name: 'bg-primary', label: 'Colore primario' },
            { name: 'bg-secondary', label: 'Colore secondario' },
            { name: 'bg-tertiary', label: 'Colore terziario' },
          ]}
          value={data.bg_color ?? 'bg-primary'}
          onChange={(name, value) =>
            onChangeBlock(block, { ...data, [name]: value })
          }
        />
        <SelectWidget
          id="title_size"
          title={intl.formatMessage(messages.title_size)}
          choices={[
            ['h1', 'H1'],
            ['h2', 'H2'],
          ]}
          value={data.title_size ?? 'h2'}
          noValueOption={false}
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              [name]: value,
            });
          }}
        />
        <AlignWidget
          value={data.alignText ?? 'center'}
          id="alignText"
          onChange={(name, value) =>
            onChangeBlock(block, { ...data, [name]: value })
          }
          alignments={['left', 'center', 'right']}
          title={intl.formatMessage(messages.allineamento_intestazione)}
        />
        <SelectWidget
          id="padding_blockTitle"
          title={intl.formatMessage(messages.spaziatura)}
          choices={[
            ['small', 'Piccola'],
            ['medium', 'Media'],
            ['large', 'Grande'],
          ]}
          value={data.padding_blockTitle ?? 'medium'}
          noValueOption={false}
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              [name]: value,
            });
          }}
        />
        <header className="header pulled">
          <h2>
            <FormattedMessage id="titleBottone" defaultMessage="Bottone" />:
          </h2>
        </header>
        <ObjectBrowserWidget
          id="href"
          title={intl.formatMessage(messages.link)}
          mode="link"
          allowExternals={true}
          value={data.href}
          widgetOptions={{
            pattern_options: { maximumSelectionSize: 1 },
          }}
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              href: value,
            });
          }}
        />
        <TextWidget
          id="href_title"
          title={intl.formatMessage(messages.titolo_link)}
          value={data.href_title ?? ''}
          onChange={(name, value) => {
            onChangeBlock(block, { ...data, [name]: value });
          }}
        />
      </Segment>
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default injectIntl(Sidebar);
