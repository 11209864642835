/**
 * Link View.
 * @module components/theme/View/ExternalNewsView
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import { isInternalURL, flattenToAppURL, URLUtils } from '@plone/volto/helpers';
import { Link } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { Image } from '@plone/volto/components';

const messages = defineMessages({
  externalSource: {
    id: 'ExternalNews_externalSource',
    defaultMessage: 'Fonte',
  },
});
/**
 * View container class.
 * @class View
 * @extends Component
 */
class ExternalNewsView extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    content: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      externalSource: PropTypes.string,
      externalUrl: PropTypes.string,
    }),
    token: PropTypes.string,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    content: null,
    token: null,
  };

  componentDidMount() {
    if (!this.props.token) {
      const { externalUrl } = this.props.content;
      if (isInternalURL(externalUrl)) {
        this.props.history.replace(flattenToAppURL(externalUrl));
      } else if (!__SERVER__) {
        window.location.href = flattenToAppURL(externalUrl);
      }
    }
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { externalUrl } = this.props.content;

    return (
      <Container id="page-document">
        {this.props.content.image && (
          <Image
            className="document-image"
            src={this.props.content.image.scales.preview.download}
            floated="right"
            loading="lazy"
          />
        )}
        <h1 className="documentFirstHeading">{this.props.content.title}</h1>
        {this.props.content.description && (
          <p className="documentDescription">
            {this.props.content.description}
          </p>
        )}

        {this.props.content.externalSource && (
          <p>
            {this.props.intl.formatMessage(messages.externalSource)}:{' '}
            {this.props.content.externalSource}
          </p>
        )}
        {externalUrl && (
          <span>
            The link address is:{' '}
            {isInternalURL(externalUrl) ? (
              <Link to={flattenToAppURL(externalUrl)}>
                {flattenToAppURL(externalUrl)}
              </Link>
            ) : (
              <>
                {URLUtils.isMail('mailto:' + externalUrl) ? (
                  <a
                    href={URLUtils.normaliseMail(externalUrl)}
                    rel="noopener noreferrer"
                  >
                    {externalUrl}
                  </a>
                ) : (
                  <>
                    {URLUtils.isTelephone(externalUrl) ? (
                      <a
                        href={URLUtils.normalizeTelephone(externalUrl)}
                        rel="noopener noreferrer"
                      >
                        {externalUrl}
                      </a>
                    ) : (
                      <a
                        href={externalUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {externalUrl}
                      </a>
                    )}
                  </>
                )}
              </>
            )}
          </span>
        )}
      </Container>
    );
  }
}

export default injectIntl(ExternalNewsView);
