import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { TextWidget, ObjectListWidget } from '@plone/volto/components';
import cx from 'classnames';

import { ImageWidget } from '@package/components';
import IconList from '@package/components/layout/icons';

const messages = defineMessages({
  BackgroundImage: {
    id: 'BackgroundImage',
    defaultMessage: 'Immagine di sfondo',
  },
});

const Sidebar = (props) => {
  const { data, block, intl, onChangeBlock, onChange } = props;
  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage id="IconsBlocks" defaultMessage="Blocco di icone" />
          :
        </h2>
      </header>
      <Segment className="form">
        <TextWidget
          id="main_title"
          title="Titolo del blocco"
          value={data.main_title ?? ''}
          onChange={(name, value) => {
            onChangeBlock(block, { ...data, [name]: value });
          }}
        />
        <ImageWidget
          title={intl.formatMessage(messages.BackgroundImage)}
          id="backgroundImage"
          value={data.url}
          onChange={(id, value) => {
            onChangeBlock(block, { ...data, url: value });
          }}
          openObjectBrowser={props.openObjectBrowser}
        />
      </Segment>
      <Segment className={cx('form', data?.iconUpload ? 'icon-uploaded' : '')}>
        <ObjectListWidget
          {...props}
          id="columns"
          value={data?.columns ?? []}
          onChange={(id, value) =>
            props.onChangeBlock(block, { ...data, [id]: value })
          }
          schema={() => {
            const columnsSchema = {
              title: 'Colonna',
              addMessage: 'Aggiungi una colonna',
              fieldsets: [
                {
                  id: 'default',
                  title: 'Default',
                  fields: ['iconUpload', 'icon', 'href'],
                },
              ],
              properties: {
                iconUpload: {
                  title: "Carica un'icona",
                  description:
                    "L'immagine deve essere un PNG, quadrata, di almeno 128x128px. Se l'immagine viene caricata, prevarrà sull'icona selezionata.",
                  widget: 'image_upload_widget',
                  openObjectBrowser: props.openObjectBrowser,
                },
                icon: {
                  title: "Scegli un'icona",
                  widget: 'icons_selector_widget',
                  icons: IconList,
                },
                href: {
                  title: 'Link a',
                  widget: 'object_browser',
                  allowExternals: true,
                  mode: 'link',
                  description:
                    "Per i link esterni inserire l'indirizzo completo. Esempio: 'www.google.com'",
                },
              },

              required: [],
            };

            return columnsSchema;
          }}
        />
      </Segment>
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  openObjectBrowser: PropTypes.func.isRequired,
};

export default injectIntl(Sidebar);
