import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, defineMessages } from 'react-intl';
import { Grid } from 'semantic-ui-react';
import moment from 'moment';
import { ReadMore } from '@package/components';
import { Subjects } from '@package/components/View';
import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';
import { Image } from '@plone/volto/components';

const messages = defineMessages({
  noResults: {
    id: 'rss_no_results',
    defaultMessage: 'No results from RSS feed.',
  },
});

const DefaultRSSTemplate = ({ items = [], data = {}, isEditMode }) => {
  const intl = useIntl();

  return items?.length > 0 ? (
    <div className="default-template listing">
      {data.title && <h2 className="text-center">{data.title}</h2>}
      <Grid stackable columns={3}>
        {items.map((item, index) => (
          <Grid.Column key={index}>
            <div className="listing-item">
              <a
                href={item.url ?? '#'}
                target="_blank"
                rel="noopener noreferrer"
              >
                <figure>
                  {item.enclosure?.url ? (
                    <Image
                      src={item.enclosure.url}
                      alt={item.title}
                      loading="lazy"
                    />
                  ) : (
                    <img
                      src={DefaultImageSVG}
                      alt={item.title}
                      loading="lazy"
                    />
                  )}
                </figure>
              </a>

              {item.source && <div className="source">{item.source}</div>}

              {item?.categories?.length > 0 && (
                <Subjects
                  subjects={[item.categories[0]._]}
                  key={item.categories[0]._}
                />
              )}

              <div className="dates">
                {(item.pubDate || item.date) && (
                  <>
                    {moment(item.pubDate || item.date)
                      .locale(intl.locale)
                      .format('LL')}
                  </>
                )}
              </div>

              <div className="item-title">
                <a
                  href={item.url ?? '#'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.title}
                </a>
              </div>
              {item.contentSnippet && <p>{item.contentSnippet}</p>}
            </div>
          </Grid.Column>
        ))}
      </Grid>

      {data.linkMore && data.linkMoreTitle && (
        <ReadMore
          linkMore={{ href: data.linkMore, title: data.linkMoreTitle }}
          isEditMode={isEditMode}
        />
      )}
    </div>
  ) : data.feed && isEditMode ? (
    <div className="no-rss-feed-results">
      {intl.formatMessage(messages.noResults)}
    </div>
  ) : null;
};

DefaultRSSTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
};

export default DefaultRSSTemplate;
