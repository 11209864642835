import React from 'react';
import { Container } from 'semantic-ui-react';
import { UniversalLink } from '@plone/volto/components';
import { ParentSiteMenu } from '@package/components/layout';
import LogoGZ from '@package/components/layout/images/logo-white.svg';

const HeaderSmallSubsite = ({ pathname }) => {
  return (
    <div className="header-small">
      <Container>
        <div className="header-small-content">
          <div className="left-zone">
            <div className="parent-logo">
              <UniversalLink href="/">
                <img src={LogoGZ} alt="Logo GiovaZoom" />
              </UniversalLink>
            </div>
          </div>
          <div className="right-zone">
            <ParentSiteMenu pathname={pathname} />

            {/* <LanguageSelector /> */}
            {/* {!token && (
              <div className="tools">
                <Anontools />
              </div>
            )} */}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HeaderSmallSubsite;
