import React from 'react';
import { Map } from 'immutable';

import VoltoDraftButtons from '@plone/volto/config/RichTextEditor/Styles';

import Plugins from '@plone/volto/config/RichTextEditor/Plugins';
import Blocks from '@plone/volto/config/RichTextEditor/Blocks';

import ToHTMLRenderers from '@plone/volto/config/RichTextEditor/ToHTML';

import FromHTML from '@plone/volto/config/RichTextEditor/FromHTML';
import createLinkPlugin from '@plone/volto/components/manage/AnchorPlugin';
import UnderlineButton from '@package/config/RichTextEditor/ToolbarButtons/UnderlineButton';
import HeadingsButton from '@package/config/RichTextEditor/ToolbarButtons/HeadingsButton';
import AlignButton from '@package/config/RichTextEditor/ToolbarButtons/AlignButton';
import CalloutsButton from '@package/config/RichTextEditor/ToolbarButtons/CalloutsButton';
import ButtonsButton from '@package/config/RichTextEditor/ToolbarButtons/ButtonsButton';
import UnorderedListSeparatedButton from '@package/config/RichTextEditor/ToolbarButtons/UnorderedListSeparatedButton';

const siteBlockRenderMap = Map({
  'unordered-list-separated-item': {
    element: 'li',
    wrapper: React.createElement('ul', {
      className: 'unordered-list-separated',
    }),
  },
  'align-center': {
    element: 'p',
  },
  'align-right': {
    element: 'p',
  },
  'align-justify': {
    element: 'p',
  },
  'callout-bg': {
    element: 'p',
  },
  'header-two-center': {
    element: 'h2',
  },
  buttons: {
    element: 'p',
  },
});

const renderHTMLBlock = (child) => {
  return child.map((subchild) => {
    if (Array.isArray(subchild)) {
      return subchild.map((subchildren) => {
        if (typeof subchildren === 'string') {
          const last = subchildren.split('\n').length - 1;
          return subchildren.split('\n').map((item, index) => (
            <React.Fragment key={index}>
              {item}
              {index !== last && <br />}
            </React.Fragment>
          ));
        } else {
          return subchildren;
        }
      });
    } else {
      return subchild;
    }
  });
};
const siteBlocksHtmlRenderers = {
  blockquote: (children, { keys }) =>
    children.map((child, i) => (
      <blockquote key={keys[i]}>{renderHTMLBlock(child)}</blockquote>
    )),
  'align-center': (children, { keys }) =>
    children.map((child, i) => (
      <p id={keys[i]} key={keys[i]} className="text-center">
        {renderHTMLBlock(child)}
      </p>
    )),
  'align-right': (children, { keys }) =>
    children.map((child, i) => (
      <p id={keys[i]} key={keys[i]} className="text-right">
        {renderHTMLBlock(child)}
      </p>
    )),
  'align-justify': (children, { keys }) =>
    children.map((child, i) => (
      <p id={keys[i]} key={keys[i]} className="text-justify">
        {renderHTMLBlock(child)}
      </p>
    )),
  'callout-bg': (children, { keys }) =>
    children.map((child, i) => (
      <p id={keys[i]} key={keys[i]} className="callout-bg" role="note">
        {renderHTMLBlock(child)}
      </p>
    )),
  'header-two-center': (children, { keys }) =>
    children.map((child, i) => (
      <h2 id={keys[i]} key={keys[i]} className="text-center">
        {renderHTMLBlock(child)}
      </h2>
    )),
  buttons: (children, { keys }) =>
    children.map((child, i) => (
      <p id={keys[i]} key={keys[i]} className="draftjs-buttons">
        {renderHTMLBlock(child)}
      </p>
    )),
  'unordered-list-separated-item': (children, conf) => (
    <div className="unordered-list-separeted">
      {ToHTMLRenderers.blocks['unordered-list-item'](children, conf)}
    </div>
  ),
};

const siteInlineHtmlRenderers = {};

const siteFromHTMLCustomBlockFn = (element) => {
  let ret = FromHTML(element); //get default from plone/volto

  if (!ret) {
    if (element.className === 'callout-bg') {
      ret = {
        type: 'callout-bg',
      };
    } else if (element.className === 'draftjs-buttons') {
      ret = {
        type: 'buttons',
      };
    }
    if (element.className === 'unordered-list-separated-item') {
      ret = {
        type: 'unordered-list-separated-item',
      };
    }
  }
  return ret;
};

export default function applyConfig(config) {
  const siteBlockStyleFn = (baseBlockStyleFn) => {
    return (contentBlock) => {
      const type = contentBlock.getType();

      let r = '';

      if (type) {
        r = baseBlockStyleFn(contentBlock) || '';
        if (type === 'callout') {
          r = 'callout';
        }

        r = r.length > 0 ? ' ' : r;

        const styles = {
          'align-center': 'text-center',
          'align-right': 'text-right',
          'align-justify': 'text-justify',
          callout: 'callout',
          'callout-bg': 'callout-bg',
          'header-two-center': 'text-center',
          buttons: 'draftjs-buttons',
          'unordered-list-separated-item': 'unordered-list-separated-item',
        };

        r += styles[type] ?? '';
      }

      return r;
    };
  };

  config.settings.richtextEditorSettings = (props) => {
    const { plugins /*, inlineToolbarButtons*/ } = Plugins(props);
    const { extendedBlockRenderMap, blockStyleFn, listBlockTypes } = Blocks(
      props,
    );

    const { Separator } = props.draftJsInlineToolbarPlugin;
    let linkPlugin = createLinkPlugin({
      libraries: props,
    });

    const {
      BoldButton,
      ItalicButton,
      UnorderedListButton,
      OrderedListButton,
      BlockquoteButton,
    } = VoltoDraftButtons(props);

    const siteRichTextEditorInlineToolbarButtons = [
      AlignButton(props),
      Separator,
      BoldButton,
      ItalicButton,
      UnderlineButton(props),
      Separator,
      HeadingsButton(props),
      linkPlugin.LinkButton,
      ButtonsButton(props),
      Separator,
      UnorderedListButton,
      UnorderedListSeparatedButton(props),
      OrderedListButton,
      BlockquoteButton,
      CalloutsButton(props),
    ];

    return {
      extendedBlockRenderMap: extendedBlockRenderMap
        .update('text-center', (element = 'p') => element)
        .merge(siteBlockRenderMap),
      blockStyleFn: siteBlockStyleFn(blockStyleFn),
      listBlockTypes: [...listBlockTypes, 'unordered-list-separated-item'],
      richTextEditorPlugins: plugins,
      richTextEditorInlineToolbarButtons: siteRichTextEditorInlineToolbarButtons,
      FromHTMLCustomBlockFn: siteFromHTMLCustomBlockFn,
      customStyleMap: {
        TEXT_LARGER: { fontSize: '1.335rem', lineHeight: 1.5 },
        TEXT_MEDIUM: { fontSize: '1.15rem', lineHeight: 1.5 },
        TEXT_LIGHT: { fontWeight: 300 },
      },
    };
  };

  // TODO: rimuovere questa customizzazione quando sistemano https://github.com/plone/volto/issues/1601
  config.settings.richtextViewSettings.ToHTMLRenderers = {
    ...config.settings.richtextViewSettings.ToHTMLRenderers,
    blocks: {
      ...ToHTMLRenderers.blocks,
      ...siteBlocksHtmlRenderers,
    },
    inline: { ...ToHTMLRenderers.inline, ...siteInlineHtmlRenderers },
  };

  return config;
}
