export const addSchemaField = (
  schema,
  field,
  title,
  description,
  properties = {},
  position = 0,
) => {
  const defaultFieldsetIndex = schema.fieldsets.findIndex(
    (x) => x.id === 'default',
  );

  schema.fieldsets[defaultFieldsetIndex] = {
    ...schema.fieldsets[defaultFieldsetIndex],
  };
  schema.fieldsets[defaultFieldsetIndex].fields.splice(position, 0, field);
  schema.properties[field] = { title, description, ...properties };
};
