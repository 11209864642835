import React from 'react';
import PropTypes from 'prop-types';
import Body from '@package/components/Blocks/ArtistsSearch/Body';

const Edit = ({ data, block, onChangeBlock, selected, pathname }) => {
  return (
    <div className="artists-search">
      <Body data={data} isEditMode={true} onChangeBlock={onChangeBlock} />
    </div>
  );
};
/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Edit.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  id: PropTypes.string.isRequired,
  block: PropTypes.string.isRequired,
  selected: PropTypes.any,
  intl: PropTypes.any,
  onChangeBlock: PropTypes.func.isRequired,
};

export default Edit;
