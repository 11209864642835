/**
 * ViewBlock.
 * @module components/manage/Blocks/Text/View
 */

import React from 'react';
import PropTypes from 'prop-types';

import { ConditionalEmbed } from 'volto-gdpr-privacy';

import Body from '@plone/volto/components/manage/Blocks/Video/Body';
/**
 * ViewBlock class.
 * @class ViewBlock
 * @extends Component
 */
const ViewBlock = ({ data, index, isEditMode }) => {
  return data?.url ? (
    <div className="video-wrapper">
      {/* <ConditionalEmbed url={!isEditMode ? data.url : null}> */}
      <Body data={data} isEditMode={isEditMode} />
      {/* </ConditionalEmbed> */}
    </div>
  ) : null;
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ViewBlock.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ViewBlock;
