import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { compose } from 'redux';
import { Segment, Form } from 'semantic-ui-react';
import withObjectBrowser from '@plone/volto/components/manage/Sidebar/ObjectBrowser';
import { TextWidget } from '@plone/volto/components';
import clearSVG from '@plone/volto/icons/clear.svg';

const messages = defineMessages({
  LinkTitle: {
    id: 'Link more title',
    defaultMessage: 'Titolo link',
  },
  LinkTo: {
    id: 'Link more to',
    defaultMessage: 'Link',
  },
  LinkToDescription: {
    id: 'Link more to description',
    defaultMessage: 'Inserisci un url per il link ad altro',
  },
});

const Sidebar = ({ data, onChangeBlock, block, intl, openObjectBrowser }) => {
  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage id="Newsletter" defaultMessage="Newsletter" />
        </h2>
      </header>

      <Segment className="form sidebar-button">
        <Form>
          <TextWidget
            id="linkTitle"
            title={intl.formatMessage(messages.LinkTitle)}
            required={false}
            value={data.linkMore?.title}
            onChange={(name, value) => {
              onChangeBlock(block, {
                ...data,
                linkMore: {
                  ...data.linkMore,
                  title: value,
                },
              });
            }}
          />

          <TextWidget
            id="linkHref"
            title={intl.formatMessage(messages.LinkTo)}
            description={intl.formatMessage(messages.LinkToDescription)}
            required={false}
            value={data.linkMore?.href}
            icon={data.linkMore?.href ? clearSVG : null}
            iconAction={
              data.linkMore?.href
                ? () => {
                    onChangeBlock(block, {
                      ...data,
                      linkMore: {
                        ...data.linkMore,
                        href: '',
                      },
                    });
                  }
                : null
            }
            onChange={(key, value) =>
              onChangeBlock(block, {
                ...data,
                linkMore: {
                  ...data.linkMore,
                  href: value,
                },
              })
            }
          />
        </Form>
      </Segment>
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  block: PropTypes.string.isRequired,
};

export default compose(withObjectBrowser, injectIntl)(Sidebar);
