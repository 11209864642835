/**
 * Edit html block.
 * @module components/manage/Blocks/HTML/Edit
 */

import { compose } from 'redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { SidebarPortal } from '@plone/volto/components';

import { withBlockExtensions } from '@plone/volto/helpers';
import Sidebar from '@package/components/Blocks/HTML/Sidebar';

/**
 * Edit html block class.
 * @class Edit
 * @extends Component
 */
class Edit extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    selected: PropTypes.bool.isRequired,
    block: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    onChangeBlock: PropTypes.func.isRequired,
    onSelectBlock: PropTypes.func.isRequired,
    onDeleteBlock: PropTypes.func.isRequired,
    handleKeyDown: PropTypes.func.isRequired,
    editable: PropTypes.bool,
  };

  /**
   * Default properties
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    editable: true,
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const variation = this.props.variation;
    const Renderer = variation.edit;
    const cb = (block, data) => {
      this.props.onChangeBlock(block, data);
    };
    return (
      <>
        <Renderer {...this.props} onChangeBlock={cb} />
        <SidebarPortal selected={this.props.selected}>
          <Sidebar key={this.props.block} {...this.props} />
        </SidebarPortal>
      </>
    );
  }
}

export default compose(withBlockExtensions)(Edit);
