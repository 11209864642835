/**
 * Edit links block.
 * @module components/manage/Blocks/LinksBlock/Edit
 */

import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { useIntl, defineMessages } from 'react-intl';
import { Input, Grid } from 'semantic-ui-react';
import { SidebarPortal } from '@plone/volto/components';
import EditBlock from './Block/EditBlock';
import Sidebar from './Sidebar';

const messages = defineMessages({
  mainTitlePlaceholder: {
    id: 'Title placeholder',
    defaultMessage: 'Titolo del blocco...',
  },
});

const Edit = (props) => {
  const { data, selected, block, onChangeBlock } = props;
  const [focusOn, setFocusOn] = useState('main_title');
  const intl = useIntl();

  useEffect(() => {
    if (!data?.columns || data?.columns?.length === 0) {
      onChangeBlock(block, {
        ...data,
        columns: [{ '@id': uuid(), bg_color_card: 'sectionGreen' }],
      });
    }
  }, [block]);

  const focusField = (field) => {
    setFocusOn(field);

    if (!selected) {
      props.onSelectBlock(block);
    }
  };

  if (__SERVER__) {
    return <div />;
  }
  return (
    <>
      <Grid>
        <div className="title-block">
          <Input
            fluid
            transparent
            placeholder={intl.formatMessage(messages.mainTitlePlaceholder)}
            value={data.main_title || ''}
            name="main_title"
            onChange={(e) =>
              onChangeBlock(block, {
                ...data,
                main_title: e.target.value,
              })
            }
          />
        </div>
        {data?.columns?.length > 0 && (
          <Grid.Row columns={data.columns.length > 3 ? 4 : data.columns.length}>
            {data?.columns?.map((column, i) => (
              <EditBlock
                data={column}
                index={i}
                focusOn={focusOn}
                setFocusOn={focusField}
                onChange={(index, field, value) => {
                  let newColumns = [...data.columns];
                  newColumns[index][field] = value;
                  onChangeBlock(block, {
                    ...data,
                    columns: [...newColumns],
                  });
                }}
                selected={selected}
                nColumns={data.columns.length}
                key={i}
              />
            ))}
          </Grid.Row>
        )}
      </Grid>

      <SidebarPortal selected={selected}>
        <Sidebar {...props} />
      </SidebarPortal>
    </>
  );
};

export default Edit;
