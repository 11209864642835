/**
 * ViewBlock.
 * @module components/manage/Blocks/IconsBlock/Block/ViewBlock
 */

import React from 'react';
import PropTypes from 'prop-types';
import redraft from 'redraft';
import config from '@plone/volto/registry';
import { UniversalLink } from '@plone/volto/components';
import { defineMessages, useIntl } from 'react-intl';
import ViewImage from './ViewImage';
import { Grid } from 'semantic-ui-react';
import cx from 'classnames';
import { checkRedraftHasContent } from '@package/helpers';

const messages = defineMessages({
  blockIconLinkTitle: {
    id: 'blockIconLinkTitle',
    defaultMessage: 'Vai al detaglio',
  },
});

const ViewBlock = ({ data }) => {
  const intl = useIntl();
  const linkToggle = () => {
    const content = (
      <Grid.Column className={(data.block_style, 'single-card')}>
        <ViewImage
          icon={data.icon}
          uploadImage={data.iconUpload}
          size="120px"
        />
        {checkRedraftHasContent(data.title) && (
          <div className="title">
            {redraft(
              data.title,
              config.settings.richtextViewSettings.ToHTMLRenderers,
              config.settings.richtextViewSettings.ToHTMLOptions,
            )}
          </div>
        )}
      </Grid.Column>
    );

    const link = (
      <UniversalLink
        title={intl.formatMessage(messages.blockIconLinkTitle)}
        item={data.href && data.href.length !== 0 ? data.href[0] : ''}
        openLinkInNewTab={data.openLinkInNewTab}
      >
        {content}
      </UniversalLink>
    );

    if (!data.href || data.href.length === 0) {
      return content;
    } else if (data.href && data.href.length !== 0) {
      return link;
    }
  };

  return (
    <>
      {(data.title || data.icon || data.iconUpload) && (
        <div className={cx('single-column', data.block_style)}>
          {linkToggle()}
        </div>
      )}
    </>
  );
};

ViewBlock.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ViewBlock;
