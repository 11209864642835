/**
 * HeaderSmallMobile component.
 * @module components/theme/HeaderSmallMobile/HeaderSmallMobile
 */

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Icon } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import {
  SearchWidget,
  //LanguageSelector
  //Anontools
} from '@plone/volto/components';

const messages = defineMessages({
  search: {
    id: 'Search site',
    defaultMessage: 'Cerca nel sito',
  },
});

/**
 * HeaderSmallMobile component class.
 * @function HeaderSmallMobile
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component.
 */
const HeaderSmallMobile = ({ pathname }) => {
  const intl = useIntl();
  const subsite = useSelector((state) => state.subsite?.data);
  //const token = useSelector((state) => state.userSession.token);
  const [showSearch, setShowSearch] = useState(false);

  return (
    <div className="header-small">
      <Container>
        <div className="header-small-content">
          <div className="right-zone">
            <button
              className={`toggle-search ${showSearch ? 'active' : ''}`}
              onClick={(e) => setShowSearch(!showSearch)}
              aria-label={intl.formatMessage(messages.search)}
            >
              <Icon name={showSearch ? 'x' : 'search'} />
            </button>

            {/* <LanguageSelector direction="left" /> */}
            {/* {!token && (
              <div className="tools">
                <Anontools />
              </div>
            )} */}
          </div>
        </div>
      </Container>
      {showSearch && (
        <div className="search-wrapper">
          <Container>
            <SearchWidget pathname={subsite?.['@id'] ? pathname : ''} />
          </Container>
        </div>
      )}
    </div>
  );
};

export default HeaderSmallMobile;
