import React from 'react';
import PropTypes from 'prop-types';

const ExternalSource = ({ item }) => {
  return item.externalSource ? (
    <div className="external-source">{item.externalSource}</div>
  ) : null;
};
ExternalSource.propTypes = {
  item: PropTypes.any,
};
export default ExternalSource;
