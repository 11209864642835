import React from 'react';
import PropTypes from 'prop-types';
import Body from './Body';

const View = (props) => (
  <div className="block artistHeader">
    <Body {...props} />
  </div>
);

View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  properties: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
