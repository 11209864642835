/**
 * View image block.
 * @module components/manage/Blocks/Hero/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Button } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import { UniversalLink } from '@plone/volto/components';

const StoresButtons = ({ data }) => (
  <div className="stores-buttons">
    {data.appStoreLink && (
      <Button as={UniversalLink} href={data.appStoreLink}>
        <span>APP STORE</span>
        <Icon name="apple" size="large" />
      </Button>
    )}

    {data.playStoreLink && (
      <Button as={UniversalLink} href={data.playStoreLink}>
        <span>PLAY STORE</span>
        <Icon name="google play" size="large" />
      </Button>
    )}
  </div>
);

StoresButtons.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default StoresButtons;
