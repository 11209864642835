import SquaresListingBlockTemplate from '@package/components/Blocks/Listing/SquaresTemplate';
import CardCircleImageListingBlockTemplate from '@package/components/Blocks/Listing/CardCircleImageTemplate';
import SliderListingBlockTemplate from '@package/components/Blocks/Listing/SliderTemplate';
import NewsListingBlockTemplate from '@package/components/Blocks/Listing/NewsTemplate';
import LinkListingBlockTemplate from '@package/components/Blocks/Listing/LinkTemplate';
import addDefaultOptions from '@package/config/Blocks/ListingOptions/defaultOptions';
import addSliderTemplateOptions from '@package/config/Blocks/ListingOptions/sliderTemplateOptions';
import addNewsTemplateOptions from '@package/config/Blocks/ListingOptions/newsTemplateOptions';
import addSquaresTemplateOptions from '@package/config/Blocks/ListingOptions/squaresTemplateOptions';
import GridGalleryListingTemplate from '@package/components/Blocks/Listing/GridGalleryListing';
import TimelineListing from '@package/components/Blocks/Listing/TimelineListing';

export const listingVariations = [
  {
    id: 'squares',
    isDefault: false,
    title: 'Quadratoni',
    template: SquaresListingBlockTemplate,
    schemaEnhancer: ({ schema, formData, intl }) => {
      let pos = addDefaultOptions(schema, formData, intl);
      addSquaresTemplateOptions(schema, formData, intl, pos);
      return schema;
    },
  },
  {
    id: 'cardCircleImage',
    isDefault: false,
    title: 'Card con immagine rotonda',
    template: CardCircleImageListingBlockTemplate,
    schemaEnhancer: ({ schema, formData, intl }) => {
      /*let pos = */ addDefaultOptions(schema, formData, intl);
      return schema;
    },
  },
  {
    id: 'gridGallery',
    isDefault: false,
    title: 'Galleria a griglia',
    template: GridGalleryListingTemplate,
    schemaEnhancer: ({ schema, formData, intl }) => {
      /*let pos = */ addDefaultOptions(schema, formData, intl);
      return schema;
    },
  },
  {
    id: 'timelineListing',
    isDefault: false,
    title: 'Timeline',
    template: TimelineListing,
    schemaEnhancer: ({ schema, formData, intl }) => {
      /*let pos = */ addDefaultOptions(schema, formData, intl);
      return schema;
    },
  },
  {
    id: 'slider',
    isDefault: false,
    title: 'Slider',
    template: SliderListingBlockTemplate,
    schemaEnhancer: ({ schema, formData, intl }) => {
      let pos = addDefaultOptions(schema, formData, intl);
      addSliderTemplateOptions(schema, formData, intl, pos);
      return schema;
    },
  },
  {
    id: 'news',
    isDefault: false,
    title: 'News',
    template: NewsListingBlockTemplate,
    schemaEnhancer: ({ schema, formData, intl }) => {
      let pos = addDefaultOptions(schema, formData, intl);
      addNewsTemplateOptions(schema, formData, intl, pos);
      return schema;
    },
  },
  {
    id: 'links',
    isDefault: false,
    title: 'Collegamenti',
    template: LinkListingBlockTemplate,
    schemaEnhancer: ({ schema, formData, intl }) => {
      /*let pos = */ addDefaultOptions(schema, formData, intl);
      return schema;
    },
  },
];

export const removeListingVariation = (config, id) => {
  let indexOfVariation = -1;
  indexOfVariation = config.blocks?.blocksConfig?.listing?.variations?.findIndex(
    (x) => x.id === id,
  );
  if (indexOfVariation >= 0) {
    config.blocks.blocksConfig.listing.variations.splice(indexOfVariation, 1);
  }
};
