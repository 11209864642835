import { defineMessages } from 'react-intl';
import { addSchemaField } from '@package/config/Blocks/ListingOptions/utils';

const messages = defineMessages({
  agenda_link: {
    id: 'agenda_link',
    defaultMessage: "Link all'agenda",
  },
});

/** DEFAULT **/
const addNewsTemplateOptions = (schema, formData, intl, position = 0) => {
  let pos = position;

  addSchemaField(
    schema,
    'agendaLink',
    intl.formatMessage(messages.agenda_link),
    null,
    {
      widget: 'object_browser',
      mode: 'link',
      selectedItemAttrs: ['Title', 'Description'],
      allowExternals: true,
    },
    pos,
  );
  pos++;

  return pos;
};

export default addNewsTemplateOptions;
