/**
 * YoungER Card Api action
 */
export const GET_YC_API = 'GET_YC_API';

export function getYCApi(subrequestSuffix, apiName, params) {
  return {
    type: GET_YC_API,
    subrequest: subrequestSuffix + apiName,
    request: {
      op: 'get',
      path: `/@yc-api/${apiName}`,
      params,
    },
  };
}
