import React from 'react';
import { Container, Grid } from 'semantic-ui-react';
import ViewColumnBlock from './Block/ViewBlock';
import cx from 'classnames';

const View = ({ data }) => {
  return (
    <section
      className={cx('text-with-icons-block full-width', {
        [data.bg_color]: data.bg_color,
      })}
    >
      <Container>
        <Grid className="columns-wrapper">
          {data?.columns?.map((column, i) => (
            <ViewColumnBlock data={data} column={column} key={i} />
          ))}
        </Grid>
      </Container>
    </section>
  );
};

export default View;
