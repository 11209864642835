import React from 'react';
import PropTypes from 'prop-types';

import { UniversalLink } from '@plone/volto/components';
import { EventWhen } from '@package/components/Blocks/Listing/Common/EventWhen';

import { PreviewImage } from '@package/components';

const EventItem = ({ item, isEditMode, infoInTitle = false }) => {
  return (
    <div className="listing-item event-item">
      <UniversalLink
        item={!isEditMode ? item : null}
        href={isEditMode ? '#' : null}
      >
        <PreviewImage item={item} />
      </UniversalLink>

      <div className="item-title">
        <UniversalLink
          item={!isEditMode ? item : null}
          href={isEditMode ? '#' : null}
        >
          <div className="item-info">
            <EventWhen
              start={item.start}
              end={item.end}
              whole_day={item.whole_day}
              open_end={item.open_end}
              show_times={false}
            />
          </div>
          <h3>{item.title ? item.title : item.id}</h3>
        </UniversalLink>
      </div>
      {item.description && <p>{item.description}</p>}
    </div>
  );
};

EventItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default EventItem;
