/**
 * ViewImage.
 * @module components/Blocks/IconsBlock/Block/ViewImage
 */

import React from 'react';
import PropTypes from 'prop-types';
import ListIcons from '@package/components/layout/icons';
import { Icon, Image } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { defineMessages, useIntl } from 'react-intl';

/**
 * ViewImage class.
 * @class ViewImage
 * @extends Component
 */

const messages = defineMessages({
  altIcon: {
    id: 'altIcon',
    defaultMessage: 'Icona',
  },
});

const ViewImage = ({ icon, size, uploadImage }) => {
  const intl = useIntl();

  const imageBuilder = () => {
    if (uploadImage) {
      return (
        <div className="block-image">
          <Image
            src={`${flattenToAppURL(uploadImage)}/@@images/image`}
            alt={intl.formatMessage(messages.altIcon)}
          />
        </div>
      );
    } else if (icon) {
      return (
        <div className="block-icon">
          <Icon name={ListIcons[icon]} size={size} />
        </div>
      );
    } else {
      return <></>;
    }
  };

  return imageBuilder();
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ViewImage.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
};

export default ViewImage;
