import React from 'react';
import redraft from 'redraft';
import config from '@plone/volto/registry';
import cx from 'classnames';
import { Container } from 'semantic-ui-react';
import { ReadMore } from '@package/components';

const View = ({ data }) => {
  const linkMore =
    data?.href?.length > 0
      ? { href: data.href[0]['@id'], title: data.href_title }
      : null;

  return (
    <div
      className={cx('text-background-block full-width', {
        [data.bg_color]: data.bg_color,
      })}
    >
      <Container>
        <section
          className={cx('text-background-wrapper', {
            ['textAlign-' + data.alignText]: data.alignText,
            ['padding-' + data.padding_blockTitle]: data.padding_blockTitle,
          })}
        >
          {data.title &&
            (data.title_size === 'h1' ? (
              <h1 className="title">{data.title}</h1>
            ) : (
              <h2 className="title">{data.title}</h2>
            ))}
          {data.text && (
            <div className="text">
              {redraft(
                data.text,
                config.settings.richtextViewSettings.ToHTMLRenderers,
                config.settings.richtextViewSettings.ToHTMLOptions,
              )}
            </div>
          )}

          {linkMore && (
            <div className="link-more">
              <ReadMore
                linkMore={linkMore}
                isEditMode={true}
                color={data.bg_color !== 'bg-secondary' ? 'black' : 'grey'}
              />
            </div>
          )}
        </section>
      </Container>
    </div>
  );
};

export default View;
