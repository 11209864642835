import { rrulestr } from 'rrule';
import { rrulei18n } from '@plone/volto/components/manage/Widgets/RecurrenceWidget/Utils';

export const datesForDisplay = (start, end, recurrence, intl, moment) => {
  const mStart = moment(start);
  const mEnd = end ? moment(end) : null;
  let eventRecurrenceText = null;
  let rruleSet = null;

  if (!mStart.isValid() || (end != null && !mEnd.isValid())) {
    return null;
  }
  const sameDay = mEnd ? mStart.isSame(mEnd, 'day') : false;
  const sameTime = mEnd ? mStart.isSame(mEnd, 'minute') : false;

  if (recurrence) {
    rruleSet = rrulestr(recurrence, {
      compatible: true, //If set to True, the parser will operate in RFC-compatible mode. Right now it means that unfold will be turned on, and if a DTSTART is found, it will be considered the first recurrence instance, as documented in the RFC.
      forceset: true,
    });
    const RRULE_LANGUAGE = rrulei18n(intl, moment);

    let rule = rruleSet.rrules()[0];
    if (rule) {
      rule.options.until = null;
    }
    eventRecurrenceText = rule?.toText(
      (t) => {
        return RRULE_LANGUAGE.strings[t];
      },
      RRULE_LANGUAGE,
      RRULE_LANGUAGE.dateFormatter,
    );
  }
  return {
    sameDay,
    sameTime,
    startDate: mStart.format('D.MM.YYYY'),
    startTime: mStart.format('LT'),
    endDate: mEnd ? mEnd.format('D.MM.YYYY') : null,
    endTime: mEnd ? mEnd.format('LT') : null,
    mStart: mStart,
    mEnd: mEnd,
    eventRecurrenceText: eventRecurrenceText,
    rruleSet: rruleSet,
  };
};
