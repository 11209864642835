/**
 * View html block.
 * @module components/manage/Blocks/HTML/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import redraft from 'redraft';
import { useIntl, defineMessages } from 'react-intl';
import { Container } from 'semantic-ui-react';
import { ReadMore } from '@package/components';
import { withBlockExtensions } from '@plone/volto/helpers';
import config from '@plone/volto/registry';

const messages = defineMessages({
  titlePlaceholder: {
    id: 'Title placeholder',
    defaultMessage: 'Titolo...',
  },
  textPlaceholder: {
    id: 'Text placeholder',
    defaultMessage: 'Testo...',
  },
  vedi: {
    id: 'Vedi',
    defaultMessage: 'Vedi',
  },
});
/**
 * View html block class.
 * @class View
 * @extends Component
 */
const View = (props) => {
  const intl = useIntl();
  const { data } = props;

  return (
    <div className="block html">
      <div className="title-description-variation full-width">
        <Container>
          <div className="text-wrapper">
            <div className="title">
              {redraft(
                data.title,
                config.settings.richtextViewSettings.ToHTMLRenderers,
                config.settings.richtextViewSettings.ToHTMLOptions,
              )}
            </div>

            <div className="description-wrapper">
              {redraft(
                data.text,
                config.settings.richtextViewSettings.ToHTMLRenderers,
                config.settings.richtextViewSettings.ToHTMLOptions,
              )}
            </div>
            {data.linkMoreHref?.length > 0 && (
              <ReadMore
                linkMore={{
                  title:
                    data.linkMoreTitle || intl.formatMessage(messages.vedi),
                  href: data.linkMoreHref[0]['@id'],
                }}
                color="white"
              />
            )}
          </div>
          <div className="html-wrapper">
            <div dangerouslySetInnerHTML={{ __html: data.html }} />
          </div>
        </Container>
      </div>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withBlockExtensions(View);
