import React, { useState } from 'react';
import { TextEditorWidget, ReadMore } from '@package/components';
import { useIntl, defineMessages } from 'react-intl';
import cx from 'classnames';
import Sidebar from './Sidebar';
import { SidebarPortal } from '@plone/volto/components';
import { Input, Container } from 'semantic-ui-react';

const messages = defineMessages({
  titlePlaceholder: {
    id: 'Title placeholder',
    defaultMessage: 'Titolo...',
  },
  textPlaceholder: {
    id: 'Text placeholder',
    defaultMessage: 'Inserisci il testo...',
  },
});

const Edit = (props) => {
  const intl = useIntl();
  const { data, block, onChangeBlock, selected } = props;
  const [focusOn, setFocusOn] = useState('title');

  const focusField = (field) => {
    setFocusOn(field);

    if (!selected) {
      props.onSelectBlock(block);
    }
  };

  const linkMore =
    data?.href?.length > 0
      ? { href: data.href[0]['@id'], title: data.href_title }
      : null;

  return (
    <div
      className={cx('text-background-block full-width', {
        [data.bg_color]: data.bg_color,
      })}
    >
      <Container>
        <div
          className={cx('text-background-wrapper', {
            ['textAlign-' + data.alignText]: data.alignText,
            ['padding-' + data.padding_blockTitle]: data.padding_blockTitle,
          })}
        >
          <div className="title">
            <Input
              fluid
              className={cx({
                'input-main-title': data.title_size === 'h1',
                'input-title': data.title_size !== 'h1',
              })}
              transparent
              placeholder={intl.formatMessage(messages.titlePlaceholder)}
              value={data.title || ''}
              onClick={(e) => {
                focusField('title');
                e.stopPropagation();
              }}
              onChange={(v) =>
                onChangeBlock(block, {
                  ...data,
                  title: v.target.value,
                })
              }
            />
          </div>
          <TextEditorWidget
            data={data}
            fieldName="text"
            onChangeBlock={(v) => {
              onChangeBlock(block, {
                ...data,
                text: v.text,
              });
            }}
            placeholder={intl.formatMessage(messages.textPlaceholder)}
            showToolbar={true}
            setFocus={(f) => focusField(f)}
            selected={focusOn === 'text'}
          />
          {linkMore && (
            <div className="link-more">
              <ReadMore
                linkMore={linkMore}
                isEditMode={true}
                color={data.bg_color !== 'bg-secondary' ? 'black' : 'grey'}
              />
            </div>
          )}
        </div>

        <SidebarPortal selected={selected}>
          <Sidebar {...props} />
        </SidebarPortal>
      </Container>
    </div>
  );
};

export default Edit;
