/**
 * View image block.
 * @module components/manage/Blocks/Image/View
 */

/* ------ CUSTOMIZATIONS ------ */
/* - fixed full-width image class
   - added 'leadimage-wrap' classes in container div
 */

import React from 'react';
import PropTypes from 'prop-types';
import { UniversalLink, Image } from '@plone/volto/components';
import cx from 'classnames';

import { flattenToAppURL } from '@plone/volto/helpers';

/**
 * View image block class.
 * @class View
 * @extends Component
 */
const View = ({ data, properties }) => (
  <div className="leadimage-wrap">
    <div
      className={cx('block image align', {
        center: !Boolean(data.align),
        [data.align]: data.align !== 'full',
        'full-width': data.align === 'full',
      })}
    >
      {properties.image && (
        <>
          {(() => {
            const image = (
              <Image
                src={flattenToAppURL(properties.image.download)}
                alt={properties.image_caption || ''}
              />
            );
            if (data.href) {
              return (
                <UniversalLink
                  href={data.href}
                  openLinkInNewTab={data.openLinkInNewTab}
                >
                  {image}
                </UniversalLink>
              );
            } else {
              return image;
            }
          })()}
        </>
      )}
    </div>
  </div>
);

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  properties: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
