import React from 'react';

const Subjects = ({ subjects }) => {
  return subjects?.length > 0 ? (
    <div className="subjects">
      {subjects.map((s) => (
        <span className="subject" key={s}>
          {s}
        </span>
      ))}
    </div>
  ) : null;
};

export default Subjects;
