import ReactPiwik from 'react-piwik';
import { useHistory } from 'react-router-dom';

const Matomo = () => {
  let history = useHistory();
  if (__CLIENT__) {
    const piwikURL =
      process.env.RAZZLE_PIWIK_URL || window.env.RAZZLE_PIWIK_URL;
    const siteId =
      process.env.RAZZLE_PIWIK_SITE_ID || window.env.RAZZLE_PIWIK_SITE_ID;
    const jsFilename =
      process.env.RAZZLE_PIWIK_JSFILE || window.env.RAZZLE_PIWIK_JSFILE;
    const phpFilename =
      process.env.RAZZLE_PIWIK_PHPFILE || window.env.RAZZLE_PIWIK_PHPFILE;
    const trackAtConnect = true;

    const excludedLocations = [
      'applicazioni.regione.emilia-romagna.it',
      'applicazionitest.regione.emilia-romagna.it',
      'cm.regione.emilia-romagna.it',
      'test-www.regione.emilia-romagna.it',
      'internostest.regione.emilia-romagna.it',
      'localhost',
      '//10.',
    ];

    if (piwikURL) {
      const enabled =
        excludedLocations.filter((f) => window.location.href.indexOf(f) >= 0)
          .length === 0;

      if (enabled) {
        const piwik = new ReactPiwik({
          url: piwikURL,
          siteId: siteId,
          jsFilename: jsFilename,
          phpFilename: phpFilename,
        });
        ReactPiwik.push([
          'setDocumentTitle',
          document.domain + '/' + document.title,
        ]);
        ReactPiwik.push(['setUserId', 'userId']);

        // track the initial pageview
        ReactPiwik.push(['trackPageView']);
        ReactPiwik.push(['enableLinkTracking']);

        piwik.connectToHistory(history, trackAtConnect);
      }
    }
  }

  return null;
};

export default Matomo;
