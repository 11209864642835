/**
 * Edit html block.
 * @module components/manage/Blocks/HTML/Edit
 */

import { compose } from 'redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import { Container } from 'semantic-ui-react';

import EditHTML from '../EditHTML';
import { TextEditorWidget, ReadMore } from '@package/components';

const messages = defineMessages({
  titlePlaceholder: {
    id: 'Title placeholder',
    defaultMessage: 'Titolo...',
  },
  textPlaceholder: {
    id: 'Text placeholder',
    defaultMessage: 'Testo...',
  },
  vedi: {
    id: 'Vedi',
    defaultMessage: 'Vedi',
  },
});

/**
 * Edit html block class.
 * @class Edit
 * @extends Component
 */
class Edit extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    selected: PropTypes.bool.isRequired,
    block: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    onChangeBlock: PropTypes.func.isRequired,
    onSelectBlock: PropTypes.func.isRequired,
    onDeleteBlock: PropTypes.func.isRequired,
    handleKeyDown: PropTypes.func.isRequired,
    editable: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      focusOn: 'title',
    };
  }
  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <div className="title-description-variation full-width">
        <Container>
          <div className="text-wrapper">
            <div
              onClick={() => {
                this.setState({ focusOn: 'title' });
              }}
            >
              <TextEditorWidget
                data={this.props.data}
                fieldName="title"
                selected={this.props.selected && this.state.focusOn === 'title'}
                block={this.props.block}
                onChangeBlock={(value) => {
                  this.props.onChangeBlock(this.props.block, value);
                }}
                placeholder={this.props.intl.formatMessage(
                  messages.titlePlaceholder,
                )}
                nextFocus="text"
                setFocus={(f) => {
                  this.setState({ focusOn: f });
                }}
                showToolbar={false}
                key="title"
              />
            </div>

            <div className="description-wrapper">
              <TextEditorWidget
                data={this.props.data}
                fieldName="text"
                selected={this.props.selected && this.state.focusOn === 'text'}
                block={this.props.block}
                onChangeBlock={(value) =>
                  this.props.onChangeBlock(this.props.block, value)
                }
                placeholder={this.props.intl.formatMessage(
                  messages.textPlaceholder,
                )}
                prevFocus="title"
                setFocus={(f) => {
                  this.setState({ focusOn: f });
                }}
                key="text"
              />
            </div>

            {this.props.data.linkMoreHref?.length > 0 && (
              <ReadMore
                linkMore={{
                  title:
                    this.props.data.linkMoreTitle ||
                    this.props.intl.formatMessage(messages.vedi),
                  href: this.props.data.linkMoreHref[0]['@id'],
                }}
                color="white"
              />
            )}
          </div>
          <div className="html-wrapper">
            <EditHTML {...this.props} />
          </div>
        </Container>
      </div>
    );
  }
}

export default compose(injectIntl)(Edit);
