/**
 * View html block.
 * @module components/manage/Blocks/HTML/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withBlockExtensions } from '@plone/volto/helpers';

/**
 * View html block class.
 * @class View
 * @extends Component
 */
const View = (props) => {
  const { data } = props;

  return (
    <div
      className="block html"
      dangerouslySetInnerHTML={{ __html: data.html }}
    />
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withBlockExtensions(View);
