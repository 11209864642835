import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ViewBlock from './Block/ViewBlock';

/**
 * View Accordion block class.
 * @class View
 * @extends Component
 */
const AccordionView = ({ data, block }) => {
  const [itemOpen, setItemOpen] = useState(-1);
  const toggle = (index) => {
    setItemOpen(index === itemOpen ? -1 : index);
  };
  const id = new Date().getTime();
  return (
    <div className="block accordion">
      <div className="accordion-wrapper">
        {data.subblocks.map((subblock, index) => (
          <ViewBlock
            data={subblock}
            toggle={() => {
              return () => toggle(index);
            }}
            isOpen={itemOpen === index}
            key={index}
            id={id}
            index={index}
          />
        ))}
      </div>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
AccordionView.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default AccordionView;
