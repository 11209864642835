import { defineMessages } from 'react-intl';
import { addSchemaField } from '@package/config/Blocks/ListingOptions/utils';

const messages = defineMessages({
  full_width: {
    id: 'Full width',
    defaultMessage: 'Full width',
  },
  show_item_title: {
    id: 'sliderTemplate_showItemTitle',
    defaultMessage: 'Show item title',
  },
  show_item_description: {
    id: 'sliderTemplate_showItemDescription',
    defaultMessage: 'Show item description',
  },
});

/** DEFAULT **/
const addSliderTemplateOptions = (schema, formData, intl, position = 0) => {
  let pos = position;

  addSchemaField(
    schema,
    'full_width',
    intl.formatMessage(messages.full_width),
    null,
    { type: 'boolean', default: false },
    pos,
  );
  pos++;

  addSchemaField(
    schema,
    'show_item_title',
    intl.formatMessage(messages.show_item_title),
    null,
    { type: 'boolean', default: false },
    pos,
  );
  pos++;

  addSchemaField(
    schema,
    'show_item_description',
    intl.formatMessage(messages.show_item_description),
    null,
    { type: 'boolean', default: false },
    pos,
  );
  pos++;

  return pos;
};

export default addSliderTemplateOptions;
