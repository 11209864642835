import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';

import { UniversalLink, Icon } from '@plone/volto/components';

import agendaSVG from '@package/icons/agenda.svg';
import config from '@plone/volto/registry';
import { ReadMore, ExternalSource, PreviewImage } from '@package/components';
import { Subjects } from '@package/components/View';
import ListingDates from '@package/components/Blocks/Listing/Common/ListingDates';

const messages = defineMessages({
  agenda: {
    id: 'newstemplate_agenda',
    defaultMessage: 'Agenda',
  },
  agenda_link_title: {
    id: 'newstemplate_agenda_link_title',
    defaultMessage: 'Vedi gli eventi',
  },
});

const NewsTemplate = ({
  items,
  linkTitle,
  linkHref,
  isEditMode,
  title,
  agendaLink,
}) => {
  const intl = useIntl();

  const { settings } = config;

  const firstItem = items?.length > 0 ? items?.[0] : null;
  const otherItems = items.length > 1 ? items?.slice(1, items.length) : [];

  const linkMore =
    linkHref?.length > 0
      ? { href: linkHref[0]['@id'], title: linkTitle }
      : null;
  return items.length > 0 ? (
    <div className="news-template">
      {title && <h2 className="text-center">{title}</h2>}

      <div className="listing-item first-item">
        <Grid stackable>
          <Grid.Column computer={5} largeScreen={4} tablet={12}>
            <ListingDates item={firstItem} />

            <div className="item-title">
              <UniversalLink
                item={!isEditMode ? firstItem : null}
                href={isEditMode ? '#' : null}
              >
                {firstItem.title ? firstItem.title : firstItem.id}
              </UniversalLink>
            </div>
            {firstItem.description && <p>{firstItem.description}</p>}
            <ExternalSource item={firstItem} />
            <Subjects subjects={firstItem.subjects} />
          </Grid.Column>
          <Grid.Column
            computer={7}
            largeScreen={8}
            tablet={12}
            className="image-column"
          >
            <UniversalLink
              item={!isEditMode ? firstItem : null}
              href={isEditMode ? '#' : null}
              tabIndex="-1"
            >
              <PreviewImage item={firstItem} />
            </UniversalLink>
          </Grid.Column>
        </Grid>
      </div>

      {otherItems?.length > 0 && (
        <Grid stackable className="other-items">
          {otherItems.map((item) => (
            <Grid.Column
              computer={4}
              largeScreen={3}
              widescreen={3}
              tablet={12}
              key={item['@id']}
            >
              <div className="listing-item">
                <UniversalLink
                  item={!isEditMode ? item : null}
                  href={isEditMode ? '#' : null}
                  tabIndex="-1"
                >
                  <PreviewImage item={item} />
                </UniversalLink>
                <Subjects subjects={item.subjects} />

                <div className="item-title">
                  <ListingDates item={item} />
                  <UniversalLink
                    item={!isEditMode ? item : null}
                    href={isEditMode ? '#' : null}
                  >
                    {item.title ? item.title : item.id}
                  </UniversalLink>
                </div>
                {item.description && <p>{item.description}</p>}
                <ExternalSource item={item} />
              </div>
            </Grid.Column>
          ))}

          {agendaLink?.length > 0 && (
            <Grid.Column
              computer={4}
              largeScreen={3}
              widescreen={3}
              tablet={12}
              className="agenda-column"
            >
              <div className="agenda-column-content">
                <div className="item-title">
                  {intl.formatMessage(messages.agenda)}
                </div>
                <div className="img-wrapper">
                  <Icon name={agendaSVG} size="115px" />
                </div>
                <Button
                  as={UniversalLink}
                  item={isEditMode ? { '@id': '#' } : agendaLink[0]}
                  color="white"
                >
                  {intl.formatMessage(messages.agenda_link_title)}
                </Button>
              </div>
            </Grid.Column>
          )}
        </Grid>
      )}
      <ReadMore linkMore={linkMore} isEditMode={isEditMode} />
    </div>
  ) : null;
};
NewsTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};
export default NewsTemplate;
