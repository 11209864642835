import React from 'react';
import { compose } from 'redux';
import { injectIntl, defineMessages } from 'react-intl';
import cx from 'classnames';
import { injectDNDSubblocks, SubblockEdit, Subblock } from 'volto-subblocks';

import { Button } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextEditorWidget } from '@package/components';
import { ReadMore } from '@package/components';

const messages = defineMessages({
  titlePlaceholder: {
    id: 'Title placeholder',
    defaultMessage: 'Titolo...',
  },
  textPlaceholder: {
    id: 'Text placeholder',
    defaultMessage: 'Testo...',
  },
  vedi: {
    id: 'Vedi',
    defaultMessage: 'Vedi',
  },
});
/**
 * Edit Accordion block class.
 * @class Edit
 * @extends Component
 */
class EditBlock extends SubblockEdit {
  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    this.state = {
      focusOn: 'title',
    };
    this.accordion_item_ref = React.createRef();
  }

  componentDidMount() {
    // eslint-disable-next-line no-unused-expressions
    this.accordion_item_ref?.current?.addEventListener(
      'keydown',
      (e) => {
        if (e.keyCode === 13) {
          if (!(this.state.focusOn === 'text')) {
            this.setState({ focusOn: 'text' });
          }
        }
      },
      { passive: true },
    );
  }
  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    if (__SERVER__) {
      return <div />;
    }

    return (
      <Subblock subblock={this} className="subblock-edit">
        <div ref={this.accordion_item_ref} key={this.props.data.index}>
          <h3 className={cx('accordion-header', { open: this.props.selected })}>
            <Button
              onClick={(e) => {
                if (this.props.selected) {
                  e.stopPropagation();
                  e.preventDefault();
                  this.props.onSubblockChangeFocus(-1);
                }
              }}
            >
              <FontAwesomeIcon
                icon={['fas', this.props.selected ? 'minus' : 'plus']}
              />
            </Button>
            {/* eslint-disable */}

            <div
              onClick={() => {
                this.setState({ focusOn: 'title' });
              }}
              className="title-editor-wrapper"
            >
              <TextEditorWidget
                data={this.props.data}
                fieldName="title"
                selected={this.props.selected && this.state.focusOn === 'title'}
                block={this.props.block}
                onChangeBlock={this.onChange}
                placeholder={this.props.intl.formatMessage(
                  messages.titlePlaceholder,
                )}
                nextFocus="text"
                setFocus={(f) => {
                  this.setState({ focusOn: f });
                }}
                showToolbar={false}
                key="title"
              />
            </div>
          </h3>
          {this.props.selected && (
            <div
              className="accordion-content open"
              onClick={() => {
                this.setState({ focusOn: 'text' });
              }}
            >
              <div className="accordion-inner">
                <TextEditorWidget
                  data={this.props.data}
                  fieldName="text"
                  selected={
                    this.props.selected && this.state.focusOn === 'text'
                  }
                  block={this.props.block}
                  onChangeBlock={this.onChange}
                  placeholder={this.props.intl.formatMessage(
                    messages.textPlaceholder,
                  )}
                  prevFocus="title"
                  setFocus={(f) => {
                    this.setState({ focusOn: f });
                  }}
                  key="text"
                />
              </div>

              {this.props.data.href && (
                <ReadMore
                  linkMore={{
                    title:
                      this.props.data.linkMoreTitle ||
                      this.props.intl.formatMessage(messages.vedi),
                    href: this.props.data.href,
                  }}
                />
              )}
            </div>
          )}
        </div>
        {/* eslint-enable */}
      </Subblock>
    );
  }
}

export default compose(injectIntl, injectDNDSubblocks)(EditBlock);
