/**
 * Edit icons block.
 * @module components/manage/Blocks/Title/Edit
 */

import React from 'react';
import { defineMessages } from 'react-intl';
import { SidebarPortal } from '@plone/volto/components';
import { Container } from 'semantic-ui-react';
import {
  withDNDContext,
  SubblocksEdit,
  SubblocksWrapper,
} from 'volto-subblocks';
import Sidebar from './Sidebar';
import EditBlock from './Block/EditBlock';
import Body from './Body';

const messages = defineMessages({
  addItem: {
    id: 'Add accordion item',
    defaultMessage: 'Aggiungi un video',
  },
  titlePlaceholder: { id: 'Title', defaultMessage: 'Titolo' },
  noVideos: {
    id: 'noVideos',
    defaultMessage:
      'Nessun video selezionato. Aggiungi un elemento per mostrare un video',
  },
});
/**
 * Edit icons block class.
 * @class Edit
 * @extends Component
 */
class Edit extends SubblocksEdit {
  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */

  render() {
    if (__SERVER__) {
      return <div />;
    }

    return (
      <SubblocksWrapper node={this.node}>
        <div className="full-width">
          <Container>
            <Body
              {...this.props}
              items={this.state.subblocks}
              className="editmode"
              isEditMode={true}
              subIndexSelected={this.state.subIndexSelected}
            >
              {this.state.subblocks.map((subblock, subindex) => (
                <div className="single-slide-wrapper" key={subblock.id}>
                  <EditBlock
                    data={subblock}
                    index={subindex}
                    selected={this.isSubblockSelected(subindex)}
                    {...this.subblockProps}
                    openObjectBrowser={this.props.openObjectBrowser}
                  />
                </div>
              ))}
            </Body>
            {this.state.subblocks.length === 0 && (
              <div>{this.props.intl.formatMessage(messages.noVideos)}</div>
            )}
            {this.props.selected && (
              <div className="add-button">
                {this.renderAddBlockButton(
                  this.props.intl.formatMessage(messages.addItem),
                )}
              </div>
            )}
          </Container>
        </div>
        <SidebarPortal selected={this.props.selected}>
          <Sidebar
            {...this.props}
            data={this.props.data}
            block={this.props.block}
            onChangeBlock={this.props.onChangeBlock}
            onChangeSubBlock={this.onChangeSubblocks}
            selected={this.state.subIndexSelected}
            setSelected={this.onSubblockChangeFocus}
            openObjectBrowser={this.props.openObjectBrowser}
          />
        </SidebarPortal>
      </SubblocksWrapper>
    );
  }
}

export default React.memo(withDNDContext(Edit));
