import React from 'react';
import PropTypes from 'prop-types';
import Body from './Body';
import { SidebarPortal } from '@plone/volto/components';
import Sidebar from './Sidebar';

/**
 * Edit block class.
 * @class Edit
 * @extends Component
 */
const Edit = (props) => {
  return (
    <div className="block youngercard-map">
      <Body {...props} isEditMode={true} />

      <SidebarPortal selected={props.selected}>
        <Sidebar {...props} />
      </SidebarPortal>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Edit.propTypes = {
  properties: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Edit;
