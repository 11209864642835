import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getYCApi } from '@package/actions';

import CheckboxListWidget from 'volto-form-block/components/Widget/CheckboxListWidget';

export const isValid = (formData, name) => {
  let valid = true;

  if ((formData[name]?.value ?? []).length === 0) return false;
  return valid;
};

const NewsletterChannelsWidget = (props) => {
  const { invalid } = props;
  const dispatch = useDispatch();

  const valid =
    invalid === null ||
    invalid === undefined ||
    invalid === 'false' ||
    invalid === false;

  const channels = useSelector(
    (state) =>
      state.YCApi.subrequests?.[
        'widget' + 'newsletters'
      ]?.result?.newsletters?.map((p) => {
        return { value: p?.newsletter?.id, label: p?.newsletter?.descrizione };
      }) ?? [],
  );

  useEffect(() => {
    dispatch(getYCApi('widget', 'newsletters'));
  }, [dispatch]);

  return (
    <div className={`${valid ? 'valid' : 'invalid'}`}>
      <div className="newsletter-channels">
        <CheckboxListWidget {...props} valueList={channels} />
      </div>
    </div>
  );
};

export default NewsletterChannelsWidget;
