import {
  PreviewImage as VoltoPreviewImage,
  Image,
} from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';

const PreviewImage = (props) => {
  const { item, ...rest } = props;
  let image = <VoltoPreviewImage {...props} loading="lazy" />;

  if (!item.image_field && item.image) {
    const src = flattenToAppURL(item.image.scales.preview.download);
    image = <Image src={src} {...rest} />;
  }

  return <figure>{image}</figure>;
};
export default PreviewImage;
