import TokenWidget from '@plone/volto/components/manage/Widgets/TokenWidget';
import SubsiteSocialLinksWidget from '@package/components/Widgets/SubsiteSocialLinksWidget/SubsiteSocialLinksWidget';
import { ColorListWidget } from '@package/components';
import ImageWidget from '@package/components/Widgets/ImageWidget/ImageWidget';
import IconsWidget from '@package/components/Widgets/IconsWidget/IconsWidget';

export const SiteWidgets = (config) => {
  config.widgets.id.subsite_social_links = SubsiteSocialLinksWidget;
  config.widgets.id.skills = TokenWidget;
  config.widgets.widget.color_list = ColorListWidget;
  config.widgets.widget.image_upload_widget = ImageWidget;
  config.widgets.widget.icons_selector_widget = IconsWidget;
};

export default SiteWidgets;
