import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import {
  FormattedMessage,
  injectIntl,
  defineMessages,
  useIntl,
} from 'react-intl';
import { ObjectListWidget, SelectWidget } from '@plone/volto/components';
import ImageSizeWidget from '@plone/volto/components/manage/Widgets/ImageSizeWidget';
import { ColorListWidget } from '@package/components';

const messages = defineMessages({
  bg_color: {
    id: 'Colore di sfondo',
    defaultMessage: 'Colore di sfondo',
  },
  CardImageSize: {
    id: 'CardImageSize',
    defaultMessage: 'Dimensione immagine',
  },
  column_number: {
    id: 'column_number',
    defaultMessage: 'Visualizzazione colonne',
  },
  column: {
    id: 'column',
    defaultMessage: 'colonna',
  },
  columns: {
    id: 'columns',
    defaultMessage: 'colonne',
  },
});

const Sidebar = (props) => {
  const { data, block, onChangeBlock } = props;
  const intl = useIntl();

  useEffect(() => {
    if (!data.bg_color) {
      onChangeBlock(block, {
        ...data,
        bg_color: 'bg-primary',
      });
    }
    if (!data.column_number) {
      onChangeBlock(block, {
        ...data,
        column_number: '6',
      });
    }
  });

  return (
    <Segment.Group raised>
      <header className="header pulled main-title">
        <h2>
          <FormattedMessage
            id="text-with-icon"
            defaultMessage="Testo con icone"
          />
          :
        </h2>
      </header>

      <Segment className="form">
        <ColorListWidget
          id="bg_color"
          title={intl.formatMessage(messages.bg_color)}
          colors={[
            { name: 'bg-primary', label: 'Colore primario' },
            { name: 'bg-secondary', label: 'Colore secondario' },
            { name: 'bg-tertiary', label: 'Colore terziario' },
          ]}
          value={data.bg_color ?? 'terciary'}
          onChange={(name, value) =>
            onChangeBlock(block, { ...data, [name]: value })
          }
        />
        <ImageSizeWidget
          id="sizeImage"
          title={intl.formatMessage(messages.CardImageSize)}
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              sizeImage: value,
            });
          }}
          value={data.sizeImage}
        />
        <SelectWidget
          id="column_number"
          title={intl.formatMessage(messages.column_number)}
          value={data.column_number}
          onChange={(id, value) => {
            onChangeBlock(block, {
              ...data,
              column_number: value,
            });
          }}
          choices={[
            ['12', '1 ' + intl.formatMessage(messages.column)],
            ['6', '2 ' + intl.formatMessage(messages.columns)],
            ['4', '3 ' + intl.formatMessage(messages.columns)],
          ]}
        />
        <ObjectListWidget
          {...props}
          id="columns"
          value={data?.columns ?? []}
          onChange={(id, value) =>
            props.onChangeBlock(block, { ...data, [id]: value })
          }
          schema={() => {
            const columnsSchema = {
              title: 'Colonna',
              fieldsets: [
                {
                  id: 'default',
                  title: 'Default',
                  fields: ['title', 'url', 'href', 'href_title'],
                },
              ],
              properties: {
                title: { title: 'Titolo' },
                url: {
                  title: 'Icona',
                  description: "L'immagine deve essere un PNG",
                  widget: 'image_upload_widget',
                  openObjectBrowser: props.openObjectBrowser,
                },
                href: {
                  title: 'Link ad altro',
                  widget: 'object_browser',
                  allowExternals: true,
                  mode: 'link',
                },
                href_title: {
                  title: 'Titolo per il link ad altro',
                },
              },
              required: [],
            };

            return columnsSchema;
          }}
        />
      </Segment>
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  openObjectBrowser: PropTypes.func.isRequired,
};

export default injectIntl(Sidebar);
