import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { Grid, Message, Modal, Button } from 'semantic-ui-react';
import { Image, UniversalLink } from '@plone/volto/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { searchContent, resetSearchContent } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';

/**
 * Customizations:
 * - Added prop img.description inside Alt attribute for img tag
 */

const messages = defineMessages({
  view_prev: {
    id: 'gallery_prev',
    defaultMessage: "Vedi l'immagine precedente",
  },
  view_next: {
    id: 'gallery_next',
    defaultMessage: "Vedi l'immagine successiva",
  },
  close: {
    id: 'gallery_close',
    defaultMessage: "Chiudi l'anteprima dell'immagine",
  },
});
const Body = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [viewImageIndex, setViewImageIndex] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { properties, isEditMode } = props;
  const folder_name = 'immagini';
  const url = `${flattenToAppURL(properties['@id'])}/${folder_name}`;
  const searchResults = useSelector((state) => state.search.subrequests);
  const images = searchResults?.[folder_name]?.items || [];
  const imagePreviewRef = React.createRef();

  useEffect(() => {
    dispatch(
      searchContent(
        url,
        {
          'path.depth': 1,
          sort_on: 'getObjPositionInParent',
          metadata_fields: '_all',
          fullobjects: 1,
        },
        folder_name,
      ),
    );

    return () => {
      dispatch(resetSearchContent(folder_name));
    };
  }, [dispatch, folder_name, url]);

  useEffect(() => {
    if (viewImageIndex != null) {
      setModalIsOpen(true);
    } else {
      setModalIsOpen(false);
    }
  }, [viewImageIndex]);

  useEffect(() => {
    if (modalIsOpen) {
      imagePreviewRef.current.ref.current.querySelectorAll('button')[0].focus();
    }
  }, [modalIsOpen]);

  const tabKeyListener = (e) => {
    if (e.keyCode === 9) {
      const focused = imagePreviewRef.current.ref.current.querySelector(
        ':focus',
      );
      const modalItems = imagePreviewRef.current.ref.current.querySelectorAll(
        'button',
      );

      const lastItem = modalItems[modalItems.length - 1];
      if (focused === lastItem) {
        modalItems[0].focus();
      }
    }
  };
  useEffect(() => {
    if (imagePreviewRef?.current?.ref?.current) {
      imagePreviewRef.current.ref.current.addEventListener(
        'keydown',
        tabKeyListener,
      );
    }
  }, [imagePreviewRef]);
  return images?.length > 0 ? (
    <>
      <Grid padded>
        <Grid.Row>
          {images.map((img, idx) => (
            <Grid.Column
              mobile={6}
              tablet={6}
              computer={3}
              largeScreen={3}
              widescreen={3}
              key={idx}
            >
              <UniversalLink
                href={flattenToAppURL(img.image.scales.large.download)}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setViewImageIndex(idx);
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    e.stopPropagation();
                    setViewImageIndex(idx);
                  }
                }}
              >
                <figure className="image-wrapper">
                  <Image
                    src={flattenToAppURL(img.image.scales.large.download)}
                    loading="lazy"
                    alt={img.description || 'immagine artista ' + (idx + 1)}
                  />
                </figure>
              </UniversalLink>
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>

      <Modal
        basic
        onClose={() => {
          setViewImageIndex(null);
          setModalIsOpen(false);
        }}
        open={modalIsOpen}
        size="large"
        closeIcon={
          <button className="close" title={intl.formatMessage(messages.close)}>
            <FontAwesomeIcon icon={['fas', 'times']} />
          </button>
        }
        centered={true}
        className="artistGalleryModal"
        ref={imagePreviewRef}
        // trigger={<Button>Basic Modal</Button>}
      >
        {viewImageIndex != null && (
          <>
            <Modal.Content>
              <div className="image-preview">
                <Button
                  title={intl.formatMessage(messages.view_prev)}
                  onClick={() => {
                    setViewImageIndex(
                      viewImageIndex - 1 >= 0
                        ? viewImageIndex - 1
                        : images.length - 1,
                    );
                  }}
                  className="prev"
                >
                  <FontAwesomeIcon icon={['fas', 'arrow-left']} />
                </Button>
                <Button
                  title={intl.formatMessage(messages.view_next)}
                  onClick={() => {
                    setViewImageIndex(
                      viewImageIndex + 1 < images.length
                        ? viewImageIndex + 1
                        : 0,
                    );
                  }}
                  className="next"
                >
                  <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                </Button>
                <Image
                  src={flattenToAppURL(
                    images[viewImageIndex].image.scales.leadimage.download,
                  )}
                  loading="lazy"
                />
              </div>
            </Modal.Content>
          </>
        )}
      </Modal>
    </>
  ) : isEditMode ? (
    <Message>
      <center>
        <div className="message-text">
          Gallery: Nessun elemento nella cartella 'Immagini'
        </div>
      </center>
    </Message>
  ) : null;
};

export default Body;
