/**
 * View title block.
 * @module components/manage/Blocks/Title/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Container, Button } from 'semantic-ui-react';
import { UniversalLink } from '@plone/volto/components';
import backgorundImage from '@package/components/layout/images/bg-newsletter.svg';
/**
 * View title block class.
 * @class View
 * @extends Component
 */
const View = ({ data }) => (
  <div className="block newsletter">
    <div className="newsletter-container full-width">
      <div
        className="background"
        style={{ backgroundImage: `url(${backgorundImage})` }}
      ></div>
      <Container>
        <h2>{data.title}</h2>

        {data.description && (
          <div className="description">{data.description}</div>
        )}
        {data.linkMore?.href && (
          <div className="more">
            <Button as={UniversalLink} href={data.linkMore.href}>
              {data.linkMore.title || data.linkMore.href}
            </Button>
          </div>
        )}
      </Container>
    </div>
  </div>
);

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  properties: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
