import { defineMessages } from 'react-intl';
import envelopeSVG from '@plone/volto/icons/envelope.svg';
import starSVG from '@plone/volto/icons/star.svg';
import BlockSettingsSchema from '@plone/volto/components/manage/Blocks/Block/Schema';

import NewsletterChannelsWidget, {
  isValid as isValidNewsletterChannels,
} from '@package/components/Widgets/NewsletterChannels/NewsletterChannels';
import NewsletterPrivacyWidget, {
  isValid as isValidNewsletterPrivacy,
} from '@package/components/Widgets/NewsletterPrivacy/NewsletterPrivacy';

import {
  listingVariations,
  removeListingVariation,
} from '@package/config/Blocks/listingVariations.js';

import ViewNewsletterBlock from '@package/components/Blocks/Newsletter/View';
import EditNewsletterBlock from '@package/components/Blocks/Newsletter/Edit';

import ViewNewsletterSubscriptionBlock from '@package/components/Blocks/NewsletterSubscription/View';
import EditNewsletterSubscriptionBlock from '@package/components/Blocks/NewsletterSubscription/Edit';

import iconsBlocksSVG from '@plone/volto/icons/dots.svg';
import ViewIconsBlocks from '@package/components/Blocks/IconsBlocks/View';
import EditIconsBlocks from '@package/components/Blocks/IconsBlocks/Edit';
import DefaultRSSTemplate from '@package/components/Blocks/RssTemplates/DefaultRssTemplate';

import LinksBlockSVG from '@plone/volto/icons/link.svg';
import ViewLinksBlock from '@package/components/Blocks/LinksBlock/View';
import EditLinksBlock from '@package/components/Blocks/LinksBlock/Edit';

import ViewEventHeader from '@package/components/Blocks/EventHeader/View';
import EditEventHeader from '@package/components/Blocks/EventHeader/Edit';

import zoomSVG from '@plone/volto/icons/zoom.svg';
import EventsSearchView from '@package/components/Blocks/EventsSearch/View';
import EventsSearchEdit from '@package/components/Blocks/EventsSearch/Edit';

import ViewArtistHeader from '@package/components/Blocks/ArtistHeader/View';
import EditArtistHeader from '@package/components/Blocks/ArtistHeader/Edit';

import ViewArtistGallery from '@package/components/Blocks/ArtistGallery/View';
import EditArtistGallery from '@package/components/Blocks/ArtistGallery/Edit';

import ViewArtistLinks from '@package/components/Blocks/ArtistLinks/View';
import EditArtistLinks from '@package/components/Blocks/ArtistLinks/Edit';

import ArtistsSearchView from '@package/components/Blocks/ArtistsSearch/View';
import ArtistsSearchEdit from '@package/components/Blocks/ArtistsSearch/Edit';

import { rssBlock as customRssBlock } from 'volto-rss-block';

import ViewYoungerCardNumbersBlock from '@package/components/Blocks/YoungerCardNumbers/View';
import EditYoungerCardNumbersBlock from '@package/components/Blocks/YoungerCardNumbers/Edit';

import flagSVG from '@plone/volto/icons/flag.svg';
import ViewYoungerCardMapBlock from '@package/components/Blocks/YoungerCardMap/View';
import EditYoungerCardMapBlock from '@package/components/Blocks/YoungerCardMap/Edit';

import videoSVG from '@plone/volto/icons/video.svg';
import VideoGalleryView from '@package/components/Blocks/VideoGallery/View';
import VideoGalleryEdit from '@package/components/Blocks/VideoGallery/Edit';

import listArrowsSVG from '@plone/volto/icons/list-arrows.svg';
import AccordionView from '@package/components/Blocks/Accordion/View';
import AccordionEdit from '@package/components/Blocks/Accordion/Edit';

import ViewHTMLDefaultBlock from '@package/components/Blocks/HTML/Default/View';
import EditHTMLDefaultBlock from '@package/components/Blocks/HTML/Default/Edit';

import ViewHTMLTitleDescriptionBlock from '@package/components/Blocks/HTML/TitleDescription/View';
import EditHTMLTitleDescriptionBlock from '@package/components/Blocks/HTML/TitleDescription/Edit';

//YOUZ BLOCKS
import textBackgroundIcon from '@package/icons/text-background-icon.svg';
import TextBackgroundView from '@package/components/Blocks/TextBackground/View';
import TextBackgroundEdit from '@package/components/Blocks/TextBackground/Edit';

import numbersIcon from '@package/icons/numeri-icon.svg';
import TableOfNumbersView from '@package/components/Blocks/TableOfNumbers/View';
import TableOfNumbersEdit from '@package/components/Blocks/TableOfNumbers/Edit';

import tuneSVG from '@plone/volto/icons/tune.svg';
import DecalogoView from '@package/components/Blocks/Decalogo/View';
import DecalogoEdit from '@package/components/Blocks/Decalogo/Edit';

import textWithIconSVG from '@package/icons/text-with-icon.svg';
import TextWithIconsEdit from '@package/components/Blocks/TextWithIcons/Edit';
import TextWithIconsView from '@package/components/Blocks/TextWithIcons/View';

import columnTextIcon from '@package/icons/column-text-icon.svg';
import ColumnTextEdit from '@package/components/Blocks/ColumnText/Edit';
import ColumnTextView from '@package/components/Blocks/ColumnText/View';

const messages = defineMessages({
  linkMore: { id: 'Link more', defaultMessage: 'Link ad altro' },
  LinkTitle: {
    id: 'Link title',
    defaultMessage: 'Link Title',
  },
  LinkTo: {
    id: 'Link to',
    defaultMessage: 'Link to',
  },
});

export const SiteBlocks = (config) => {
  config.blocks = {
    ...config.blocks,
    requiredBlocks: [
      ...config.blocks.requiredBlocks,
      'description',
      //'leadimage',
      'artistHeader',
      'artistGallery',
      'artistLinks',
    ],
    initialBlocks: {
      ...config.blocks.initialBlocks,
      Document: ['title', 'description', 'leadimage', 'text'],
      'News Item': ['title', 'description', 'leadimage', 'text'],
      Event: ['title', 'description', 'eventHeader', 'text'],
      Artist: ['artistHeader', 'text', 'artistGallery', 'artistLinks'],
    },
    groupBlocksOrder: [
      ...config.blocks.groupBlocksOrder,
      { id: 'youngerCard', title: 'Younger Card' },
      { id: 'youz', title: 'Youz' },
    ],
    blocksConfig: {
      ...config.blocks.blocksConfig,
      listing: {
        ...config.blocks.blocksConfig.listing,
        showLinkMore: true,
        variations: [
          ...config.blocks.blocksConfig.listing.variations,
          ...listingVariations,
        ],
      },
      hero: {
        ...config.blocks.blocksConfig.hero,
        sidebarTab: 1,
      },
      newsletter: {
        id: 'newsletter',
        title: 'Newsletter',
        icon: envelopeSVG,
        group: 'common',
        view: ViewNewsletterBlock,
        edit: EditNewsletterBlock,
        schema: BlockSettingsSchema,
        restricted: false,
        mostUsed: false,
        blockHasOwnFocusManagement: true,
        security: {
          addPermission: [],
          view: [],
        },
        sidebarTab: 1,
      },
      newsletter_subscription: {
        id: 'newsletter_subscription',
        title: 'Iscrizione Newsletter',
        icon: envelopeSVG,
        group: 'common',
        view: ViewNewsletterSubscriptionBlock,
        edit: EditNewsletterSubscriptionBlock,
        schema: BlockSettingsSchema,
        restricted: false,
        mostUsed: false,
        blockHasOwnFocusManagement: false,
        security: {
          addPermission: [],
          view: [],
        },
        sidebarTab: 0,
      },
      iconsBlocks: {
        id: 'iconsBlocks',
        title: 'Blocchi con icone',
        icon: iconsBlocksSVG,
        group: 'common',
        view: ViewIconsBlocks,
        edit: EditIconsBlocks,
        schema: BlockSettingsSchema,
        restricted: false,
        mostUsed: true,
        blockHasOwnFocusManagement: true,
        security: {
          addPermission: [],
          view: [],
        },
        sidebarTab: 1,
      },
      linksBlock: {
        id: 'linksBlock',
        title: 'Blocco con link',
        icon: LinksBlockSVG,
        group: 'text',
        view: ViewLinksBlock,
        edit: EditLinksBlock,
        schema: BlockSettingsSchema,
        restricted: false,
        mostUsed: true,
        blockHasOwnFocusManagement: true,
        security: {
          addPermission: [],
          view: [],
        },
        sidebarTab: 1,
      },
      video_gallery: {
        id: 'video_gallery',
        title: 'Video Gallery',
        icon: videoSVG,
        group: 'media',
        view: VideoGalleryView,
        edit: VideoGalleryEdit,
        schema: BlockSettingsSchema,
        blockHasOwnFocusManagement: true,
        restricted: false,
        mostUsed: true,
        security: {
          addPermission: [],
          view: [],
        },
        sidebarTab: 1,
      },
      rssBlock: {
        ...customRssBlock,
        templates: {
          default: { label: 'Default template', template: DefaultRSSTemplate },
        },
      },
      youngerCardNumbers: {
        id: 'youngerCardNumbers',
        title: 'Numeri di YoungER Card',
        icon: starSVG,
        group: 'youngerCard',
        view: ViewYoungerCardNumbersBlock,
        edit: EditYoungerCardNumbersBlock,
        schema: BlockSettingsSchema,
        restricted: false,
        mostUsed: false,
        sidebarTab: 1,
      },

      youngerCardMap: {
        id: 'youngerCardMap',
        title: 'Mappa YoungER Card',
        icon: flagSVG,
        group: 'youngerCard',
        view: ViewYoungerCardMapBlock,
        edit: EditYoungerCardMapBlock,
        schema: BlockSettingsSchema,
        restricted: false,
        mostUsed: false,
        sidebarTab: 1,
      },

      eventHeader: {
        id: 'eventHeader',
        title: "Testata dell'evento",
        view: ViewEventHeader,
        edit: EditEventHeader,
        schema: BlockSettingsSchema,
        restricted: true,
      },

      eventsSearch: {
        id: 'eventsSearch',
        title: 'Ricerca eventi',
        icon: zoomSVG,
        group: 'search',
        view: EventsSearchView,
        edit: EventsSearchEdit,
        restricted: false,
        mostUsed: false,
        blockHasOwnFocusManagement: false,
        security: {
          addPermission: [],
          view: [],
        },
        sidebarTab: 1,
      },

      artistHeader: {
        id: 'artistHeader',
        title: "Testata dell'artista",
        view: ViewArtistHeader,
        edit: EditArtistHeader,
        schema: BlockSettingsSchema,
        restricted: true,
      },

      artistGallery: {
        id: 'artistGallery',
        title: "Gallery dell'artista",
        view: ViewArtistGallery,
        edit: EditArtistGallery,
        schema: BlockSettingsSchema,
        restricted: true,
      },
      artistLinks: {
        id: 'artistLinks',
        title: "Link dell'artista",
        view: ViewArtistLinks,
        edit: EditArtistLinks,
        schema: BlockSettingsSchema,
        restricted: true,
      },
      artistsSearch: {
        id: 'artistsSearch',
        title: 'Ricerca artisti',
        icon: zoomSVG,
        group: 'search',
        view: ArtistsSearchView,
        edit: ArtistsSearchEdit,
        restricted: false,
        mostUsed: false,
        blockHasOwnFocusManagement: false,
        security: {
          addPermission: [],
          view: [],
        },
        sidebarTab: 0,
      },

      accordion: {
        id: 'accordion',
        title: 'Accordion',
        icon: listArrowsSVG,
        group: 'text',
        view: AccordionView,
        edit: AccordionEdit,
        restricted: false,
        mostUsed: false,
        security: {
          addPermission: [],
          view: [],
        },
        sidebarTab: 1,
      },
      html: {
        ...config.blocks.blocksConfig.html,
        sidebarTab: 1,
        variations: [
          {
            id: 'default',
            title: 'Default',
            isDefault: true,
            view: ViewHTMLDefaultBlock,
            edit: EditHTMLDefaultBlock,
          },
          {
            id: 'title_description',
            title: 'Con titolo e descrizione',
            view: ViewHTMLTitleDescriptionBlock,
            edit: EditHTMLTitleDescriptionBlock,
            schemaEnhancer: ({ schema, formData, intl }) => {
              schema.properties.linkMoreTitle = {
                title: intl.formatMessage(messages.LinkTitle),
              };
              schema.properties.linkMoreHref = {
                title: intl.formatMessage(messages.LinkTo),
                widget: 'object_browser',
                mode: 'link',
                selectedItemAttrs: ['Title', 'Description'],
                allowExternals: true,
              };

              schema.fieldsets.push({
                id: 'linkmore',
                title: intl.formatMessage(messages.linkMore),
                fields: ['linkMoreTitle', 'linkMoreHref'],
              });

              return schema;
            },
          },
        ],
      },

      //Youz blocks
      textBackground: {
        id: 'textBackground',
        title: 'Testo con sfondo',
        icon: textBackgroundIcon,
        group: 'text',
        view: TextBackgroundView,
        edit: TextBackgroundEdit,
        restricted: false,
        security: {
          addPermission: [],
          view: [],
        },
        sidebarTab: 1,
      },
      tableOfNumbers: {
        id: 'tableOfNumbers',
        title: 'Numeri',
        icon: numbersIcon,
        group: 'text',
        view: TableOfNumbersView,
        edit: TableOfNumbersEdit,
        restricted: false,
        mostUsed: false,
        security: {
          addPermission: [],
          view: [],
        },
      },
      columnText: {
        id: 'columnText',
        title: 'Testo incolonnato',
        icon: columnTextIcon,
        group: 'text',
        view: ColumnTextView,
        edit: ColumnTextEdit,
        restricted: false,
        security: {
          addPermission: [],
          view: [],
        },
        sidebarTab: 1,
      },
      Decalogo: {
        id: 'Decalogo',
        title: 'Decalogo',
        icon: tuneSVG,
        group: 'text',
        view: DecalogoView,
        edit: DecalogoEdit,
        restricted: false,
        mostUsed: false,
        security: {
          addPermission: [],
          view: [],
        },
        sidebarTab: 1,
      },
      textWithIcons: {
        id: 'textWithIcons',
        title: 'Testo con icone',
        icon: textWithIconSVG,
        group: 'text',
        view: TextWithIconsView,
        edit: TextWithIconsEdit,
        restricted: false,
        security: {
          addPermission: [],
          view: [],
        },
        sidebarTab: 1,
      },
    },
  };

  config.blocks.blocksConfig.__grid = {
    ...config.blocks.blocksConfig.__grid,
    gridAllowedBlocks: [
      ...config.blocks.blocksConfig.__grid.gridAllowedBlocks,
      'video',
    ],
  };
  delete config.blocks.blocksConfig.imagesGrid; //remove imagesGrid block
  delete config.blocks.blocksConfig.teaserGrid; //remove teaserGrid block
  delete config.blocks.blocksConfig.teaser; //remove teaser block

  if (!config.blocks.blocksConfig.form?.additionalFields)
    config.blocks.blocksConfig.form.additionalFields = [];
  config.blocks.blocksConfig.form.additionalFields.push({
    id: 'newsletter-channels',
    label: 'Canali Newsletter',
    component: NewsletterChannelsWidget,
    isValid: isValidNewsletterChannels,
  });
  config.blocks.blocksConfig.form.additionalFields.push({
    id: 'newsletter-privacy',
    label: 'Privacy Newsletter',
    component: NewsletterPrivacyWidget,
    isValid: isValidNewsletterPrivacy,
  });

  config.blocks.blocksConfig.description.restricted = true;
  config.blocks.blocksConfig.leadimage.restricted = false;

  config.blocks.groupBlocksOrder = [
    ...config.blocks.groupBlocksOrder,
    { id: 'search', title: 'Ricerca' },
  ];

  removeListingVariation(config, 'summary');
  removeListingVariation(config, 'imageGallery');
};
export default SiteBlocks;
