import React from 'react';
import redraft from 'redraft';
import config from '@plone/volto/registry';
import { Container } from 'semantic-ui-react';

const View = ({ data }) => {
  const singleCell = (number, description) => {
    if (number || description) {
      return (
        <div className="cell">
          {number && (
            <div className="number">
              <h2>{number}</h2>
            </div>
          )}
          {description && (
            <div className="description">
              {redraft(
                description,
                config.settings.richtextViewSettings.ToHTMLRenderers,
                config.settings.richtextViewSettings.ToHTMLOptions,
              )}
            </div>
          )}
        </div>
      );
    }
  };

  return (
    <section className="tableOfNumbers-wrapper full-width">
      <Container className="flex-container">
        {singleCell(data.number1, data.description1)}
        {singleCell(data.number2, data.description2)}
        {singleCell(data.number3, data.description3)}
        {singleCell(data.number4, data.description4)}
        {singleCell(data.number5, data.description5)}
      </Container>
    </section>
  );
};

export default View;
