/**
 * ViewBlock of Links blocks, single block
 * @module components/manage/Blocks/LinksBlock/Block/ViewBlock
 */

import React from 'react';
import redraft from 'redraft';
import config from '@plone/volto/registry';
import { Icon, UniversalLink } from '@plone/volto/components';
import cx from 'classnames';
import { Grid } from 'semantic-ui-react';
import arrowSVG from '@package/components/layout/icons/circle-right.svg';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  infoLink: {
    id: 'More information link',
    defaultMessage: 'Più informazione su {title}.',
  },
  infoLinkBlank: {
    id: 'More information link without title',
    defaultMessage: 'Apri link {path}',
  },
});

const ViewBlock = ({ data, nColumns }) => {
  const intl = useIntl();

  return data.section || data.title || data.subtitle ? (
    <Grid.Column
      computer={
        nColumns > 3
          ? 3
          : nColumns === 1
          ? 12
          : nColumns === 2
          ? 6
          : nColumns === 3 && 4
      }
      tablet={6}
      mobile={12}
    >
      <div
        className={cx(
          'single-card',
          data?.bg_color_card ? data.bg_color_card : 'sectionGreen',
        )}
      >
        {data.section && <h2 className="section">{data.section}</h2>}
        {data.title &&
          (data.href?.length > 0 ? (
            <UniversalLink
              tabIndex="-1"
              item={data.href[0]}
              title={intl.formatMessage(messages.infoLink, {
                title: data.title,
              })}
            >
              <h3 className="title">{data.title}</h3>
            </UniversalLink>
          ) : (
            <h3 className="title">{data.title}</h3>
          ))}
        {data.subtitle && (
          <div className="subtitle">
            {redraft(
              data.subtitle,
              config.settings.richtextViewSettings.ToHTMLRenderers,
              config.settings.richtextViewSettings.ToHTMLOptions,
            )}
          </div>
        )}
        {data.href?.length > 0 && (
          <div className="icon-link">
            <UniversalLink
              item={data.href[0]}
              title={
                data.title
                  ? intl.formatMessage(messages.infoLink, {
                      title: data.title,
                    })
                  : intl.formatMessage(messages.infoLinkBlank, {
                      path: data.href,
                    })
              }
            >
              <Icon name={arrowSVG} />
            </UniversalLink>
          </div>
        )}
      </div>
    </Grid.Column>
  ) : (
    <></>
  );
};

export default ViewBlock;
