import React from 'react';

import Icon from '@plone/volto/components/theme/Icon/Icon';
import unorderedSeparatedListSVG from '@package/icons/unordered-separated-list.svg';

const UnorderedListSeparatedButton = (props) => {
  const createBlockStyleButton = props.draftJsCreateBlockStyleButton.default;

  return createBlockStyleButton({
    blockType: 'unordered-list-separated-item',
    children: <Icon name={unorderedSeparatedListSVG} size="24px" />,
  });
};

export default UnorderedListSeparatedButton;
