/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, defineMessages } from 'react-intl';
import Slider from 'react-slick';
import { Container } from 'semantic-ui-react';
import cx from 'classnames';
import { UniversalLink, Icon, PreviewImage } from '@plone/volto/components';

import galleryPlaySVG from '@plone/volto/icons/play.svg';
import galleryPauseSVG from '@plone/volto/icons/pause.svg';
import rightKeySVG from '@plone/volto/icons/right-key.svg';
import leftKeySVG from '@plone/volto/icons/left-key.svg';
import aheadSVG from '@plone/volto/icons/ahead.svg';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const messages = defineMessages({
  viewImage: {
    id: 'Vai al contenuto',
    defaultMessage: 'Vai al contenuto',
  },
  play: {
    id: 'Play slider',
    defaultMessage: 'Avvia il carosello con le immagini',
  },
  pause: {
    id: 'Pause slider',
    defaultMessage: 'Metti in pausa il carosello',
  },
  sliderDot: {
    id: 'Slider Dot',
    defaultMessage: 'Slider numero ', //Lasciare spazio dopo il testo
  },
});

const SliderTemplate = ({
  items,
  linkMore,
  isEditMode,
  title,
  full_width,
  show_item_title,
  show_item_description,
}) => {
  const intl = useIntl();
  const [autoplay, setAutoplay] = useState(false);
  const [slider, setSlider] = useState(null);

  const NextArrow = (props) => {
    const { className, style, onClick } = props;

    return (
      <div className={className} style={{ ...style }} onClick={onClick}>
        <Icon name={rightKeySVG} size="unset" />
      </div>
    );
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;

    return (
      <div className={className} style={{ ...style }} onClick={onClick}>
        <Icon name={leftKeySVG} size="unset" />
      </div>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    focusOnSelect: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: autoplay,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    customPaging: (i) => (
      <button
        tabIndex={-1}
        title={intl.formatMessage(messages.sliderDot) + (i + 1)}
      >
        {i + 1}
      </button>
    ),
  };
  return items?.length > 0 ? (
    <div aria-hidden="true" className="sliderTemplate">
      {title && <h2 className="text-center">{title}</h2>}
      <Container>
        <div className={cx('slider-container', { 'full-width': full_width })}>
          <div className="play-pause-wrapper">
            <Container>
              <button
                onClick={() => {
                  if (autoplay) {
                    slider.slickPause();
                    setAutoplay(false);
                  } else {
                    slider.slickPlay();
                    setAutoplay(true);
                  }
                }}
                title={
                  autoplay
                    ? intl.formatMessage(messages.pause)
                    : intl.formatMessage(messages.play)
                }
              >
                {autoplay ? (
                  <Icon name={galleryPauseSVG} size="30px" />
                ) : (
                  <Icon name={galleryPlaySVG} size="30px" />
                )}
                <span>{autoplay ? 'pause' : 'play'}</span>
              </button>
            </Container>
          </div>
          <Slider {...settings} ref={(slider) => setSlider(slider)}>
            {items.map((item, index) => {
              const image = item.image?.scales?.large?.download;

              return (
                <div className="single-slide-wrapper" key={item['@id']}>
                  <div className="slide-wrapper">
                    <figure className="img-wrapper">
                      {image && (
                        <picture>
                          <PreviewImage
                            item={item}
                            size="large"
                            aria-hidden="true"
                          />
                        </picture>
                      )}
                    </figure>

                    {(show_item_title || show_item_description) && (
                      <div className="slide-text">
                        <Container>
                          <UniversalLink
                            item={item}
                            title={intl.formatMessage(messages.viewImage)}
                          >
                            {show_item_title && (
                              <div className="slide-title">{item.title}</div>
                            )}
                            {show_item_description && item.description && (
                              <div className="slide-description">
                                {item.description}
                              </div>
                            )}
                            <Icon name={aheadSVG} />
                          </UniversalLink>
                        </Container>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </Container>
    </div>
  ) : null;
};

SliderTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default SliderTemplate;
