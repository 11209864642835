import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Body from './Body';
import { SidebarPortal } from '@plone/volto/components';
import Sidebar from './Sidebar';

const defaultItems = [
  {
    id: 'fondi',
    title: 'fondi stanziati',
    title_mini: 'nei progetti L.R. 14/08',
    number: '537',
    number_description: 'milioni di euro',
    icon: 'starship-freighter',
  },
  {
    id: 'giovani_coinvolti',
    title: 'giovani coinvolti',
    title_mini: 'nei progetti L.R. 14/08',
    number: '12000',
    icon: 'hand-spock',
  },
  {
    id: 'giovani_iscritti',
    title: 'giovani iscritti',
    title_mini: 'YoungERcard',
    icon: 'user-austronaut',
    readonly: true,
  },
  {
    id: 'soggetti_coinvolti',
    title: 'soggetti coinvolti',
    title_mini: 'nei progetti L.R. 14/08',
    number: '20000',
    icon: 'ufo-beam',
  },
];

/**
 * Edit block class.
 * @class Edit
 * @extends Component
 */
const Edit = (props) => {
  const { data, onChangeBlock, block, selected } = props;

  useEffect(() => {
    if (!data.items) {
      onChangeBlock(block, {
        ...data,
        items: defaultItems,
      });
    }
  }, []);

  return (
    <div className="block youngercard-numbers">
      <Body {...props} isEditMode={true} />

      <SidebarPortal selected={selected}>
        <Sidebar {...props} />
      </SidebarPortal>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Edit.propTypes = {
  properties: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Edit;
