import React, { useEffect, useState } from 'react';

import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Segment, Form, Divider, Header, Accordion } from 'semantic-ui-react';
import withObjectBrowser from '@plone/volto/components/manage/Sidebar/ObjectBrowser';
import { TextWidget, Icon } from '@plone/volto/components';
import NumberWidget from '@plone/volto/components/manage/Widgets/NumberWidget';
import linkSVG from '@plone/volto/icons/link.svg';
import upSVG from '@plone/volto/icons/up-key.svg';
import downSVG from '@plone/volto/icons/down-key.svg';
import { LinkToWidget } from '@package/components/';

const messages = defineMessages({
  LinkTitle: {
    id: 'Link more title',
    defaultMessage: 'Titolo link',
  },
  title: {
    id: 'Titolo',
    defaultMessage: 'Titolo',
  },
  title_help: {
    id: 'Titolo del blocco',
    defaultMessage: 'Titolo del blocco',
  },
  linkMore: {
    id: 'Link more',
    defaultMessage: 'Link ad altro',
  },
  number: {
    id: 'Youngercard_number',
    defaultMessage: 'Numero',
  },
  number_description: {
    id: 'Youngercard_number_description',
    defaultMessage: 'Descrizione',
  },
});

const Sidebar = ({ data, onChangeBlock, block, intl, openObjectBrowser }) => {
  const [accActive, setAccActive] = useState(0);

  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage
            id="YoungerCard Numbers"
            defaultMessage="Numeri di YoungER Card"
          />
        </h2>
      </header>

      <Segment className="form sidebar-button">
        <Form>
          <TextWidget
            id="title"
            title={intl.formatMessage(messages.title)}
            description={intl.formatMessage(messages.title_help)}
            required={false}
            value={data.title}
            onChange={(name, value) => {
              onChangeBlock(block, {
                ...data,
                [name]: value,
              });
            }}
          />
          <Divider horizontal>
            <Header as="h6">
              <Icon name={linkSVG} size="24px" />
              {intl.formatMessage(messages.linkMore)}
            </Header>
          </Divider>
          <TextWidget
            id="linkTitle"
            title={intl.formatMessage(messages.LinkTitle)}
            required={false}
            value={data.linkMore?.title}
            onChange={(name, value) => {
              onChangeBlock(block, {
                ...data,
                linkMore: {
                  ...data.linkMore,
                  title: value,
                },
              });
            }}
          />

          <LinkToWidget
            data={data.linkMore ?? {}}
            openObjectBrowser={openObjectBrowser}
            onChange={(name, value) => {
              onChangeBlock(block, {
                ...data,
                linkMore: { ...data.linkMore, [name]: value },
              });
            }}
          />
        </Form>
      </Segment>
      <Segment>
        <Accordion fluid styled className="form">
          {data.items &&
            data.items.map((item, index) => (
              <div key={'item' + index}>
                <Accordion.Title
                  active={accActive === index}
                  index={index}
                  onClick={() =>
                    setAccActive(accActive === index ? null : index)
                  }
                >
                  {item.title}
                  {accActive === index ? (
                    <Icon name={upSVG} size="20px" />
                  ) : (
                    <Icon name={downSVG} size="20px" />
                  )}
                </Accordion.Title>
                <Accordion.Content active={accActive === index}>
                  <NumberWidget
                    id="number"
                    title={intl.formatMessage(messages.number)}
                    value={item.number}
                    onChange={(name, value) => {
                      let items = [...data.items];
                      items[index][name] = value;
                      onChangeBlock(block, {
                        ...data,
                        items: items,
                      });
                    }}
                    isDisabled={item.readonly}
                  />
                  <TextWidget
                    id="number_description"
                    title={intl.formatMessage(messages.number_description)}
                    value={item.number_description}
                    onChange={(name, value) => {
                      let items = [...data.items];
                      items[index][name] = value;
                      onChangeBlock(block, {
                        ...data,
                        items: items,
                      });
                    }}
                  />
                </Accordion.Content>
              </div>
            ))}
        </Accordion>
      </Segment>
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  block: PropTypes.string.isRequired,
};

export default compose(withObjectBrowser, injectIntl)(Sidebar);
