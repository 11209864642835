import React, { useState, useReducer, useEffect, createRef } from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Button,
  Pagination,
  Icon as SemanticIcon,
  Input,
  Grid,
  Container,
  Select,
} from 'semantic-ui-react';
import moment from 'moment';

import { Icon } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import paginationLeftSVG from '@plone/volto/icons/left-key.svg';
import paginationRightSVG from '@plone/volto/icons/right-key.svg';
import clearSVG from '@plone/volto/icons/clear.svg';

import ArtistItem from './ArtistItem';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { getQueryStringResults, getVocabulary } from '@plone/volto/actions';

const messages = defineMessages({
  artistsSearch: {
    id: 'artist_search',
    defaultMessage: 'Ricerca artisti',
  },
  artistsSearchText: {
    id: 'artist_search_text',
    defaultMessage: 'Digita la parola chiave',
  },
  artistsSearchArtists: {
    id: 'artist_search_artists',
    defaultMessage: 'Artisti',
  },
  artistsSearchForText: {
    id: 'artist_search_for',
    defaultMessage: 'contenenti',
  },
  clearText: {
    id: 'clear_text',
    defaultMessage: 'Cancella',
  },
  no_results_found: {
    id: 'no_results_found',
    defaultMessage: 'Nessun risultato',
  },
  search_province: {
    id: 'artist_search_province',
    defaultMessage: 'Provincia',
  },
  search_skills: {
    id: 'artist_search_skills',
    defaultMessage: 'Tipologia',
  },
});

const filtersActions = {
  TEXT: 'TEXT',
  SKILLS: 'SKILLS',
  PROVINCE: 'PROVINCE',
  RESET: 'RESET',
};

const DEFAULT_B_SIZE = 8;

const Body = ({ data, isEditMode, onChangeBlock }) => {
  const location = useLocation();
  const pathname = flattenToAppURL(location?.pathname).replace('/edit', '');
  const intl = useIntl();
  const [lastSubrequest, setLastSubrequest] = useState(null);

  moment.locale(intl.locale);
  const dispatch = useDispatch();
  const [b_size, setB_size] = useState(DEFAULT_B_SIZE);

  const provinces_vocab = useSelector((state) => {
    return state.vocabularies?.['giovazoom.policy.vocabularies.provinces']
      ?.items;
  });
  const skills_vocab = useSelector((state) => {
    return state.vocabularies?.['giovazoom.policy.vocabularies.skills']?.items;
  });

  useEffect(() => {
    dispatch(
      getVocabulary({
        vocabNameOrURL: 'giovazoom.policy.vocabularies.provinces',
      }),
    );
    dispatch(
      getVocabulary({ vocabNameOrURL: 'giovazoom.policy.vocabularies.skills' }),
    );
  }, [dispatch]);

  const provinces = provinces_vocab?.map((p) => ({
    key: p.value,
    text: p.label,
    value: p.value,
  }));

  const skillsList = skills_vocab?.map((s) => ({
    key: s.value,
    text: s.label,
    value: s.value,
  }));

  const initialFiltersState = {
    text: '',
    province: null,
    skills: [],
    changedFilters: true,
  };

  const filtersReducer = (state = initialFiltersState, action) => {
    let newState = { ...state };

    switch (action.type) {
      case filtersActions.TEXT:
        newState.text = action.value;
        newState.changedFilters = true;
        break;

      case filtersActions.SKILLS:
        newState.skills = action.value;
        newState.changedFilters = true;
        break;

      case filtersActions.PROVINCE:
        newState.province = action.value;
        newState.changedFilters = true;
        break;

      case filtersActions.RESET:
        newState = {
          ...initialFiltersState,
        };

        break;

      default:
        return newState;
    }

    return newState;
  };

  const [
    { text, province, skills, changedFilters },
    dispatchFilter,
  ] = useReducer(filtersReducer, initialFiltersState);

  const [currentPage, setCurrentPage] = useState(1);

  const querystringResults = useSelector((state) => {
    return state.querystringsearch?.subrequests?.[lastSubrequest];
  });

  const items = useSelector((state) => {
    return state.querystringsearch?.subrequests?.[lastSubrequest]?.items ?? [];
  });

  const loading = useSelector((state) => {
    return (
      state.querystringsearch?.subrequests?.[lastSubrequest]?.loading || false
    );
  });

  useEffect(() => {
    if (changedFilters) {
      doRequest(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text, province, skills, changedFilters, b_size]);

  useEffect(() => {
    setB_size(data.b_size ?? DEFAULT_B_SIZE);
  }, [data.b_size]);

  const getQueryFilter = (query, i, o) => {
    if (!query) return '';
    let ret = null;
    query.forEach((f) => {
      if (i && f.i === i && o && o === f.o) {
        ret = f;
      }
    });
    return ret;
  };

  const getFilters = () => {
    const defaultQuery = data.query ?? [];

    let filters = JSON.parse(JSON.stringify(defaultQuery));

    if (
      !getQueryFilter(
        filters,
        'portal_type',
        'plone.app.querystring.operation.selection.any',
      )
    ) {
      filters.push({
        i: 'portal_type',
        o: 'plone.app.querystring.operation.selection.any',
        v: ['Artist'],
      });
    }

    if (text && text.length) {
      filters.push({
        i: 'SearchableText',
        o: 'plone.app.querystring.operation.string.contains',
        v: text + '*',
      });
    }

    if (province) {
      filters.push({
        i: 'province',
        o: 'plone.app.querystring.operation.string.is',
        v: province,
      });
    }

    if (skills?.length > 0) {
      filters.push({
        i: 'skills',
        o: 'plone.app.querystring.operation.selection.any',
        v: skills,
      });
    }

    return filters;
  };

  const doRequest = (page = currentPage) => {
    const filters = getFilters();
    const subrequestId = new Date().getTime();
    setLastSubrequest(subrequestId);

    dispatch(
      getQueryStringResults(
        pathname,
        {
          fullobjects: 1,
          query: filters,
          b_size: b_size,
        },
        subrequestId,
        page,
      ),
    );
  };

  const resultsRef = createRef();

  // Se cambia il tipo di wichEvents o i criteri impostati dalla sidebar (data.query), resetto lo stato dei filtri
  useEffect(() => {
    dispatchFilter({ type: filtersActions.RESET });
  }, [data.query]);

  const handleQueryPaginationChange = (e) => {
    resultsRef.current.scrollIntoView({ behavior: 'smooth' });
    const current = 1;

    setCurrentPage(current);
    doRequest(current);
  };

  return (
    <div ref={resultsRef}>
      <div className="site-search-filters">
        <div className="site-search-filter-text site-search-filter">
          <Input
            placeholder={intl.formatMessage(messages.artistsSearchText)}
            aria-label={
              intl.formatMessage(messages.artistsSearch) +
              ': ' +
              intl.formatMessage(messages.artistsSearchText)
            }
            value={text}
            className={text?.length > 0 ? 'has-value' : ''}
            icon
            onChange={(e, data) => {
              dispatchFilter({
                type: filtersActions.TEXT,
                value: data.value ?? '',
              });
            }}
            aria-controls="artists-search-results"
          >
            <input />
            {text.length > 0 && (
              <Button
                inverted
                icon
                title={intl.formatMessage(messages.clearText)}
                aria-label={intl.formatMessage(messages.clearText)}
                onClick={() =>
                  dispatchFilter({
                    type: filtersActions.TEXT,
                    value: '',
                  })
                }
              >
                <Icon name={clearSVG} />
              </Button>
            )}
          </Input>
        </div>

        {provinces?.length > 0 && (
          <div className="site-search-filter select">
            <Select
              placeholder={intl.formatMessage(messages.search_province)}
              aria-label={intl.formatMessage(messages.search_province)}
              className="site-search-filter-select"
              options={provinces}
              value={data.province}
              clearable
              onChange={(e, data) => {
                dispatchFilter({
                  type: filtersActions.PROVINCE,
                  value: data.value,
                });
              }}
            />
          </div>
        )}

        {skillsList?.length > 0 && (
          <div className="site-search-filter select">
            <Select
              className="site-search-filter-select"
              placeholder={intl.formatMessage(messages.search_skills)}
              aria-label={intl.formatMessage(messages.search_skills)}
              multiple
              options={skillsList}
              value={data.skills}
              clearable
              onChange={(e, data) => {
                dispatchFilter({
                  type: filtersActions.SKILLS,
                  value: data.value,
                });
              }}
            />
          </div>
        )}
      </div>

      {/* <h2>
        <AnimatePresence>
          <motion.span
            key="items-length"
            className="colored"
          >{`${items_total} `}</motion.span>
          <motion.span key="filters-info">
            {`${intl.formatMessage(messages.artistsSearchArtists)}${
              filtersStrings.length > 0 ? ': ' : ''
            }`}
          </motion.span>
          {filtersStrings.length > 0 &&
            filtersStrings.map((filter) => (
              <motion.span
                key={filter}
                initial={{ scale: 0.7, opacity: 0.5 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.7, opacity: 0.5 }}
              >
                {filter}
              </motion.span>
            ))}
        </AnimatePresence>
      </h2> */}

      {/* mostra i risultati solamente dopo che l'utente ha inserito dei filtri (changedFilters) */}

      {loading && changedFilters ? (
        <Container textAlign="center">
          <SemanticIcon loading name="spinner" size="big" />
        </Container>
      ) : (
        <div id="artists-search-results" aria-live="polite" role="region">
          {items?.length === 0 && changedFilters && (
            <h2>
              <AnimatePresence>
                <motion.span key="filters-info">
                  {intl.formatMessage(messages.no_results_found)}
                </motion.span>
              </AnimatePresence>
            </h2>
          )}
          {items?.length > 0 && (
            <div className="block listing">
              <Grid
                stackable
                columns={4}
                verticalAlign="top"
                className="default-template"
              >
                <AnimatePresence initial={true}>
                  {items.map((item) => (
                    <motion.div
                      key={item['@id']}
                      className="column"
                      initial={{ scale: 0.7, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      exit={{ scale: 0.7, opacity: 0 }}
                    >
                      <ArtistItem item={item} isEditMode={isEditMode} />
                    </motion.div>
                  ))}
                </AnimatePresence>
              </Grid>

              {querystringResults.total > b_size && (
                <Container textAlign="center">
                  <Pagination
                    activePage={currentPage}
                    totalPages={Math.ceil(querystringResults.total / b_size)}
                    onPageChange={handleQueryPaginationChange}
                    firstItem={null}
                    lastItem={null}
                    prevItem={{
                      content: <Icon name={paginationLeftSVG} size="18px" />,
                      icon: true,
                      'aria-disabled': !querystringResults.batching.prev,
                      className: !querystringResults.batching.prev
                        ? 'disabled'
                        : null,
                    }}
                    nextItem={{
                      content: <Icon name={paginationRightSVG} size="18px" />,
                      icon: true,
                      'aria-disabled': !querystringResults.batching.next,
                      className: !querystringResults.batching.next
                        ? 'disabled'
                        : null,
                    }}
                  />
                </Container>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default Body;
