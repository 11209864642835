/**
 * Edit icons block.
 * @module components/manage/Blocks/IconsBlocks/Edit
 */

import React, { useState, useEffect } from 'react';
import EditBlock from './Block/EditBlock';
import { Input, Grid } from 'semantic-ui-react';
import { useIntl, defineMessages } from 'react-intl';
import { flattenToAppURL } from '@plone/volto/helpers';
import { SidebarPortal } from '@plone/volto/components';
import { v4 as uuid } from 'uuid';
import Sidebar from './Sidebar';

const messages = defineMessages({
  mainTitlePlaceholder: {
    id: 'Title placeholder',
    defaultMessage: 'Titolo del blocco...',
  },
});

const Edit = (props) => {
  const { data, selected, block, onChangeBlock, onChange } = props;
  const [focusOn, setFocusOn] = useState('title-block');
  const intl = useIntl();

  useEffect(() => {
    if (!data?.columns || data?.columns?.length === 0) {
      onChangeBlock(block, {
        ...data,
        columns: [{ '@id': uuid() }],
      });
    }
  }, [block]);

  const focusField = (field) => {
    setFocusOn(field);

    if (!selected) {
      props.onSelectBlock(block);
    }
  };

  if (__SERVER__) {
    return <div />;
  }

  return (
    <div className="block iconsBlock">
      <div className="title-wrapper">
        <div className="title-block">
          <Input
            fluid
            transparent
            placeholder={intl.formatMessage(messages.mainTitlePlaceholder)}
            value={data.main_title || ''}
            name="main_title"
            onChange={(e) =>
              onChangeBlock(block, {
                ...data,
                main_title: e.target.value,
              })
            }
          />
        </div>
      </div>
      <Grid padded className="iconsBlock-wrapper">
        {data.url && (
          <div
            className="background"
            style={{
              backgroundImage: `url(${flattenToAppURL(
                data.url,
              )}/@@images/image)`,
            }}
          ></div>
        )}

        <Grid.Row
          className="columns-wrapper"
          columns={data?.columns?.length > 2 ? 3 : 2}
        >
          {data?.columns?.length > 0 &&
            data?.columns?.map((column, i) => (
              <div className="single-column" key={column['@id']}>
                <EditBlock
                  data={column}
                  onChange={(index, field, value) => {
                    let newColumns = [...data.columns];
                    newColumns[index][field] = value;
                    onChangeBlock(block, {
                      ...data,
                      columns: [...newColumns],
                    });
                  }}
                  setFocusOn={focusField}
                  index={i}
                  selected={selected}
                  key={column['@id']}
                />
              </div>
            ))}
        </Grid.Row>

        <SidebarPortal selected={selected}>
          <Sidebar {...props} />
        </SidebarPortal>
      </Grid>
    </div>
  );
};

export default Edit;
