/* 
Customizations:
- Added tabIndex -1 to the UniversalLink with image 
*/

import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { UniversalLink } from '@plone/volto/components';

import { ReadMore, ExternalSource, PreviewImage } from '@package/components';
import { EventWhen } from '@package/components/Blocks/Listing/Common/EventWhen';

const DefaultTemplate = ({ items, linkHref, linkTitle, isEditMode, title }) => {
  const linkMore =
    linkHref?.length > 0
      ? { href: linkHref[0]['@id'], title: linkTitle }
      : null;

  return items.length > 0 ? (
    <div className="default-template">
      {title && <h2 className="text-center">{title}</h2>}
      <Grid stackable columns={3}>
        {items.map((item) => (
          <Grid.Column key={item['@id']}>
            <div className="listing-item">
              <UniversalLink
                item={!isEditMode ? item : null}
                href={isEditMode ? '#' : null}
                tabIndex="-1"
              >
                <PreviewImage item={item} />
              </UniversalLink>

              <div className="item-title">
                {item['@type'] === 'Event' && item.start && (
                  <div className="item-info">
                    <EventWhen
                      start={item.start}
                      end={item.end}
                      whole_day={item.whole_day}
                      open_end={item.open_end}
                      show_times={false}
                    />
                  </div>
                )}
                <UniversalLink
                  item={!isEditMode ? item : null}
                  href={isEditMode ? '#' : null}
                >
                  {item.title ? item.title : item.id}
                </UniversalLink>
              </div>
              {item.description && <p>{item.description}</p>}
              <ExternalSource item={item} />
            </div>
          </Grid.Column>
        ))}
      </Grid>

      <ReadMore linkMore={linkMore} isEditMode={isEditMode} />
    </div>
  ) : null;
};

DefaultTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default DefaultTemplate;
