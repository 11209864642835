import { defineMessages, useIntl } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

import { toPublicURL } from '@plone/volto/helpers';

/**
 * Sharing view component class.
 * @function Sharing
 * @params {object} content: Content object.
 * @returns {string} Markup of the component.
 */

const messages = defineMessages({
  share: {
    id: 'share',
    defaultMessage: 'Condividi',
  },
});

const Sharing = ({ url, title }) => {
  const intl = useIntl();
  const publicUrl = toPublicURL(url);

  let socials = [
    {
      id: 'facebook',
      title: 'Facebook',
      url: `https://www.facebook.com/sharer/sharer.php?u=${publicUrl}`,
      icon: 'facebook',
    },
    {
      id: 'twitter',
      title: 'Twitter',
      url: `https://twitter.com/intent/tweet?url=${publicUrl}`,
      icon: 'twitter',
    },
    {
      id: 'linkedin',
      title: 'LinkedIn',
      url: `https://www.linkedin.com/shareArticle?mini=true&url=${publicUrl}&title=${publicUrl}`,
      icon: 'linkedin',
    },
    {
      id: 'whatsapp',
      title: 'Whatsapp',
      url: `https://api.whatsapp.com/send?phone=&text=${publicUrl}`,
      icon: 'whatsapp',
    },
    {
      id: 'telegram',
      title: 'Telegram',
      url: `https://t.me/share/url?url=${publicUrl}&text=${title}`,
      icon: 'telegram',
    },
  ];

  return (
    <Dropdown
      text={intl.formatMessage(messages.share)}
      className="page-sharing"
    >
      <Dropdown.Menu>
        {socials.map((item, i) => (
          <Dropdown.Item
            as="a"
            href={item.url}
            key={item.id}
            target="_blank"
            rel="noopener noreferrer"
            icon={item.icon}
            text={item.title}
          />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default Sharing;

Sharing.propTypes = {
  params: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
  }),
};
