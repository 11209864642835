import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { Message } from 'semantic-ui-react';
import { searchContent, resetSearchContent } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';

import LinkTemplate from '@package/components/Blocks/Listing/LinkTemplate';

const messages = defineMessages({
  title: {
    id: 'artistLinks_title',
    defaultMessage: "Link alle opere dell'artista",
  },
});
const Body = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { properties, isEditMode } = props;
  const folder_name = 'allegati-e-link';
  const url = `${flattenToAppURL(properties['@id'])}/${folder_name}`;
  const searchResults = useSelector((state) => state.search.subrequests);
  const links = searchResults?.[folder_name]?.items || [];

  useEffect(() => {
    dispatch(
      searchContent(
        url,
        {
          'path.depth': 1,
          sort_on: 'getObjPositionInParent',
          metadata_fields: '_all',
          fullobjects: 1,
        },
        folder_name,
      ),
    );

    return () => {
      dispatch(resetSearchContent(folder_name));
    };
  }, [dispatch, folder_name, url]);

  return links?.length > 0 ? (
    <LinkTemplate items={links} title={intl.formatMessage(messages.title)} />
  ) : isEditMode ? (
    <Message>
      <center>
        <div className="message-text">
          Allegati e Link artista: Nessun elemento nella cartella 'Allegati e
          Link'
        </div>
      </center>
    </Message>
  ) : null;
};

export default Body;
