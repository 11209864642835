/**
 * Header component.
 * @module components/theme/Header/Header
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'semantic-ui-react';
import {
  HeaderSmall,
  HeaderSmallSubsite,
  RightHeaderSubsite,
  HomeHeaderSubsite,
} from '@package/components/layout';
import { Logo, Navigation } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { defineMessages, useIntl } from 'react-intl';
import { UniversalLink } from '@plone/volto/components';

const messages = defineMessages({
  logoSubsiteAlt: {
    id: 'logo-subsite-alt',
    defaultMessage: 'Logo',
  },
  subsiteUniversalLink: {
    id: 'link-subsite-homepage-title',
    defaultMessage: 'homepage',
  },
});

const HeaderDesktop = ({ pathname }) => {
  const intl = useIntl();
  const subsite = useSelector((state) => state.subsite?.data);
  const logoSubsite = subsite?.subsite_logo && (
    <figure className="icon">
      <img
        src={flattenToAppURL(subsite.subsite_logo.scales?.mini?.download)}
        alt={intl.formatMessage(messages.logoSubsiteAlt)}
      />
    </figure>
  );
  const isSubsite = subsite?.['@id']?.length > 0 ? true : false;
  return (
    <div className="header-desktop">
      {isSubsite ? (
        <HeaderSmallSubsite pathname={pathname} />
      ) : (
        <HeaderSmall pathname={pathname} />
      )}

      <div className="header-center">
        <Container>
          <UniversalLink
            href={subsite?.['@id'] ? flattenToAppURL(subsite['@id']) : '/'}
            title={intl.formatMessage(messages.subsiteUniversalLink)}
          >
            {subsite?.subsite_logo ? logoSubsite : <Logo />}
          </UniversalLink>
          <RightHeaderSubsite pathname={pathname} />
        </Container>
      </div>
      <Container>
        <div className="logo-nav-wrapper">
          <Navigation pathname={pathname} />
        </div>
      </Container>

      <HomeHeaderSubsite />
    </div>
  );
};
export default HeaderDesktop;
