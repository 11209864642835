import React from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'semantic-ui-react';
import { flattenHTMLToAppURL } from '@plone/volto/helpers';

const FooterSubsite = () => {
  const subsite = useSelector((state) => state.subsite?.data);

  return subsite?.subsite_footer?.data ? (
    <div className="footer-subsite">
      <div className="text">
        <Container>
          <div
            dangerouslySetInnerHTML={{
              __html: flattenHTMLToAppURL(subsite.subsite_footer.data),
            }}
          />
        </Container>
      </div>
    </div>
  ) : null;
};

export default FooterSubsite;
