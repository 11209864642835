import { omit } from 'lodash';
import { GET_YC_API } from '@package/actions';

const RESET_YC_API_RESULTS = 'RESET_YC_API_RESULTS';

const initialState = {
  error: null,
  items: [],
  total: 0,
  loaded: false,
  loading: false,
  batching: {},
  subrequests: {},
};

export function YCApiReducer(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_YC_API}_PENDING`:
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                ...(state.subrequests[action.subrequest] || {
                  result: null,
                }),
                error: null,
                loaded: false,
                loading: true,
              },
            },
          }
        : {
            ...state,
            error: null,
            loading: true,
            loaded: false,
          };
    case `${GET_YC_API}_SUCCESS`:
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                error: null,
                result: action.result,
                loaded: true,
                loading: false,
              },
            },
          }
        : {
            ...state,
            error: null,
            result: action.result,
            loaded: true,
            loading: false,
          };
    case `${GET_YC_API}_FAIL`:
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                error: action.error,
                result: null,
                loading: false,
                loaded: false,
              },
            },
          }
        : {
            ...state,
            error: action.error,
            result: null,
            loading: false,
            loaded: false,
          };
    case GET_YC_API:
      return action.subrequest
        ? {
            ...state,
            subrequests: omit(state.subrequests, [action.subrequest]),
          }
        : {
            ...state,
            error: null,
            result: null,
            loading: false,
            loaded: false,
          };
    default:
      return state;
  }
}
