import { defineMessages } from 'react-intl';
import { addSchemaField } from '@package/config/Blocks/ListingOptions/utils';

const messages = defineMessages({
  full_width: {
    id: 'Full width',
    defaultMessage: 'Full width',
  },
});

/** DEFAULT **/
const addSquaresTemplateOptions = (schema, formData, intl, position = 0) => {
  let pos = position;

  addSchemaField(
    schema,
    'full_width',
    intl.formatMessage(messages.full_width),
    null,
    { type: 'boolean', default: false },
    pos,
  );
  pos++;

  return pos;
};

export default addSquaresTemplateOptions;
