/**
 * Header component.
 * @module components/theme/Header/Header
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { HeaderDesktop, HeaderMobile } from '@package/components/layout';

/**
 * Header component class.
 * @class Header
 * @extends Component
 */
class Header extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    pathname: PropTypes.string.isRequired,
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <div className="header-wrapper" role="banner">
        <HeaderDesktop pathname={this.props.pathname} />
        <HeaderMobile pathname={this.props.pathname} />
      </div>
    );
  }
}

export default Header;
