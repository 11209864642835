import React from 'react';
import PropTypes from 'prop-types';
import Body from './Body';

const View = (props) => {
  const content = Body(props);
  return content ? <div className="block artistGallery">{content}</div> : null;
};

View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  properties: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
