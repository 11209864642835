import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { getYCApi } from '@package/actions';
import { useIntl, defineMessages } from 'react-intl';
import { Field } from 'volto-form-block';

const messages = defineMessages({
  privacy_accept: {
    id: 'privacy_accept',
    defaultMessage: 'Accetto i termini della privacy',
  },
});
export const isValid = (formData, name) => {
  return formData[name]?.value === true;
};

const NewsletterPrivacyWidget = (props) => {
  const intl = useIntl();
  const { invalid } = props;
  const dispatch = useDispatch();

  const valid =
    invalid === null ||
    invalid === undefined ||
    invalid === 'false' ||
    invalid === false;

  const privacy = useSelector(
    (state) => state.YCApi.subrequests?.['widget' + 'privacy']?.result,
  );

  useEffect(() => {
    dispatch(getYCApi('widget', 'privacy', { recipient: 6 }));
  }, [dispatch]);

  return (
    <div className={`${valid ? 'valid' : 'invalid'}`}>
      <fieldset>
        <div className="inline required field newsletter-privacy">
          {privacy && (
            <legend>
              <Grid className="privacy-wrapper">
                <Grid.Row stretched>
                  <Grid.Column>
                    <h4>{privacy.PV01_DESCRIPTION}</h4>
                    <div className="short-description">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: privacy.PV01_SHORT_TEXT,
                        }}
                      />
                    </div>
                    <div className="privacy-long-text">
                      <div
                        dangerouslySetInnerHTML={{ __html: privacy.PV01_TEXT }}
                      />
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </legend>
          )}
        </div>

        <Field
          {...props}
          field_type="checkbox"
          label={props.label ?? intl.formatMessage(messages.privacy_accept)}
          valid={valid}
        />
      </fieldset>
    </div>
  );
};

export default NewsletterPrivacyWidget;
