/**
 * View links block.
 * @module components/manage/Blocks/LinksBlock/View
 */

import React from 'react';
import { Grid } from 'semantic-ui-react';
import ViewBlock from './Block/ViewBlock';

const View = ({ data }) => {
  return (
    <div className="block linksBlock">
      <Grid>
        {data.main_title && <h2 className="title-block">{data.main_title}</h2>}
        {data?.columns?.length > 0 && (
          <Grid.Row columns={data.columns.length > 3 ? 4 : data.columns.length}>
            {data.columns.map((column, i) => (
              <ViewBlock data={column} key={i} nColumns={data.columns.length} />
            ))}
          </Grid.Row>
        )}
      </Grid>
    </div>
  );
};

export default View;
