import React from 'react';
import moment from 'moment';
import { useIntl, defineMessages } from 'react-intl';
import { datesForDisplay } from '@package/helpers';
//import { parseDateTime } from '@plone/volto/helpers';

const messages = defineMessages({
  dal: {
    id: 'dal_data',
    defaultMessage: 'Dal',
  },
  al: {
    id: 'al_data',
    defaultMessage: 'al',
  },
  dalle_ore: {
    id: 'dalle_ore_small',
    defaultMessage: 'dalle',
  },
  alle_ore: {
    id: 'alle_ore',
    defaultMessage: 'alle',
  },
  il_giorno: {
    id: 'il_giorno',
    defaultMessage: 'Il',
  },
  excluded_dates: { id: 'tranne_il', defaultMessage: 'tranne il' },
});

const ListingDates = ({ item }) => {
  const intl = useIntl();
  moment.locale(intl.locale);

  if (item['@type'] === 'Event') {
    const datesInfo = datesForDisplay(
      item.start,
      item.end,
      item.recurrence,
      intl,
      moment,
    );
    return (
      <div className="dates">
        {/* Dove */}
        {item.location && (
          <span className="event-where">{item.location} - </span>
        )}
        {/* Quando */}
        {!datesInfo.sameDay ? (
          <>
            {intl.formatMessage(messages.dal)} {datesInfo.startDate}
            {!item.open_end && (
              <>
                &nbsp;{intl.formatMessage(messages.al)}&nbsp;
                {datesInfo.endDate}
              </>
            )}
          </>
        ) : (
          <>
            {intl.formatMessage(messages.il_giorno)} {datesInfo.startDate}
          </>
        )}
        {/* {datesInfo.eventRecurrenceText && (
          <div className="recurrence">
            {datesInfo.eventRecurrenceText}
            {datesInfo.rruleSet?.exdates().length > 0 && (
              <div>
                {intl.formatMessage(messages.excluded_dates)}{' '}
                {datesInfo.rruleSet.exdates().map((exDate, index) => {
                  return (
                    <>
                      {parseDateTime(
                        intl.locale,
                        exDate.toISOString(),
                        'DD.MM.YYYY',
                      )}
                      {index < datesInfo.rruleSet.exdates().length - 1
                        ? ', '
                        : ''}
                    </>
                  );
                })}
              </div>
            )}
          </div>
        )} */}
        &nbsp;
        {/* Orari */}
        {!item.whole_day && (
          <span className="event-times">
            {intl.formatMessage(messages.dalle_ore)} {datesInfo.startTime}
            {!item.open_end && (
              <>
                &nbsp;{intl.formatMessage(messages.alle_ore)}{' '}
                {datesInfo.endTime}
              </>
            )}
          </span>
        )}
      </div>
    );
  }
  return item.effective ? (
    <div className="dates">{moment(item.effective).format('DD/MM/YYYY')}</div>
  ) : (
    <></>
  );
};

export default ListingDates;
