import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { UniversalLink } from '@plone/volto/components';

import { flattenToAppURL } from '@plone/volto/helpers';
import { getItemsByPath } from 'volto-dropdownmenu';

import { ReadMore, ExternalSource, PreviewImage } from '@package/components';
import config from '@plone/volto/registry';

const getMenuItem = (items = [], pathname) => {
  let menuItem = null;
  items?.map((item) => {
    const paths = [...(item.navigationRoot ?? [])];
    if (item.showMoreLink?.length > 0) {
      paths.push(item.showMoreLink[0]);
    }

    let active = paths.reduce(
      (acc, path) =>
        acc ||
        flattenToAppURL(pathname).indexOf(flattenToAppURL(path['@id'])) > -1,
      false,
    );

    if (active) {
      menuItem = item;
    }
  });
  return menuItem;
};

const SquaresTemplate = ({
  items,
  linkTitle,
  linkHref,
  isEditMode,
  title,
  full_width,
}) => {
  const { settings } = config;
  const location = useLocation();
  const [menu, setMenu] = useState(null);
  const [additionalItemsClasses, setAdditionalItemsClasses] = useState({});
  const dropdownMenuNavItems = useSelector(
    (state) => state.dropdownMenuNavItems?.result,
  );

  useEffect(() => {
    setMenu(getItemsByPath(dropdownMenuNavItems, location.pathname));
  }, [dropdownMenuNavItems]);

  useEffect(() => {
    let additionalClasses = {};
    if (menu && items) {
      items.map((item) => {
        const menuItem = getMenuItem(menu, item['@id']);
        if (menuItem) {
          additionalClasses[item['@id']] = menuItem.additionalClasses;
        }
      });
    }
    setAdditionalItemsClasses(additionalClasses);
  }, [menu, items, location]);

  const linkMore =
    linkHref?.length > 0
      ? { href: linkHref[0]['@id'], title: linkTitle }
      : null;

  return items.length > 0 ? (
    <div className={cx('squares-template', { 'full-width': full_width })}>
      {title && <h2 className="text-center">{title}</h2>}
      <Grid stretched>
        {items.map((item) => (
          <Grid.Column key={item['@id']} mobile={6} tablet={4} computer={2}>
            <UniversalLink
              item={!isEditMode ? item : null}
              href={isEditMode ? '#' : null}
            >
              <div
                className={cx('listing-item', {
                  [additionalItemsClasses[item['@id']]]:
                    additionalItemsClasses[item['@id']],
                })}
              >
                <div className="item-content">
                  <PreviewImage item={item} />
                  <div className="title">
                    {item.title ? item.title : item.id}
                  </div>
                  <ExternalSource item={item} />
                </div>
              </div>
            </UniversalLink>
          </Grid.Column>
        ))}
      </Grid>

      <ReadMore linkMore={linkMore} isEditMode={isEditMode} />
    </div>
  ) : null;
};
SquaresTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};
export default SquaresTemplate;
