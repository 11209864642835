/**
 * ParentSiteMenu component.
 * @module components/ItaliaTheme/Header/ParentSiteMenu
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { Menu } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import { UniversalLink } from '@plone/volto/components';

const messages = defineMessages({
  secondarymenu_aria: {
    id: 'secondarymenu-menu-arialabel',
    defaultMessage: 'GiovaZoom menu',
  },
});

const ParentSiteMenu = () => {
  const intl = useIntl();
  const dropdownMenu = useSelector(
    (state) => state.dropdownMenuNavItems?.result,
  );
  const subsite = useSelector((state) => state.subsite?.data);

  let menu = null;
  if (subsite) {
    const subsiteUrl = flattenToAppURL(subsite?.['@id'] ?? '');
    const url_split = subsiteUrl.split('/');

    let i = url_split.length - 1;
    while (i > 0) {
      let s = url_split.slice(0, i).join('/');
      s = s.length === 0 ? '/' : s;
      dropdownMenu.map((m) => {
        if (m.rootPath === s) {
          menu = m;
          i = 0;
        }
      });
      i--;
    }
  }

  return subsite && menu ? (
    <nav
      className="secondary-menu"
      role="navigation"
      aria-label={intl.formatMessage(messages.secondarymenu_aria)}
    >
      <Menu stackable pointing secondary>
        {menu.items.map((item, id) => (
          <UniversalLink
            href={flattenToAppURL(
              item.linkUrl?.[0]?.['@id'] ||
                item.showMoreLink?.[0]?.['@id'] ||
                item.navigationRoot?.[0]?.['@id'] ||
                '',
            )}
            key={id}
            className="item"
          >
            <span className={item.inEvidence ? 'in-evidence' : ''}>
              {item.title}
            </span>
          </UniversalLink>
        ))}
      </Menu>
    </nav>
  ) : null;
};

export default ParentSiteMenu;
