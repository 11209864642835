import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid } from 'semantic-ui-react';
// import CountUp from 'react-countup';
// import VisibilitySensor from 'react-visibility-sensor';

import { Icon } from '@plone/volto/components';
import { ReadMore } from '@package/components';

import handSpockSVG from '@package/icons/hand-spock-solid.svg';
import starshipFreighterSVG from '@package/icons/starship-freighter-solid.svg';
import ufoBeamSVG from '@package/icons/ufo-beam-solid.svg';
import userAustronautSVG from '@package/icons/user-astronaut-solid.svg';
import { getYCApi } from '@package/actions';

const Body = (props) => {
  const { data, isEditMode } = props;
  const dispatch = useDispatch();
  const icons = {
    'starship-freighter': starshipFreighterSVG,
    'hand-spock': handSpockSVG,
    'user-austronaut': userAustronautSVG,
    'ufo-beam': ufoBeamSVG,
  };

  const giovani_iscritti = useSelector(
    (state) =>
      state.YCApi.subrequests?.[props.id + 'statistiche']?.result
        ?.statistiche?.[0]?.statistica?.valore,
  );

  useEffect(() => {
    dispatch(getYCApi(props.id, 'statistiche'));
  }, [dispatch]);

  return (
    <div className="bg-wrapper full-width">
      <Container>
        {data.title && <h2 className="text-center">{data.title}</h2>}
        <Grid columns="equal">
          <Grid.Row stretched>
            {data.items?.map((item) => (
              <Grid.Column mobile={6} tablet={6} computer={3} key={item.id}>
                <div className="item">
                  {(item.number ||
                    item.description ||
                    item.id === 'giovani_iscritti') && (
                    <div className="top">
                      <div className="number">
                        {item.id === 'giovani_iscritti'
                          ? giovani_iscritti
                          : item.number}
                        {/* <CountUp
                          start={0}
                          end={item.number ? parseInt(item.number) : 1}
                          duration={2}
                          redraw={true}
                        >
                          {({ countUpRef, start }) => {
                            return (
                              <div>
                                <span ref={countUpRef} />
                                <VisibilitySensor
                                  onChange={(isVisible) => {
                                    if (isVisible) {
                                      start();
                                    }
                                  }}
                                >
                                  <span className="visibility-sensor">+</span>
                                </VisibilitySensor>
                              </div>
                            );
                          }}
                        </CountUp> */}
                      </div>

                      {item.number_description && (
                        <div className="description">
                          {item.number_description}
                        </div>
                      )}
                    </div>
                  )}
                  {(item.icon || item.title || item.title_mini) && (
                    <div className="bottom">
                      {item.icon && (
                        <div className="icon-wrapper">
                          <Icon name={icons[item.icon]} size="40px" />
                        </div>
                      )}
                      {item.title && <div className="title">{item.title}</div>}
                      {item.title_mini && (
                        <div className="title_mini">{item.title_mini}</div>
                      )}
                    </div>
                  )}
                </div>
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>

        <ReadMore
          linkMore={data.linkMore}
          isEditMode={isEditMode}
          color="white"
        />
      </Container>
    </div>
  );
};

export default Body;
