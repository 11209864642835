/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { Input, Grid } from 'semantic-ui-react';
import { Image } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { TextEditorWidget, ReadMore } from '@package/components';
import cx from 'classnames';

const messages = defineMessages({
  titlePlaceholder: {
    id: 'Title placeholder',
    defaultMessage: 'Titolo...',
  },
  textPlaceholder: {
    id: 'Text placeholder',
    defaultMessage: 'Inserisci il testo...',
  },
});

const EditBlock = ({
  data,
  column,
  index,
  focusOn,
  setFocusOn,
  onChange,
  selected,
}) => {
  const intl = useIntl();
  const icon = column.url;

  const linkMore =
    column?.href?.length > 0
      ? { href: column.href[0]['@id'], title: column.href_title }
      : null;

  return __SERVER__ ? (
    <div />
  ) : (
    <Grid.Column
      computer={data.column_number}
      tablet={data.column_number}
      mobile={12}
      className="column-block"
    >
      <div className="column-title title">
        <Input
          fluid
          transparent
          placeholder={intl.formatMessage(messages.titlePlaceholder)}
          value={column.title || ''}
          name={'title' + index}
          onClick={(e) => {
            setFocusOn('title' + index);
            e.stopPropagation();
          }}
          onChange={(e) => onChange(index, 'title', e.target.value)}
        />
      </div>
      {icon && (
        <div className="column-image">
          <Image
            className={cx({
              ['image-' + data.sizeImage]: data.sizeImage,
            })}
            src={flattenToAppURL(icon) + '/@@images/image/teaser'}
            alt=""
            role="presentation"
            aria-hidden="true"
          />
        </div>
      )}
      <div className="column-text">
        <div
          onClick={(e) => setFocusOn('text' + index)}
          onKeyDown={() => setFocusOn('text' + index)}
        >
          <TextEditorWidget
            data={column}
            fieldName={'text'}
            selected={selected && focusOn === 'text' + index}
            onChangeBlock={(v) => {
              onChange(index, 'text', v.text);
            }}
            placeholder={intl.formatMessage(messages.textPlaceholder)}
            prevFocus={'title' + index}
            setFocus={(f) => setFocusOn(f)}
            showToolbar={true}
            key={'text' + index}
          />
        </div>
      </div>
      <ReadMore
        linkMore={linkMore}
        isEditMode={true}
        color={data.bg_color !== 'bg-secondary' ? 'black' : 'grey'}
      />
    </Grid.Column>
  );
};

export default React.memo(EditBlock);
