/**
 * Header component.
 * @module components/theme/Header/Header
 */

import React, { useState, useEffect } from 'react';
import { Container } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { Logo, Navigation } from '@plone/volto/components';
import { SocialLinks } from 'volto-social-settings';
import { SecondaryMenu } from 'volto-secondarymenu';
import { HeaderSmallMobile, ParentSiteMenu } from '@package/components/layout';
import { flattenToAppURL } from '@plone/volto/helpers';

const messages = defineMessages({
  closeMobileMenu: {
    id: 'Close menu',
    defaultMessage: 'Close menu',
  },
  openMobileMenu: {
    id: 'Open menu',
    defaultMessage: 'Open menu',
  },
  logoSubsiteAlt: {
    id: 'logo-subsite-alt',
    defaultMessage: 'Logo',
  },
  subsiteUniversalLink: {
    id: 'link-subsite-homepage-title',
    defaultMessage: 'homepage',
  },
});

const HeaderMobile = ({ pathname }) => {
  const intl = useIntl();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const subsite = useSelector((state) => state.subsite?.data);
  const logoSubsite = subsite?.subsite_logo && (
    <figure className="icon">
      <img
        src={flattenToAppURL(subsite.subsite_logo.scales?.mini?.download)}
        alt={intl.formatMessage(messages.logoSubsiteAlt)}
      />
    </figure>
  );

  const getAnchorTarget = (nodeElement) => {
    if (nodeElement.nodeName === 'A') {
      return nodeElement;
    } else if (nodeElement.parentElement?.nodeName === 'A') {
      return nodeElement.parentElement;
    } else {
      return null;
    }
  };

  useEffect(() => {
    const clickListener = (e) => {
      const targetItem = getAnchorTarget(e.target);
      const dropdownmenuLinks = [
        ...document.querySelectorAll(
          '.mobile-menu .navigation-dropdownmenu .dropdown-menu-wrapper ul li a, .mobile-menu .navigation-dropdownmenu .dropdownmenu-blocks-column a, .mobile-menu .dropdownmenu-footer a, .mobile-menu .navigation-dropdownmenu .menu > a, .mobile-menu .secondary-menu .menu > a',
        ),
      ];

      if (
        dropdownmenuLinks?.length > 0 &&
        dropdownmenuLinks?.indexOf(targetItem) >= 0
      ) {
        setIsMobileMenuOpen(false); //close mobile menu
      }
    };

    document.body.addEventListener('click', clickListener, { passive: true });

    return () => document.body.removeEventListener('click', clickListener);
  }, []);

  const Hamburger = (
    <div className="hamburger-wrapper">
      <button
        className={cx('hamburger hamburger--collapse', {
          'is-active': isMobileMenuOpen,
        })}
        aria-label={
          isMobileMenuOpen
            ? intl.formatMessage(messages.closeMobileMenu)
            : intl.formatMessage(messages.openMobileMenu)
        }
        title={
          isMobileMenuOpen
            ? intl.formatMessage(messages.closeMobileMenu)
            : intl.formatMessage(messages.openMobileMenu)
        }
        type="button"
        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
      >
        <span className="hamburger-box">
          <span className="hamburger-inner" />
        </span>
      </button>
    </div>
  );
  return (
    <div className="header-mobile">
      <HeaderSmallMobile pathname={pathname} />

      <div className="header-center">
        <Container>
          <div className="logo-content-wrapper">
            {subsite?.subsite_logo ? logoSubsite : <Logo mobile={true} />}
            {Hamburger}
          </div>
        </Container>
      </div>

      <div className={`mobile-menu ${isMobileMenuOpen ? 'open' : ''}`}>
        <div className="mobile-menu-container">
          {Hamburger}
          {subsite?.subsite_logo ? (
            logoSubsite
          ) : (
            <Logo mobile={true} menu={true} />
          )}
          <SocialLinks />
          <div className="mobile-menu-navigation">
            <Navigation pathname={pathname} />
            {subsite?.['@id'] ? (
              <ParentSiteMenu pathname={pathname} />
            ) : (
              <SecondaryMenu pathname={pathname} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default HeaderMobile;
