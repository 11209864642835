/**
 * Routes.
 * @module routes
 */

import { Sitemap, Search } from '@plone/volto/components';
import routes from '@plone/volto/routes';
import YCProgettoView from '@package/components/View/YCProgettoView';
import YCConvenzioneView from '@package/components/View/YCConvenzioneView';
import YCSpazioServizioView from '@package/components/View/YCSpazioServizioView';
import YCPuntoDistribuzioneView from '@package/components/View/YCPuntoDistribuzioneView';

/**
 * Routes array.
 * @array
 * @returns {array} Routes.
 */
const giovazoomRoutes = routes;

giovazoomRoutes[0].routes = [
  {
    path: '/**/sitemap',
    component: Sitemap,
  },
  {
    path: '/**/search',
    component: Search,
  },
  {
    path: '/**/youngercard/maps/progetto/*',
    component: YCProgettoView,
  },
  {
    path: '/**/youngercard/maps/convenzione/*',
    component: YCConvenzioneView,
  },
  {
    path: '/**/youngercard/maps/spazio o servizio/*',
    component: YCSpazioServizioView,
  },
  {
    path: '/**/youngercard/maps/punto di distribuzione/*',
    component: YCPuntoDistribuzioneView,
  },
  ...routes[0].routes,
];
export default giovazoomRoutes;
