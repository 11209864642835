import React, { useState, useEffect, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';

import { FormView, getFieldName } from 'volto-form-block';

import { getYCApi } from '@package/actions';
import config from '@plone/volto/registry';

const messages = defineMessages({
  email: {
    id: 'newsletter-subscription-email',
    defaultMessage: 'E-mail',
  },
  channels: {
    id: 'newsletter-subscription-channels',
    defaultMessage: 'A quali canali vuoi iscriverti?',
  },
  channels_description: {
    id: 'newsletter-subscription-channels-description',
    defaultMessage: 'Seleziona i canali ai quali vuoi iscriverti',
  },
  submit_label: {
    id: 'newsletter-subscription-submit',
    defaultMessage: 'Iscriviti!',
  },
  formSubmitted: {
    id: 'formSubmitted',
    defaultMessage: 'Form successfully submitted',
  },
  privacy: {
    id: 'privacy',
    defaultMessage: 'Privacy',
  },
});

const initialState = {
  loading: false,
  error: null,
  result: null,
};

const FORM_STATES = {
  normal: 'normal',
  loading: 'loading',
  error: 'error',
  success: 'success',
};

const formStateReducer = (state, action) => {
  switch (action.type) {
    case FORM_STATES.normal:
      return initialState;

    case FORM_STATES.loading:
      return { loading: true, error: null, result: null };

    case FORM_STATES.error:
      return { loading: false, error: action.error, result: null };

    case FORM_STATES.success:
      return { loading: false, error: null, result: action.result };

    default:
      return initialState;
  }
};

const getInitialData = (data) => ({
  ...data.reduce(
    (acc, field) => ({ ...acc, [getFieldName(field.label, field.id)]: field }),
    {},
  ),
});

/**
 * View title block class.
 * @class View
 * @extends Component
 */
const Body = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const data = {
    subblocks: [
      {
        field_id: 'mail',
        field_type: 'from',
        id: 'mail',
        input_values: null,
        label: 'E-mail',
        required: true,
      },
      {
        field_id: 'newsletter',
        field_type: 'newsletter-channels',
        id: 'newsletter',
        input_values: null,
        label: intl.formatMessage(messages.channels),
        required: true,
      },
      {
        field_id: 'privacy',
        field_type: 'newsletter-privacy',
        id: 'privacy',
        input_values: null,
        label: intl.formatMessage(messages.privacy),
        required: true,
      },
    ],
  };

  const { static_fields = [] } = data;
  const [formState, setFormState] = useReducer(formStateReducer, initialState);
  const [formErrors, setFormErrors] = useState([]);
  const submitResults = useSelector(
    (state) => state.YCApi.subrequests?.newsletters_add,
  );

  const [formData, setFormData] = useReducer((state, action) => {
    if (action.reset) {
      return getInitialData(static_fields);
    }

    return {
      ...state,
      [action.field]: action.value,
    };
  }, getInitialData(static_fields));

  const onChangeFormData = (field_id, field, value, label) => {
    setFormData({ field, value: { field_id, value, label } });
  };

  useEffect(() => {
    if (formErrors.length > 0) {
      isValidForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const isValidForm = () => {
    let v = [];
    data.subblocks.forEach((subblock, index) => {
      let name = getFieldName(subblock.label, subblock.id);
      let fieldType = subblock.field_type;

      let additionalField =
        config.blocks.blocksConfig.form.additionalFields?.filter(
          (f) => f.id === fieldType && f.isValid !== undefined,
        )?.[0] ?? null;
      if (
        subblock.required &&
        additionalField &&
        !additionalField?.isValid(formData, name)
      ) {
        v.push(name);
      } else if (
        subblock.required &&
        fieldType === 'checkbox' &&
        !formData[name]?.value
      ) {
        v.push(name);
      } else if (
        subblock.required &&
        (!formData[name] ||
          formData[name]?.value?.length === 0 ||
          JSON.stringify(formData[name]?.value ?? {}) === '{}')
      ) {
        v.push(name);
      }
    });

    setFormErrors(v);
    return v.length === 0;
  };

  const submit = (e) => {
    e.preventDefault();

    if (isValidForm()) {
      let paramsObj = {};
      Object.keys(formData).forEach((name) => {
        paramsObj[formData[name].field_id] = formData[name].value;
      });

      dispatch(
        getYCApi('', 'newsletters_add', {
          mail: paramsObj.mail,
          newsletter: paramsObj.newsletter.join(','),
        }),
      );
      setFormState({ type: FORM_STATES.loading });
    } else {
      setFormState({ type: FORM_STATES.error });
    }
  };

  const resetFormState = () => {
    setFormData({ reset: true });
    setFormState({ type: FORM_STATES.normal });
  };

  useEffect(() => {
    if (submitResults?.loaded) {
      setFormState({
        type: FORM_STATES.success,
        result: intl.formatMessage(messages.formSubmitted),
      });
    } else if (submitResults?.error) {
      let errorDescription = `${submitResults.error.status} ${
        submitResults.error.message
      } - ${JSON.parse(submitResults.error.response?.text ?? '{}')?.message}`;

      setFormState({ type: FORM_STATES.error, error: errorDescription });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitResults]);

  useEffect(() => {
    resetFormState();
  }, []);

  return (
    <div className="block form newsletter-subscription">
      <FormView
        formState={formState}
        formErrors={formErrors}
        formData={formData}
        onChangeFormData={onChangeFormData}
        data={data}
        onSubmit={submit}
        resetFormState={resetFormState}
      />
    </div>
  );
};

export default Body;
