import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TextWidget, ObjectListWidget } from '@plone/volto/components';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  backgroundColorSingleCard: {
    id: 'Background color card',
    defaultMessage: 'Colore di sfondo',
  },
});

const Sidebar = (props) => {
  const { data, onChangeBlock, block } = props;
  const intl = useIntl();

  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage id="LinksBlock" defaultMessage="Blocco link" />:
        </h2>
      </header>

      <Segment className="form">
        <TextWidget
          id="main_title"
          title="Titolo del blocco"
          value={data.main_title ?? ''}
          onChange={(name, value) => {
            onChangeBlock(block, { ...data, [name]: value });
          }}
        />
        <ObjectListWidget
          {...props}
          id="columns"
          value={data?.columns ?? []}
          onChange={(id, value) =>
            onChangeBlock(block, { ...data, [id]: value })
          }
          schema={() => {
            const columnsSchema = {
              title: 'Card',
              addMessage: 'Aggiungi una card',
              fieldsets: [
                {
                  id: 'default',
                  title: 'Default',
                  fields: ['bg_color_card', 'href'],
                },
              ],
              properties: {
                bg_color_card: {
                  title: intl.formatMessage(messages.backgroundColorSingleCard),
                  widget: 'color_list',
                  defaultValue: 'sectionGreen',
                  colors: [
                    { name: 'sectionGreen', label: 'Verde' },
                    { name: 'sectionPurple', label: 'Viola' },
                    { name: 'sectionPink', label: 'Rosa' },
                    { name: 'sectionYellow', label: 'Gialo' },
                    { name: 'sectionBlue', label: 'Azurro' },
                    { name: 'sectionRed', label: 'Rosso' },
                    { name: 'pink', label: 'Pink Youz' },
                    { name: 'yellow', label: 'Giallo Youz' },
                  ],
                },
                href: {
                  title: 'Link',
                  widget: 'object_browser',
                  allowExternals: true,
                  mode: 'link',
                  description:
                    "Per i link esterni inserire l'indirizzo completo. Esempio: 'www.google.com",
                },
              },

              required: [],
            };

            return columnsSchema;
          }}
        />
      </Segment>
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  openObjectBrowser: PropTypes.func.isRequired,
};

export default injectIntl(Sidebar);
