import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, defineMessages } from 'react-intl';
import { Button } from 'semantic-ui-react';
import { UniversalLink } from '@plone/volto/components';

const messages = defineMessages({
  linkPlaceholder: {
    id: 'Link placeholder',
    defaultMessage: 'Scopri di più',
  },
});

const ReadMore = ({
  linkMore,
  isEditMode = false,
  color = 'grey',
  size = '',
}) => {
  const intl = useIntl();
  let href = linkMore?.href || null;

  return href ? (
    <div className="read-more">
      <Button
        as={UniversalLink}
        href={isEditMode ? '#' : href}
        color={color}
        size={size}
      >
        {linkMore?.title || intl.formatMessage(messages.linkPlaceholder)}
      </Button>
    </div>
  ) : null;
};
ReadMore.propTypes = {
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};
export default ReadMore;
