import React from 'react';
import { Container } from 'semantic-ui-react';

import { defineMessages, useIntl } from 'react-intl';
import moment from 'moment';

const messages = defineMessages({
  pubDate: {
    id: 'Published date',
    defaultMessage: 'Published',
  },
  lastEditDate: {
    id: 'Last edit date',
    defaultMessage: 'Last edit',
  },
});

/**
 * Component to display the footer.
 * @function ContentDates
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const ContentDates = ({ content }) => {
  const intl = useIntl();
  moment.locale(intl.locale);
  return (
    <div className="content-dates">
      <Container>
        {content.effective && (
          <>
            <span>
              {intl.formatMessage(messages.pubDate)}:{' '}
              {moment(content.effective).format('DD/MM/YYYY')}
            </span>{' '}
            -{' '}
          </>
        )}
        <span>
          {intl.formatMessage(messages.lastEditDate)}:{' '}
          {moment(content.modified).format('DD/MM/YYYY HH:mm')}
        </span>
      </Container>
    </div>
  );
};

export default ContentDates;
