/**
 * HeaderSmall component.
 * @module components/theme/HeaderSmall/HeaderSmall
 */

import React from 'react';
import { Container } from 'semantic-ui-react';
import { SecondaryMenu } from 'volto-secondarymenu';
import LogoRegione from '@package/components/layout/images/logo-regione.svg';
import {
  SearchWidget,
  //Anontools,
  //LanguageSelector,
} from '@plone/volto/components';
import { SocialLinks } from 'volto-social-settings';

/**
 * HeaderSmall component class.
 * @function HeaderSmall
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component.
 */
const HeaderSmall = ({ pathname }) => {
  // const token = useSelector((state) => state.userSession.token);
  return (
    <div className="header-small">
      <Container>
        <div className="header-small-content">
          <div className="left-zone">
            <div className="parent-logo">
              <a
                href="https://www.regione.emilia-romagna.it"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={LogoRegione} alt="Logo Regione Emilia-Romagna" />
              </a>
            </div>

            <SecondaryMenu pathname={pathname} />
          </div>
          <div className="right-zone">
            <div className="search">
              <SearchWidget pathname="" />
            </div>
            <SocialLinks />

            {/* <LanguageSelector /> */}
            {/* {!token && (
              <div className="tools">
                <Anontools />
              </div>
            )} */}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HeaderSmall;
