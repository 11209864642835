/**
 * Edit title block.
 * @module components/manage/Blocks/Title/Edit
 */

import React, { Component } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { isEqual } from 'lodash';
import { Container, Button } from 'semantic-ui-react';

import { defineMessages, injectIntl } from 'react-intl';
import loadable from '@loadable/component';
import config from '@plone/volto/registry';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';
import { SidebarPortal, UniversalLink } from '@plone/volto/components';
import Sidebar from './Sidebar';
import backgorundImage from '@package/components/layout/images/bg-newsletter.svg';

const messages = defineMessages({
  title: {
    id: 'Title',
    defaultMessage: 'Titolo',
  },
  title_placeholder: {
    id: 'Title placeholder',
    defaultMessage: 'Titolo...',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Descrizione',
  },
  description_placeholder: {
    id: 'Description placeholder',
    defaultMessage: 'Inserisci una descrizione...',
  },
});
const Editor = loadable(() => import('draft-js-plugins-editor'));

/**
 * Edit title block class.
 * @class Edit
 * @extends Component
 */
class EditComponent extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    selected: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    onChangeField: PropTypes.func.isRequired,
    onSelectBlock: PropTypes.func.isRequired,
    onDeleteBlock: PropTypes.func.isRequired,
    onAddBlock: PropTypes.func.isRequired,
    onFocusPreviousBlock: PropTypes.func.isRequired,
    onFocusNextBlock: PropTypes.func.isRequired,
    block: PropTypes.string.isRequired,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs Newsletter
   */
  constructor(props) {
    super(props);
    const { settings } = config;

    this.draftConfig = settings.richtextEditorSettings(props);

    if (!__SERVER__) {
      let titleEditorState;
      let descriptionEditorState;

      const { DefaultDraftBlockRenderMap, EditorState } = props.draftJs;
      const { stateFromHTML } = props.draftJsImportHtml;

      const blockTitleRenderMap = Map({
        unstyled: {
          element: 'div',
        },
      });

      const blockDescriptionRenderMap = Map({
        unstyled: {
          element: 'div',
        },
      });

      this.extendedTitleBlockRenderMap = DefaultDraftBlockRenderMap.merge(
        blockTitleRenderMap,
      );

      this.extendedDescriptionBlockRenderMap = DefaultDraftBlockRenderMap.merge(
        blockDescriptionRenderMap,
      );

      if (props.data && props.data.title) {
        titleEditorState = EditorState.createWithContent(
          stateFromHTML(props.data.title),
        );
      } else {
        titleEditorState = EditorState.createEmpty();
      }

      if (props.data && props.data.description) {
        descriptionEditorState = EditorState.createWithContent(
          stateFromHTML(props.data.description),
        );
      } else {
        descriptionEditorState = EditorState.createEmpty();
      }

      this.state = {
        titleEditorState,
        descriptionEditorState,
        currentFocused: 'title',
      };
    }

    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
  }

  /**
   * Component did mount lifecycle method
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    if (this.node) {
      this.titleEditor.focus();
      this.titleEditor._onBlur = () => this.setState({ currentFocused: null });
      this.titleEditor._onFocus = () =>
        this.setState({ currentFocused: 'title' });

      this.descriptionEditor._onBlur = () =>
        this.setState({ currentFocused: null });
      this.descriptionEditor._onFocus = () =>
        this.setState({ currentFocused: 'description' });
    }
  }

  /**
   * @param {*} nextProps
   * @returns {boolean}
   * @memberof Edit
   */
  shouldComponentUpdate(nextProps) {
    return this.props.selected || !isEqual(this.props.data, nextProps.data);
  }
  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { EditorState } = this.props.draftJs;
    const stateFromHTML = this.props.draftJsImportHtml.stateFromHTML;

    if (
      nextProps.data.title &&
      this.props.data.title !== nextProps.data.title &&
      !this.props.selected
    ) {
      const contentState = stateFromHTML(nextProps.data.title);
      this.setState({
        titleEditorState: nextProps.data.title
          ? EditorState.createWithContent(contentState)
          : EditorState.createEmpty(),
      });
    }

    if (
      nextProps.data.description &&
      this.props.data.description !== nextProps.data.description &&
      !this.props.selected
    ) {
      const contentState = stateFromHTML(nextProps.data.description);
      this.setState({
        descriptionEditorState: nextProps.data.description
          ? EditorState.createWithContent(contentState)
          : EditorState.createEmpty(),
      });
    }

    if (nextProps.selected !== this.props.selected) {
      if (this.state.currentFocused === 'title') {
        this.titleEditor.focus();
      } else {
        this.descriptionEditor.focus();
      }
    }
  }

  onChangeTitle = (titleEditorState) => {
    this.setState({ titleEditorState }, () => {
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        title: titleEditorState.getCurrentContent().getPlainText(),
      });
    });
  };

  onChangeDescription = (descriptionEditorState) => {
    this.setState({ descriptionEditorState }, () => {
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        description: descriptionEditorState.getCurrentContent().getPlainText(),
      });
    });
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    if (__SERVER__) {
      return <div />;
    }
    const { Editor } = this.props.draftJs;

    return (
      <div className="newsletter-container full-width">
        <div className="background">
          <img src={backgorundImage} alt="bg" />
        </div>
        <Container>
          <h2>
            <Editor
              onChange={this.onChangeTitle}
              editorState={this.state.titleEditorState}
              blockRenderMap={this.extendedTitleBlockRenderMap}
              handleReturn={() => true}
              placeholder={this.props.intl.formatMessage(
                messages.title_placeholder,
              )}
              blockStyleFn={() => 'text-editor'}
              onUpArrow={() => {
                const selectionState = this.state.titleEditorState.getSelection();
                const { titleEditorState } = this.state;
                if (
                  titleEditorState
                    .getCurrentContent()
                    .getBlockMap()
                    .first()
                    .getKey() === selectionState.getFocusKey()
                ) {
                  this.props.onFocusPreviousBlock(
                    this.props.block,
                    this.props.blockNode.current,
                  );
                }
              }}
              onDownArrow={() => {
                const selectionState = this.state.titleEditorState.getSelection();
                const { titleEditorState } = this.state;
                if (
                  titleEditorState
                    .getCurrentContent()
                    .getBlockMap()
                    .last()
                    .getKey() === selectionState.getFocusKey()
                ) {
                  this.setState(() => ({
                    currentFocused: 'description',
                  }));
                  this.descriptionEditor.focus();
                }
              }}
              ref={(node) => {
                this.titleEditor = node;
              }}
            />
          </h2>

          <div className="description">
            <Editor
              onChange={this.onChangeDescription}
              editorState={this.state.descriptionEditorState}
              blockRenderMap={this.extendedDescriptionBlockRenderMap}
              handleReturn={() => true}
              placeholder={this.props.intl.formatMessage(
                messages.description_placeholder,
              )}
              blockStyleFn={() => 'text-editor'}
              onUpArrow={() => {
                const selectionState = this.state.descriptionEditorState.getSelection();
                const { descriptionEditorState } = this.state;
                if (
                  descriptionEditorState
                    .getCurrentContent()
                    .getBlockMap()
                    .first()
                    .getKey() === selectionState.getFocusKey()
                ) {
                  this.props.onFocusPreviousBlock(
                    this.props.block,
                    this.props.blockNode.current,
                  );
                }
              }}
              onDownArrow={() => {
                const selectionState = this.state.descriptionEditorState.getSelection();
                const { descriptionEditorState } = this.state;
                if (
                  descriptionEditorState
                    .getCurrentContent()
                    .getBlockMap()
                    .last()
                    .getKey() === selectionState.getFocusKey()
                ) {
                  this.setState(() => ({
                    currentFocused: 'description',
                  }));
                  this.descriptionEditor.focus();
                }
              }}
              ref={(node) => {
                this.descriptionEditor = node;
              }}
            />
          </div>
          {this.props.data.linkMore?.href && (
            <div className="more">
              <Button as={UniversalLink} href={this.props.data.linkMore.href}>
                {this.props.data.linkMore.title ||
                  this.props.data.linkMore.href}
              </Button>
            </div>
          )}
        </Container>
        <SidebarPortal selected={this.props.selected}>
          <Sidebar {...this.props} />
        </SidebarPortal>
      </div>
    );
  }
}

export const Edit = compose(
  injectIntl,
  injectLazyLibs([
    'draftJs',
    'draftJsImportHtml',
    'draftJsLibIsSoftNewlineEvent',
    'draftJsFilters',
    'draftJsInlineToolbarPlugin',
    'draftJsBlockBreakoutPlugin',
    'draftJsCreateInlineStyleButton',
    'draftJsCreateBlockStyleButton',
    'immutableLib',
    // TODO: add all plugin dependencies, also in Wysiwyg and Cell
  ]),
)(EditComponent);

const Preloader = (props) => {
  const [loaded, setLoaded] = React.useState(false);
  React.useEffect(() => {
    Editor.load().then(() => setLoaded(true));
  }, []);
  return loaded ? <Edit {...props} /> : null;
};

export default Preloader;
