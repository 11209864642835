import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { defineMessages, useIntl } from 'react-intl';
import {
  ObjectListWidget,
  SelectWidget,
  CheckboxWidget,
  TextWidget,
} from '@plone/volto/components';
import { AlignWidget, ColorListWidget } from '@package/components';

const messages = defineMessages({
  allineamento_intestazione: {
    id: 'Allineamento Intestazione',
    defaultMessage: 'Allineamento intestazione',
  },
  colore_sfondo: {
    id: 'Colore Sfondo',
    defaultMessage: 'Colore di sfondo',
  },
  ID_ancore: {
    id: 'ID Anchor',
    defaultMessage: 'ID per le ancore',
  },
  ID_ancore_descrizione: {
    id: "Descrizione per l'ID delle Ancore",
    defaultMessage:
      "L'ID non può contenere spazi vuoti. Esempi: 'closer', 'closer-page' o 'closer_link'",
  },
  dimensione_bottone: {
    id: 'Dimensione del Bottone',
    defaultMessage: 'Dimensione del bottone',
  },
  titolo_link: {
    id: 'Titolo Link',
    defaultMessage: 'Titolo del link',
  },
  immagine: {
    id: 'Allineamento Immagine',
    defaultMessage: 'Allineamento immagine',
  },
  linea: {
    id: 'Linea di separazione',
    defaultMessage: 'Riga tra gli elementi',
  },
  descrizione1: {
    id: '1° Paragrafo',
    defaultMessage: '1° testo',
  },
  descrizione2: {
    id: '2° Paragrafo',
    defaultMessage: '2° testo',
  },
  iconCheckbox: {
    id: 'With or without icon',
    defaultMessage: 'Mostra icona di spunta',
  },
  descrizione3: {
    id: '3° Paragrafo',
    defaultMessage: '3° testo',
  },
});

const Sidebar = (props) => {
  const { data, onChangeBlock, block } = props;
  useEffect(() => {
    if (!data.bg_color) {
      onChangeBlock(block, {
        ...data,
        bg_color: 'bg-primary',
      });
    }
    if (!data.alignText) {
      onChangeBlock(block, {
        ...data,
        alignText: 'right',
      });
    }
    if (!data.id_element__decalogo) {
      onChangeBlock(block, {
        ...data,
        id_element__decalogo: ' ',
      });
    }
  }, [data]);

  const intl = useIntl();

  return (
    <Segment.Group raised>
      <header className="header pulled main-title">
        <h2>
          <FormattedMessage
            id="titleDecalogo"
            defaultMessage="Blocco Decalogo"
          />
          :
        </h2>
      </header>
      <Segment className="form">
        <header className="header pulled">
          <h2>
            <FormattedMessage
              id="titleGeneral"
              defaultMessage="Impostazioni generali"
            />
            :
          </h2>
        </header>
        <AlignWidget
          value={data.alignText ?? 'right'}
          id="alignText"
          onChange={(name, value) =>
            onChangeBlock(block, { ...data, [name]: value })
          }
          alignments={['left', 'right']}
          title={intl.formatMessage(messages.allineamento_intestazione)}
        />
        <ColorListWidget
          id="bg_color"
          title={intl.formatMessage(messages.colore_sfondo)}
          colors={[
            { name: 'bg-primary', label: 'Colore primario' },
            { name: 'bg-secondary', label: 'Colore secondario' },
            { name: 'bg-tertiary', label: 'Colore terziario' },
          ]}
          value={data.bg_color ?? 'bg-primary'}
          onChange={(name, value) =>
            onChangeBlock(block, { ...data, [name]: value })
          }
        />
        <TextWidget
          id="id_element__decalogo"
          title={intl.formatMessage(messages.ID_ancore)}
          description={intl.formatMessage(messages.ID_ancore_descrizione)}
          value={data.id_element__decalogo ?? ' '}
          onChange={(name, value) => {
            onChangeBlock(block, { ...data, [name]: value });
          }}
        />
      </Segment>
      <Segment className="form">
        <div className="form sidebar-listing-data">
          <header className="header pulled">
            <h2>
              <FormattedMessage id="titleaddButton" defaultMessage="Bottone" />:
            </h2>
          </header>
          <SelectWidget
            id="button_sizes"
            title={intl.formatMessage(messages.dimensione_bottone)}
            choices={[
              ['tiny', 'Piccola'],
              ['large', 'Grande'],
            ]}
            value={data.button_sizes ?? 'tiny'}
            noValueOption={false}
            onChange={(name, value) => {
              onChangeBlock(block, {
                ...data,
                [name]: value,
              });
            }}
          />
          <ObjectListWidget
            {...props}
            id="addButtons"
            value={data?.addButtons ?? []}
            onChange={(id, value) =>
              props.onChangeBlock(block, { ...data, [id]: value })
            }
            schema={() => {
              const buttonSchema = {
                title: 'Buttone',
                addMessage: 'Aggiungi un Bottone',
                fieldsets: [
                  {
                    id: 'default',
                    title: 'Default',
                    fields: ['href', 'href_title'],
                  },
                ],
                properties: {
                  href: {
                    title: 'Link',
                    widget: 'object_browser',
                    mode: 'link',
                    allowExternals: true,
                  },
                  href_title: {
                    title: intl.formatMessage(messages.titolo_link),
                  },
                },

                required: [],
              };

              return buttonSchema;
            }}
          />
        </div>
      </Segment>
      <header className="header pulled">
        <h2>
          <FormattedMessage id="titleColorBlock" defaultMessage="Immagine" />:
        </h2>
      </header>
      <Segment className="form">
        <div>
          <SelectWidget
            id="decalogo_img"
            title={intl.formatMessage(messages.immagine)}
            choices={[
              ['left1', 'Sinistra pollice su'],
              ['left2', 'Sinistra vittoria'],
              ['right1', 'Destra cornetta'],
              ['right2', 'Destra O.K.'],
            ]}
            value={data.decalogo_img}
            onChange={(name, value) => {
              onChangeBlock(block, {
                ...data,
                [name]: value,
              });
            }}
          />
        </div>
      </Segment>

      <Segment className="form">
        <header className="header pulled">
          <h2>
            <FormattedMessage
              id="titleItemsList"
              defaultMessage="Lista Elementi"
            />
            :
          </h2>
        </header>
        <CheckboxWidget
          id="showLineSeparatorDecalogo"
          title={intl.formatMessage(messages.linea)}
          value={data.showLineSeparatorDecalogo ?? false}
          onChange={(name, value) => {
            onChangeBlock(block, { ...data, [name]: value });
          }}
        />
        <ObjectListWidget
          {...props}
          id="item"
          className="form sidebar-listing-data"
          value={data?.item ?? []}
          onChange={(id, value) =>
            props.onChangeBlock(block, { ...data, [id]: value })
          }
          schema={() => {
            const itemSchema = {
              title: 'item',
              addMessage: 'Aggiungi un nuovo elemento alla lista',
              fieldsets: [
                {
                  id: 'default',
                  title: 'Default',
                  fields: [
                    'listItem',
                    'href',
                    'href_title',
                    'icon',
                    'description1',
                    'description2',
                    'description3',
                  ],
                },
              ],
              properties: {
                listItem: {
                  title: 'Titolo elemento',
                },
                href: {
                  title: 'Link',
                  widget: 'object_browser',
                  mode: 'link',
                  allowExternals: true,
                },
                href_title: {
                  title: intl.formatMessage(messages.titolo_link),
                },
                description1: {
                  title: intl.formatMessage(messages.descrizione1),
                  widget: 'textarea',
                },
                icon: {
                  title: intl.formatMessage(messages.iconCheckbox),
                  type: 'boolean',
                },
                description2: {
                  title: intl.formatMessage(messages.descrizione2),
                  widget: 'textarea',
                },
                description3: {
                  title: intl.formatMessage(messages.descrizione3),
                  widget: 'textarea',
                },
              },
              required: [],
            };

            return itemSchema;
          }}
        />
      </Segment>
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  openObjectBrowser: PropTypes.func.isRequired,
};

export default injectIntl(Sidebar);
