import React from 'react';
import { defineMessages } from 'react-intl';

import {
  withDNDContext,
  SubblocksEdit,
  SubblocksWrapper,
} from 'volto-subblocks';

import { SidebarPortal } from '@plone/volto/components';
import EditBlock from './Block/EditBlock';
import Sidebar from './Sidebar';

const messages = defineMessages({
  addItem: {
    id: 'Add accordion item',
    defaultMessage: 'Aggiungi elemento',
  },
});
/**
 * Edit Accordion block class.
 * @class Edit
 * @extends Component
 */
class Edit extends SubblocksEdit {
  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    if (__SERVER__) {
      return <div />;
    }

    return (
      <div className="accordion-wrapper">
        <SubblocksWrapper node={this.node}>
          {this.state.subblocks.map((subblock, subindex) => (
            <div className="accordion-item" key={subblock.id}>
              <EditBlock
                data={subblock}
                index={subindex}
                selected={this.isSubblockSelected(subindex)}
                {...this.subblockProps}
                openObjectBrowser={this.props.openObjectBrowser}
              />
            </div>
          ))}

          {this.props.selected && (
            <div className="add-element">
              {this.renderAddBlockButton(
                this.props.intl.formatMessage(messages.addItem),
              )}
            </div>
          )}
        </SubblocksWrapper>

        <SidebarPortal selected={this.props.selected || false}>
          <Sidebar
            {...this.props}
            data={this.props.data}
            block={this.props.block}
            onChangeBlock={this.props.onChangeBlock}
            onChangeSubBlock={this.onChangeSubblocks}
            selected={this.state.subIndexSelected}
            setSelected={this.onSubblockChangeFocus}
            openObjectBrowser={this.props.openObjectBrowser}
          />
        </SidebarPortal>
      </div>
    );
  }
}

export default React.memo(withDNDContext(Edit));
